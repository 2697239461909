@for $i from 1 through 9 {
	.z-index-#{$i} {
		z-index: #{$i};
	}
}

@for $i from 1 through 2 {
	.z-index--#{$i} {
		z-index: -#{$i};
	}
}