.icon-sm {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 75%;
    line-height: normal;
}

.icon-md {
    width: 2.8rem;
    height: 2.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
}

.icon-lg {
    width: 3.5rem;
    height: 3.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1;
}

.icon-xl {
    width: 5rem;
    height: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.2rem;
    line-height: 1;
}


.only-icon {
    display: inline-flex;
    i {
        font-size: 1.8rem;
    }
    &-sm {
        i {
            font-size: 1rem;
        }
    }
    &-lg {
        i {
            font-size: 2.25rem;
        }
    }
    &-xl {
        i {
            font-size: 3rem;
        }
    }
}


@keyframes pulse-border {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0;
    }
}


.after-shadow {
    z-index: 1;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
        pointer-events: none;
        background: currentColor;
        z-index: -1;
    }
}

// Icon Dots Effect
.dots-icon {
    position: relative;
}

.dots {
    z-index: 0;
    animation: dots 6s infinite linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .dot {
        width: 5px;
        height: 5px;
        border-radius: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -3px;
        margin-top: -3px;
    }

    .dot1 {
        background-color: #8ad9fd;
        transform: translate(15px, 5px);
        animation: dot1 1.5s infinite alternate;
    }

    .dot2 {
        background-color: #b8a6f7;
        transform: translate(-8px, 8px);
        animation: dot2 1.5s infinite alternate;
        animation-delay: .5s;
    }

    .dot3 {
        background-color: #fac5a7;
        transform: translate(-5px, -12px);
        animation: dot3 1.5s infinite alternate;
        animation-delay: 1s;
    }
}

@keyframes dots {
    from {
        transform: rotate(-360deg)
    }

    to {
        transform: rotate(0)
    }

}

@keyframes dot1 {
    from {
        transform: translate(15px, 5px);
        opacity: 1;
    }

    to {
        transform: translate(25px, 5px);
        opacity: 1;
    }

}

@keyframes dot2 {
    from {
        transform: translate(-8px, 8px);
        opacity: 1;
    }

    to {
        transform: translate(-19px, 19px);
        opacity: 1;
    }

}

@keyframes dot3 {
    from {
        transform: translate(-5px, -12px);
        opacity: 1;
    }

    to {
        transform: translate(-10px, -24px);
        opacity: 1;
    }

}


// Hover rotate effect
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@mixin hover-rotate {
    animation: lds-dual-ring 1.2s linear infinite;
    animation-play-state: paused;
}

@mixin hover-rotate-hover {
    animation-play-state: running;
}

.hover-rotate {
    .hr-rotate {
        @include hover-rotate;
    }

    .hr-rotate-after {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            margin: auto;
            border-radius: 50px;
            transition: ease all 0.55s;
            z-index: -1;
            @include hover-rotate;
        }
    }

    .ef-1 {
        &:after {
            border-top: 50px solid transparent;
            border-left: 50px solid currentColor;
            top: -10px;
            left: -10px;
            border-right: 50px solid transparent;
            border-bottom: 50px solid transparent;
        }
    }

    .ef-2 {
        &:after {
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            border: 4px solid currentcolor;
            border-bottom: 4px solid transparent;
        }
    }

    .ef-3 {
        &:after {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
            border: 2px solid currentcolor;
            border-bottom: 2px solid transparent;
            border-top: 2px solid transparent;
        }
    }

    .ef-4 {
        &:after {
            top: -8px;
            left: -8px;
            right: -8px;
            bottom: -8px;
            border: 2px solid currentcolor;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
            border-top: 2px solid transparent;
        }
    }

    &:hover {
        .hr-rotate {
            @include hover-rotate-hover;
        }

        .hr-rotate-after {
            &:after {
                @include hover-rotate-hover;
            }
        }
    }
}

.arrow-icon {
    width: 32px;
    height: 12px;
    position: relative;

    &:before {
        width: 32px;
        height: 2px;
        background: currentColor;
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 12px;
        height: 12px;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        margin: auto;
        right: 0;
        transform: rotate(-45deg);
    }
}