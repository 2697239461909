@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
@font-face {
  font-family: bootstrap-icons;
  src: url("bootstrap-icons.9e6122ef.woff2") format("woff2"), url("bootstrap-icons.d79790f5.woff") format("woff");
}

[class^="bi-"]:before, [class*=" bi-"]:before {
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.bi-alarm-fill:before {
  content: "";
}

.bi-alarm:before {
  content: "";
}

.bi-align-bottom:before {
  content: "";
}

.bi-align-center:before {
  content: "";
}

.bi-align-end:before {
  content: "";
}

.bi-align-middle:before {
  content: "";
}

.bi-align-start:before {
  content: "";
}

.bi-align-top:before {
  content: "";
}

.bi-alt:before {
  content: "";
}

.bi-app-indicator:before {
  content: "";
}

.bi-app:before {
  content: "";
}

.bi-archive-fill:before {
  content: "";
}

.bi-archive:before {
  content: "";
}

.bi-arrow-90deg-down:before {
  content: "";
}

.bi-arrow-90deg-left:before {
  content: "";
}

.bi-arrow-90deg-right:before {
  content: "";
}

.bi-arrow-90deg-up:before {
  content: "";
}

.bi-arrow-bar-down:before {
  content: "";
}

.bi-arrow-bar-left:before {
  content: "";
}

.bi-arrow-bar-right:before {
  content: "";
}

.bi-arrow-bar-up:before {
  content: "";
}

.bi-arrow-clockwise:before {
  content: "";
}

.bi-arrow-counterclockwise:before {
  content: "";
}

.bi-arrow-down-circle-fill:before {
  content: "";
}

.bi-arrow-down-circle:before {
  content: "";
}

.bi-arrow-down-left-circle-fill:before {
  content: "";
}

.bi-arrow-down-left-circle:before {
  content: "";
}

.bi-arrow-down-left-square-fill:before {
  content: "";
}

.bi-arrow-down-left-square:before {
  content: "";
}

.bi-arrow-down-left:before {
  content: "";
}

.bi-arrow-down-right-circle-fill:before {
  content: "";
}

.bi-arrow-down-right-circle:before {
  content: "";
}

.bi-arrow-down-right-square-fill:before {
  content: "";
}

.bi-arrow-down-right-square:before {
  content: "";
}

.bi-arrow-down-right:before {
  content: "";
}

.bi-arrow-down-short:before {
  content: "";
}

.bi-arrow-down-square-fill:before {
  content: "";
}

.bi-arrow-down-square:before {
  content: "";
}

.bi-arrow-down-up:before {
  content: "";
}

.bi-arrow-down:before {
  content: "";
}

.bi-arrow-left-circle-fill:before {
  content: "";
}

.bi-arrow-left-circle:before {
  content: "";
}

.bi-arrow-left-right:before {
  content: "";
}

.bi-arrow-left-short:before {
  content: "";
}

.bi-arrow-left-square-fill:before {
  content: "";
}

.bi-arrow-left-square:before {
  content: "";
}

.bi-arrow-left:before {
  content: "";
}

.bi-arrow-repeat:before {
  content: "";
}

.bi-arrow-return-left:before {
  content: "";
}

.bi-arrow-return-right:before {
  content: "";
}

.bi-arrow-right-circle-fill:before {
  content: "";
}

.bi-arrow-right-circle:before {
  content: "";
}

.bi-arrow-right-short:before {
  content: "";
}

.bi-arrow-right-square-fill:before {
  content: "";
}

.bi-arrow-right-square:before {
  content: "";
}

.bi-arrow-right:before {
  content: "";
}

.bi-arrow-up-circle-fill:before {
  content: "";
}

.bi-arrow-up-circle:before {
  content: "";
}

.bi-arrow-up-left-circle-fill:before {
  content: "";
}

.bi-arrow-up-left-circle:before {
  content: "";
}

.bi-arrow-up-left-square-fill:before {
  content: "";
}

.bi-arrow-up-left-square:before {
  content: "";
}

.bi-arrow-up-left:before {
  content: "";
}

.bi-arrow-up-right-circle-fill:before {
  content: "";
}

.bi-arrow-up-right-circle:before {
  content: "";
}

.bi-arrow-up-right-square-fill:before {
  content: "";
}

.bi-arrow-up-right-square:before {
  content: "";
}

.bi-arrow-up-right:before {
  content: "";
}

.bi-arrow-up-short:before {
  content: "";
}

.bi-arrow-up-square-fill:before {
  content: "";
}

.bi-arrow-up-square:before {
  content: "";
}

.bi-arrow-up:before {
  content: "";
}

.bi-arrows-angle-contract:before {
  content: "";
}

.bi-arrows-angle-expand:before {
  content: "";
}

.bi-arrows-collapse:before {
  content: "";
}

.bi-arrows-expand:before {
  content: "";
}

.bi-arrows-fullscreen:before {
  content: "";
}

.bi-arrows-move:before {
  content: "";
}

.bi-aspect-ratio-fill:before {
  content: "";
}

.bi-aspect-ratio:before {
  content: "";
}

.bi-asterisk:before {
  content: "";
}

.bi-at:before {
  content: "";
}

.bi-award-fill:before {
  content: "";
}

.bi-award:before {
  content: "";
}

.bi-back:before {
  content: "";
}

.bi-backspace-fill:before {
  content: "";
}

.bi-backspace-reverse-fill:before {
  content: "";
}

.bi-backspace-reverse:before {
  content: "";
}

.bi-backspace:before {
  content: "";
}

.bi-badge-3d-fill:before {
  content: "";
}

.bi-badge-3d:before {
  content: "";
}

.bi-badge-4k-fill:before {
  content: "";
}

.bi-badge-4k:before {
  content: "";
}

.bi-badge-8k-fill:before {
  content: "";
}

.bi-badge-8k:before {
  content: "";
}

.bi-badge-ad-fill:before {
  content: "";
}

.bi-badge-ad:before {
  content: "";
}

.bi-badge-ar-fill:before {
  content: "";
}

.bi-badge-ar:before {
  content: "";
}

.bi-badge-cc-fill:before {
  content: "";
}

.bi-badge-cc:before {
  content: "";
}

.bi-badge-hd-fill:before {
  content: "";
}

.bi-badge-hd:before {
  content: "";
}

.bi-badge-tm-fill:before {
  content: "";
}

.bi-badge-tm:before {
  content: "";
}

.bi-badge-vo-fill:before {
  content: "";
}

.bi-badge-vo:before {
  content: "";
}

.bi-badge-vr-fill:before {
  content: "";
}

.bi-badge-vr:before {
  content: "";
}

.bi-badge-wc-fill:before {
  content: "";
}

.bi-badge-wc:before {
  content: "";
}

.bi-bag-check-fill:before {
  content: "";
}

.bi-bag-check:before {
  content: "";
}

.bi-bag-dash-fill:before {
  content: "";
}

.bi-bag-dash:before {
  content: "";
}

.bi-bag-fill:before {
  content: "";
}

.bi-bag-plus-fill:before {
  content: "";
}

.bi-bag-plus:before {
  content: "";
}

.bi-bag-x-fill:before {
  content: "";
}

.bi-bag-x:before {
  content: "";
}

.bi-bag:before {
  content: "";
}

.bi-bar-chart-fill:before {
  content: "";
}

.bi-bar-chart-line-fill:before {
  content: "";
}

.bi-bar-chart-line:before {
  content: "";
}

.bi-bar-chart-steps:before {
  content: "";
}

.bi-bar-chart:before {
  content: "";
}

.bi-basket-fill:before {
  content: "";
}

.bi-basket:before {
  content: "";
}

.bi-basket2-fill:before {
  content: "";
}

.bi-basket2:before {
  content: "";
}

.bi-basket3-fill:before {
  content: "";
}

.bi-basket3:before {
  content: "";
}

.bi-battery-charging:before {
  content: "";
}

.bi-battery-full:before {
  content: "";
}

.bi-battery-half:before {
  content: "";
}

.bi-battery:before {
  content: "";
}

.bi-bell-fill:before {
  content: "";
}

.bi-bell:before {
  content: "";
}

.bi-bezier:before {
  content: "";
}

.bi-bezier2:before {
  content: "";
}

.bi-bicycle:before {
  content: "";
}

.bi-binoculars-fill:before {
  content: "";
}

.bi-binoculars:before {
  content: "";
}

.bi-blockquote-left:before {
  content: "";
}

.bi-blockquote-right:before {
  content: "";
}

.bi-book-fill:before {
  content: "";
}

.bi-book-half:before {
  content: "";
}

.bi-book:before {
  content: "";
}

.bi-bookmark-check-fill:before {
  content: "";
}

.bi-bookmark-check:before {
  content: "";
}

.bi-bookmark-dash-fill:before {
  content: "";
}

.bi-bookmark-dash:before {
  content: "";
}

.bi-bookmark-fill:before {
  content: "";
}

.bi-bookmark-heart-fill:before {
  content: "";
}

.bi-bookmark-heart:before {
  content: "";
}

.bi-bookmark-plus-fill:before {
  content: "";
}

.bi-bookmark-plus:before {
  content: "";
}

.bi-bookmark-star-fill:before {
  content: "";
}

.bi-bookmark-star:before {
  content: "";
}

.bi-bookmark-x-fill:before {
  content: "";
}

.bi-bookmark-x:before {
  content: "";
}

.bi-bookmark:before {
  content: "";
}

.bi-bookmarks-fill:before {
  content: "";
}

.bi-bookmarks:before {
  content: "";
}

.bi-bookshelf:before {
  content: "";
}

.bi-bootstrap-fill:before {
  content: "";
}

.bi-bootstrap-reboot:before {
  content: "";
}

.bi-bootstrap:before {
  content: "";
}

.bi-border-all:before {
  content: "";
}

.bi-border-bottom:before {
  content: "";
}

.bi-border-center:before {
  content: "";
}

.bi-border-inner:before {
  content: "";
}

.bi-border-left:before {
  content: "";
}

.bi-border-middle:before {
  content: "";
}

.bi-border-outer:before {
  content: "";
}

.bi-border-right:before {
  content: "";
}

.bi-border-style:before {
  content: "";
}

.bi-border-top:before {
  content: "";
}

.bi-border-width:before {
  content: "";
}

.bi-border:before {
  content: "";
}

.bi-bounding-box-circles:before {
  content: "";
}

.bi-bounding-box:before {
  content: "";
}

.bi-box-arrow-down-left:before {
  content: "";
}

.bi-box-arrow-down-right:before {
  content: "";
}

.bi-box-arrow-down:before {
  content: "";
}

.bi-box-arrow-in-down-left:before {
  content: "";
}

.bi-box-arrow-in-down-right:before {
  content: "";
}

.bi-box-arrow-in-down:before {
  content: "";
}

.bi-box-arrow-in-left:before {
  content: "";
}

.bi-box-arrow-in-right:before {
  content: "";
}

.bi-box-arrow-in-up-left:before {
  content: "";
}

.bi-box-arrow-in-up-right:before {
  content: "";
}

.bi-box-arrow-in-up:before {
  content: "";
}

.bi-box-arrow-left:before {
  content: "";
}

.bi-box-arrow-right:before {
  content: "";
}

.bi-box-arrow-up-left:before {
  content: "";
}

.bi-box-arrow-up-right:before {
  content: "";
}

.bi-box-arrow-up:before {
  content: "";
}

.bi-box-seam:before {
  content: "";
}

.bi-box:before {
  content: "";
}

.bi-braces:before {
  content: "";
}

.bi-bricks:before {
  content: "";
}

.bi-briefcase-fill:before {
  content: "";
}

.bi-briefcase:before {
  content: "";
}

.bi-brightness-alt-high-fill:before {
  content: "";
}

.bi-brightness-alt-high:before {
  content: "";
}

.bi-brightness-alt-low-fill:before {
  content: "";
}

.bi-brightness-alt-low:before {
  content: "";
}

.bi-brightness-high-fill:before {
  content: "";
}

.bi-brightness-high:before {
  content: "";
}

.bi-brightness-low-fill:before {
  content: "";
}

.bi-brightness-low:before {
  content: "";
}

.bi-broadcast-pin:before {
  content: "";
}

.bi-broadcast:before {
  content: "";
}

.bi-brush-fill:before {
  content: "";
}

.bi-brush:before {
  content: "";
}

.bi-bucket-fill:before {
  content: "";
}

.bi-bucket:before {
  content: "";
}

.bi-bug-fill:before {
  content: "";
}

.bi-bug:before {
  content: "";
}

.bi-building:before {
  content: "";
}

.bi-bullseye:before {
  content: "";
}

.bi-calculator-fill:before {
  content: "";
}

.bi-calculator:before {
  content: "";
}

.bi-calendar-check-fill:before {
  content: "";
}

.bi-calendar-check:before {
  content: "";
}

.bi-calendar-date-fill:before {
  content: "";
}

.bi-calendar-date:before {
  content: "";
}

.bi-calendar-day-fill:before {
  content: "";
}

.bi-calendar-day:before {
  content: "";
}

.bi-calendar-event-fill:before {
  content: "";
}

.bi-calendar-event:before {
  content: "";
}

.bi-calendar-fill:before {
  content: "";
}

.bi-calendar-minus-fill:before {
  content: "";
}

.bi-calendar-minus:before {
  content: "";
}

.bi-calendar-month-fill:before {
  content: "";
}

.bi-calendar-month:before {
  content: "";
}

.bi-calendar-plus-fill:before {
  content: "";
}

.bi-calendar-plus:before {
  content: "";
}

.bi-calendar-range-fill:before {
  content: "";
}

.bi-calendar-range:before {
  content: "";
}

.bi-calendar-week-fill:before {
  content: "";
}

.bi-calendar-week:before {
  content: "";
}

.bi-calendar-x-fill:before {
  content: "";
}

.bi-calendar-x:before {
  content: "";
}

.bi-calendar:before {
  content: "";
}

.bi-calendar2-check-fill:before {
  content: "";
}

.bi-calendar2-check:before {
  content: "";
}

.bi-calendar2-date-fill:before {
  content: "";
}

.bi-calendar2-date:before {
  content: "";
}

.bi-calendar2-day-fill:before {
  content: "";
}

.bi-calendar2-day:before {
  content: "";
}

.bi-calendar2-event-fill:before {
  content: "";
}

.bi-calendar2-event:before {
  content: "";
}

.bi-calendar2-fill:before {
  content: "";
}

.bi-calendar2-minus-fill:before {
  content: "";
}

.bi-calendar2-minus:before {
  content: "";
}

.bi-calendar2-month-fill:before {
  content: "";
}

.bi-calendar2-month:before {
  content: "";
}

.bi-calendar2-plus-fill:before {
  content: "";
}

.bi-calendar2-plus:before {
  content: "";
}

.bi-calendar2-range-fill:before {
  content: "";
}

.bi-calendar2-range:before {
  content: "";
}

.bi-calendar2-week-fill:before {
  content: "";
}

.bi-calendar2-week:before {
  content: "";
}

.bi-calendar2-x-fill:before {
  content: "";
}

.bi-calendar2-x:before {
  content: "";
}

.bi-calendar2:before {
  content: "";
}

.bi-calendar3-event-fill:before {
  content: "";
}

.bi-calendar3-event:before {
  content: "";
}

.bi-calendar3-fill:before {
  content: "";
}

.bi-calendar3-range-fill:before {
  content: "";
}

.bi-calendar3-range:before {
  content: "";
}

.bi-calendar3-week-fill:before {
  content: "";
}

.bi-calendar3-week:before {
  content: "";
}

.bi-calendar3:before {
  content: "";
}

.bi-calendar4-event:before {
  content: "";
}

.bi-calendar4-range:before {
  content: "";
}

.bi-calendar4-week:before {
  content: "";
}

.bi-calendar4:before {
  content: "";
}

.bi-camera-fill:before {
  content: "";
}

.bi-camera-reels-fill:before {
  content: "";
}

.bi-camera-reels:before {
  content: "";
}

.bi-camera-video-fill:before {
  content: "";
}

.bi-camera-video-off-fill:before {
  content: "";
}

.bi-camera-video-off:before {
  content: "";
}

.bi-camera-video:before {
  content: "";
}

.bi-camera:before {
  content: "";
}

.bi-camera2:before {
  content: "";
}

.bi-capslock-fill:before {
  content: "";
}

.bi-capslock:before {
  content: "";
}

.bi-card-checklist:before {
  content: "";
}

.bi-card-heading:before {
  content: "";
}

.bi-card-image:before {
  content: "";
}

.bi-card-list:before {
  content: "";
}

.bi-card-text:before {
  content: "";
}

.bi-caret-down-fill:before {
  content: "";
}

.bi-caret-down-square-fill:before {
  content: "";
}

.bi-caret-down-square:before {
  content: "";
}

.bi-caret-down:before {
  content: "";
}

.bi-caret-left-fill:before {
  content: "";
}

.bi-caret-left-square-fill:before {
  content: "";
}

.bi-caret-left-square:before {
  content: "";
}

.bi-caret-left:before {
  content: "";
}

.bi-caret-right-fill:before {
  content: "";
}

.bi-caret-right-square-fill:before {
  content: "";
}

.bi-caret-right-square:before {
  content: "";
}

.bi-caret-right:before {
  content: "";
}

.bi-caret-up-fill:before {
  content: "";
}

.bi-caret-up-square-fill:before {
  content: "";
}

.bi-caret-up-square:before {
  content: "";
}

.bi-caret-up:before {
  content: "";
}

.bi-cart-check-fill:before {
  content: "";
}

.bi-cart-check:before {
  content: "";
}

.bi-cart-dash-fill:before {
  content: "";
}

.bi-cart-dash:before {
  content: "";
}

.bi-cart-fill:before {
  content: "";
}

.bi-cart-plus-fill:before {
  content: "";
}

.bi-cart-plus:before {
  content: "";
}

.bi-cart-x-fill:before {
  content: "";
}

.bi-cart-x:before {
  content: "";
}

.bi-cart:before {
  content: "";
}

.bi-cart2:before {
  content: "";
}

.bi-cart3:before {
  content: "";
}

.bi-cart4:before {
  content: "";
}

.bi-cash-stack:before {
  content: "";
}

.bi-cash:before {
  content: "";
}

.bi-cast:before {
  content: "";
}

.bi-chat-dots-fill:before {
  content: "";
}

.bi-chat-dots:before {
  content: "";
}

.bi-chat-fill:before {
  content: "";
}

.bi-chat-left-dots-fill:before {
  content: "";
}

.bi-chat-left-dots:before {
  content: "";
}

.bi-chat-left-fill:before {
  content: "";
}

.bi-chat-left-quote-fill:before {
  content: "";
}

.bi-chat-left-quote:before {
  content: "";
}

.bi-chat-left-text-fill:before {
  content: "";
}

.bi-chat-left-text:before {
  content: "";
}

.bi-chat-left:before {
  content: "";
}

.bi-chat-quote-fill:before {
  content: "";
}

.bi-chat-quote:before {
  content: "";
}

.bi-chat-right-dots-fill:before {
  content: "";
}

.bi-chat-right-dots:before {
  content: "";
}

.bi-chat-right-fill:before {
  content: "";
}

.bi-chat-right-quote-fill:before {
  content: "";
}

.bi-chat-right-quote:before {
  content: "";
}

.bi-chat-right-text-fill:before {
  content: "";
}

.bi-chat-right-text:before {
  content: "";
}

.bi-chat-right:before {
  content: "";
}

.bi-chat-square-dots-fill:before {
  content: "";
}

.bi-chat-square-dots:before {
  content: "";
}

.bi-chat-square-fill:before {
  content: "";
}

.bi-chat-square-quote-fill:before {
  content: "";
}

.bi-chat-square-quote:before {
  content: "";
}

.bi-chat-square-text-fill:before {
  content: "";
}

.bi-chat-square-text:before {
  content: "";
}

.bi-chat-square:before {
  content: "";
}

.bi-chat-text-fill:before {
  content: "";
}

.bi-chat-text:before {
  content: "";
}

.bi-chat:before {
  content: "";
}

.bi-check-all:before {
  content: "";
}

.bi-check-circle-fill:before {
  content: "";
}

.bi-check-circle:before {
  content: "";
}

.bi-check-square-fill:before {
  content: "";
}

.bi-check-square:before {
  content: "";
}

.bi-check:before {
  content: "";
}

.bi-check2-all:before {
  content: "";
}

.bi-check2-circle:before {
  content: "";
}

.bi-check2-square:before {
  content: "";
}

.bi-check2:before {
  content: "";
}

.bi-chevron-bar-contract:before {
  content: "";
}

.bi-chevron-bar-down:before {
  content: "";
}

.bi-chevron-bar-expand:before {
  content: "";
}

.bi-chevron-bar-left:before {
  content: "";
}

.bi-chevron-bar-right:before {
  content: "";
}

.bi-chevron-bar-up:before {
  content: "";
}

.bi-chevron-compact-down:before {
  content: "";
}

.bi-chevron-compact-left:before {
  content: "";
}

.bi-chevron-compact-right:before {
  content: "";
}

.bi-chevron-compact-up:before {
  content: "";
}

.bi-chevron-contract:before {
  content: "";
}

.bi-chevron-double-down:before {
  content: "";
}

.bi-chevron-double-left:before {
  content: "";
}

.bi-chevron-double-right:before {
  content: "";
}

.bi-chevron-double-up:before {
  content: "";
}

.bi-chevron-down:before {
  content: "";
}

.bi-chevron-expand:before {
  content: "";
}

.bi-chevron-left:before {
  content: "";
}

.bi-chevron-right:before {
  content: "";
}

.bi-chevron-up:before {
  content: "";
}

.bi-circle-fill:before {
  content: "";
}

.bi-circle-half:before {
  content: "";
}

.bi-circle-square:before {
  content: "";
}

.bi-circle:before {
  content: "";
}

.bi-clipboard-check:before {
  content: "";
}

.bi-clipboard-data:before {
  content: "";
}

.bi-clipboard-minus:before {
  content: "";
}

.bi-clipboard-plus:before {
  content: "";
}

.bi-clipboard-x:before {
  content: "";
}

.bi-clipboard:before {
  content: "";
}

.bi-clock-fill:before {
  content: "";
}

.bi-clock-history:before {
  content: "";
}

.bi-clock:before {
  content: "";
}

.bi-cloud-arrow-down-fill:before {
  content: "";
}

.bi-cloud-arrow-down:before {
  content: "";
}

.bi-cloud-arrow-up-fill:before {
  content: "";
}

.bi-cloud-arrow-up:before {
  content: "";
}

.bi-cloud-check-fill:before {
  content: "";
}

.bi-cloud-check:before {
  content: "";
}

.bi-cloud-download-fill:before {
  content: "";
}

.bi-cloud-download:before {
  content: "";
}

.bi-cloud-drizzle-fill:before {
  content: "";
}

.bi-cloud-drizzle:before {
  content: "";
}

.bi-cloud-fill:before {
  content: "";
}

.bi-cloud-fog-fill:before {
  content: "";
}

.bi-cloud-fog:before {
  content: "";
}

.bi-cloud-fog2-fill:before {
  content: "";
}

.bi-cloud-fog2:before {
  content: "";
}

.bi-cloud-hail-fill:before {
  content: "";
}

.bi-cloud-hail:before {
  content: "";
}

.bi-cloud-haze-1:before {
  content: "";
}

.bi-cloud-haze-fill:before {
  content: "";
}

.bi-cloud-haze:before {
  content: "";
}

.bi-cloud-haze2-fill:before {
  content: "";
}

.bi-cloud-lightning-fill:before {
  content: "";
}

.bi-cloud-lightning-rain-fill:before {
  content: "";
}

.bi-cloud-lightning-rain:before {
  content: "";
}

.bi-cloud-lightning:before {
  content: "";
}

.bi-cloud-minus-fill:before {
  content: "";
}

.bi-cloud-minus:before {
  content: "";
}

.bi-cloud-moon-fill:before {
  content: "";
}

.bi-cloud-moon:before {
  content: "";
}

.bi-cloud-plus-fill:before {
  content: "";
}

.bi-cloud-plus:before {
  content: "";
}

.bi-cloud-rain-fill:before {
  content: "";
}

.bi-cloud-rain-heavy-fill:before {
  content: "";
}

.bi-cloud-rain-heavy:before {
  content: "";
}

.bi-cloud-rain:before {
  content: "";
}

.bi-cloud-slash-fill:before {
  content: "";
}

.bi-cloud-slash:before {
  content: "";
}

.bi-cloud-sleet-fill:before {
  content: "";
}

.bi-cloud-sleet:before {
  content: "";
}

.bi-cloud-snow-fill:before {
  content: "";
}

.bi-cloud-snow:before {
  content: "";
}

.bi-cloud-sun-fill:before {
  content: "";
}

.bi-cloud-sun:before {
  content: "";
}

.bi-cloud-upload-fill:before {
  content: "";
}

.bi-cloud-upload:before {
  content: "";
}

.bi-cloud:before {
  content: "";
}

.bi-clouds-fill:before {
  content: "";
}

.bi-clouds:before {
  content: "";
}

.bi-cloudy-fill:before {
  content: "";
}

.bi-cloudy:before {
  content: "";
}

.bi-code-slash:before {
  content: "";
}

.bi-code-square:before {
  content: "";
}

.bi-code:before {
  content: "";
}

.bi-collection-fill:before {
  content: "";
}

.bi-collection-play-fill:before {
  content: "";
}

.bi-collection-play:before {
  content: "";
}

.bi-collection:before {
  content: "";
}

.bi-columns-gap:before {
  content: "";
}

.bi-columns:before {
  content: "";
}

.bi-command:before {
  content: "";
}

.bi-compass-fill:before {
  content: "";
}

.bi-compass:before {
  content: "";
}

.bi-cone-striped:before {
  content: "";
}

.bi-cone:before {
  content: "";
}

.bi-controller:before {
  content: "";
}

.bi-cpu-fill:before {
  content: "";
}

.bi-cpu:before {
  content: "";
}

.bi-credit-card-2-back-fill:before {
  content: "";
}

.bi-credit-card-2-back:before {
  content: "";
}

.bi-credit-card-2-front-fill:before {
  content: "";
}

.bi-credit-card-2-front:before {
  content: "";
}

.bi-credit-card-fill:before {
  content: "";
}

.bi-credit-card:before {
  content: "";
}

.bi-crop:before {
  content: "";
}

.bi-cup-fill:before {
  content: "";
}

.bi-cup-straw:before {
  content: "";
}

.bi-cup:before {
  content: "";
}

.bi-cursor-fill:before {
  content: "";
}

.bi-cursor-text:before {
  content: "";
}

.bi-cursor:before {
  content: "";
}

.bi-dash-circle-dotted:before {
  content: "";
}

.bi-dash-circle-fill:before {
  content: "";
}

.bi-dash-circle:before {
  content: "";
}

.bi-dash-square-dotted:before {
  content: "";
}

.bi-dash-square-fill:before {
  content: "";
}

.bi-dash-square:before {
  content: "";
}

.bi-dash:before {
  content: "";
}

.bi-diagram-2-fill:before {
  content: "";
}

.bi-diagram-2:before {
  content: "";
}

.bi-diagram-3-fill:before {
  content: "";
}

.bi-diagram-3:before {
  content: "";
}

.bi-diamond-fill:before {
  content: "";
}

.bi-diamond-half:before {
  content: "";
}

.bi-diamond:before {
  content: "";
}

.bi-dice-1-fill:before {
  content: "";
}

.bi-dice-1:before {
  content: "";
}

.bi-dice-2-fill:before {
  content: "";
}

.bi-dice-2:before {
  content: "";
}

.bi-dice-3-fill:before {
  content: "";
}

.bi-dice-3:before {
  content: "";
}

.bi-dice-4-fill:before {
  content: "";
}

.bi-dice-4:before {
  content: "";
}

.bi-dice-5-fill:before {
  content: "";
}

.bi-dice-5:before {
  content: "";
}

.bi-dice-6-fill:before {
  content: "";
}

.bi-dice-6:before {
  content: "";
}

.bi-disc-fill:before {
  content: "";
}

.bi-disc:before {
  content: "";
}

.bi-discord:before {
  content: "";
}

.bi-display-fill:before {
  content: "";
}

.bi-display:before {
  content: "";
}

.bi-distribute-horizontal:before {
  content: "";
}

.bi-distribute-vertical:before {
  content: "";
}

.bi-door-closed-fill:before {
  content: "";
}

.bi-door-closed:before {
  content: "";
}

.bi-door-open-fill:before {
  content: "";
}

.bi-door-open:before {
  content: "";
}

.bi-dot:before {
  content: "";
}

.bi-download:before {
  content: "";
}

.bi-droplet-fill:before {
  content: "";
}

.bi-droplet-half:before {
  content: "";
}

.bi-droplet:before {
  content: "";
}

.bi-earbuds:before {
  content: "";
}

.bi-easel-fill:before {
  content: "";
}

.bi-easel:before {
  content: "";
}

.bi-egg-fill:before {
  content: "";
}

.bi-egg-fried:before {
  content: "";
}

.bi-egg:before {
  content: "";
}

.bi-eject-fill:before {
  content: "";
}

.bi-eject:before {
  content: "";
}

.bi-emoji-angry-fill:before {
  content: "";
}

.bi-emoji-angry:before {
  content: "";
}

.bi-emoji-dizzy-fill:before {
  content: "";
}

.bi-emoji-dizzy:before {
  content: "";
}

.bi-emoji-expressionless-fill:before {
  content: "";
}

.bi-emoji-expressionless:before {
  content: "";
}

.bi-emoji-frown-fill:before {
  content: "";
}

.bi-emoji-frown:before {
  content: "";
}

.bi-emoji-heart-eyes-fill:before {
  content: "";
}

.bi-emoji-heart-eyes:before {
  content: "";
}

.bi-emoji-laughing-fill:before {
  content: "";
}

.bi-emoji-laughing:before {
  content: "";
}

.bi-emoji-neutral-fill:before {
  content: "";
}

.bi-emoji-neutral:before {
  content: "";
}

.bi-emoji-smile-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down-fill:before {
  content: "";
}

.bi-emoji-smile-upside-down:before {
  content: "";
}

.bi-emoji-smile:before {
  content: "";
}

.bi-emoji-sunglasses-fill:before {
  content: "";
}

.bi-emoji-sunglasses:before {
  content: "";
}

.bi-emoji-wink-fill:before {
  content: "";
}

.bi-emoji-wink:before {
  content: "";
}

.bi-envelope-fill:before {
  content: "";
}

.bi-envelope-open-fill:before {
  content: "";
}

.bi-envelope-open:before {
  content: "";
}

.bi-envelope:before {
  content: "";
}

.bi-eraser-fill:before {
  content: "";
}

.bi-eraser:before {
  content: "";
}

.bi-exclamation-circle-fill:before {
  content: "";
}

.bi-exclamation-circle:before {
  content: "";
}

.bi-exclamation-diamond-fill:before {
  content: "";
}

.bi-exclamation-diamond:before {
  content: "";
}

.bi-exclamation-octagon-fill:before {
  content: "";
}

.bi-exclamation-octagon:before {
  content: "";
}

.bi-exclamation-square-fill:before {
  content: "";
}

.bi-exclamation-square:before {
  content: "";
}

.bi-exclamation-triangle-fill:before {
  content: "";
}

.bi-exclamation-triangle:before {
  content: "";
}

.bi-exclamation:before {
  content: "";
}

.bi-exclude:before {
  content: "";
}

.bi-eye-fill:before {
  content: "";
}

.bi-eye-slash-fill:before {
  content: "";
}

.bi-eye-slash:before {
  content: "";
}

.bi-eye:before {
  content: "";
}

.bi-eyedropper:before {
  content: "";
}

.bi-eyeglasses:before {
  content: "";
}

.bi-facebook:before {
  content: "";
}

.bi-file-arrow-down-fill:before {
  content: "";
}

.bi-file-arrow-down:before {
  content: "";
}

.bi-file-arrow-up-fill:before {
  content: "";
}

.bi-file-arrow-up:before {
  content: "";
}

.bi-file-bar-graph-fill:before {
  content: "";
}

.bi-file-bar-graph:before {
  content: "";
}

.bi-file-binary-fill:before {
  content: "";
}

.bi-file-binary:before {
  content: "";
}

.bi-file-break-fill:before {
  content: "";
}

.bi-file-break:before {
  content: "";
}

.bi-file-check-fill:before {
  content: "";
}

.bi-file-check:before {
  content: "";
}

.bi-file-code-fill:before {
  content: "";
}

.bi-file-code:before {
  content: "";
}

.bi-file-diff-fill:before {
  content: "";
}

.bi-file-diff:before {
  content: "";
}

.bi-file-earmark-arrow-down-fill:before {
  content: "";
}

.bi-file-earmark-arrow-down:before {
  content: "";
}

.bi-file-earmark-arrow-up-fill:before {
  content: "";
}

.bi-file-earmark-arrow-up:before {
  content: "";
}

.bi-file-earmark-bar-graph-fill:before {
  content: "";
}

.bi-file-earmark-bar-graph:before {
  content: "";
}

.bi-file-earmark-binary-fill:before {
  content: "";
}

.bi-file-earmark-binary:before {
  content: "";
}

.bi-file-earmark-break-fill:before {
  content: "";
}

.bi-file-earmark-break:before {
  content: "";
}

.bi-file-earmark-check-fill:before {
  content: "";
}

.bi-file-earmark-check:before {
  content: "";
}

.bi-file-earmark-code-fill:before {
  content: "";
}

.bi-file-earmark-code:before {
  content: "";
}

.bi-file-earmark-diff-fill:before {
  content: "";
}

.bi-file-earmark-diff:before {
  content: "";
}

.bi-file-earmark-easel-fill:before {
  content: "";
}

.bi-file-earmark-easel:before {
  content: "";
}

.bi-file-earmark-excel-fill:before {
  content: "";
}

.bi-file-earmark-excel:before {
  content: "";
}

.bi-file-earmark-fill:before {
  content: "";
}

.bi-file-earmark-font-fill:before {
  content: "";
}

.bi-file-earmark-font:before {
  content: "";
}

.bi-file-earmark-image-fill:before {
  content: "";
}

.bi-file-earmark-image:before {
  content: "";
}

.bi-file-earmark-lock-fill:before {
  content: "";
}

.bi-file-earmark-lock:before {
  content: "";
}

.bi-file-earmark-lock2-fill:before {
  content: "";
}

.bi-file-earmark-lock2:before {
  content: "";
}

.bi-file-earmark-medical-fill:before {
  content: "";
}

.bi-file-earmark-medical:before {
  content: "";
}

.bi-file-earmark-minus-fill:before {
  content: "";
}

.bi-file-earmark-minus:before {
  content: "";
}

.bi-file-earmark-music-fill:before {
  content: "";
}

.bi-file-earmark-music:before {
  content: "";
}

.bi-file-earmark-person-fill:before {
  content: "";
}

.bi-file-earmark-person:before {
  content: "";
}

.bi-file-earmark-play-fill:before {
  content: "";
}

.bi-file-earmark-play:before {
  content: "";
}

.bi-file-earmark-plus-fill:before {
  content: "";
}

.bi-file-earmark-plus:before {
  content: "";
}

.bi-file-earmark-post-fill:before {
  content: "";
}

.bi-file-earmark-post:before {
  content: "";
}

.bi-file-earmark-ppt-fill:before {
  content: "";
}

.bi-file-earmark-ppt:before {
  content: "";
}

.bi-file-earmark-richtext-fill:before {
  content: "";
}

.bi-file-earmark-richtext:before {
  content: "";
}

.bi-file-earmark-ruled-fill:before {
  content: "";
}

.bi-file-earmark-ruled:before {
  content: "";
}

.bi-file-earmark-slides-fill:before {
  content: "";
}

.bi-file-earmark-slides:before {
  content: "";
}

.bi-file-earmark-spreadsheet-fill:before {
  content: "";
}

.bi-file-earmark-spreadsheet:before {
  content: "";
}

.bi-file-earmark-text-fill:before {
  content: "";
}

.bi-file-earmark-text:before {
  content: "";
}

.bi-file-earmark-word-fill:before {
  content: "";
}

.bi-file-earmark-word:before {
  content: "";
}

.bi-file-earmark-x-fill:before {
  content: "";
}

.bi-file-earmark-x:before {
  content: "";
}

.bi-file-earmark-zip-fill:before {
  content: "";
}

.bi-file-earmark-zip:before {
  content: "";
}

.bi-file-earmark:before {
  content: "";
}

.bi-file-easel-fill:before {
  content: "";
}

.bi-file-easel:before {
  content: "";
}

.bi-file-excel-fill:before {
  content: "";
}

.bi-file-excel:before {
  content: "";
}

.bi-file-fill:before {
  content: "";
}

.bi-file-font-fill:before {
  content: "";
}

.bi-file-font:before {
  content: "";
}

.bi-file-image-fill:before {
  content: "";
}

.bi-file-image:before {
  content: "";
}

.bi-file-lock-fill:before {
  content: "";
}

.bi-file-lock:before {
  content: "";
}

.bi-file-lock2-fill:before {
  content: "";
}

.bi-file-lock2:before {
  content: "";
}

.bi-file-medical-fill:before {
  content: "";
}

.bi-file-medical:before {
  content: "";
}

.bi-file-minus-fill:before {
  content: "";
}

.bi-file-minus:before {
  content: "";
}

.bi-file-music-fill:before {
  content: "";
}

.bi-file-music:before {
  content: "";
}

.bi-file-person-fill:before {
  content: "";
}

.bi-file-person:before {
  content: "";
}

.bi-file-play-fill:before {
  content: "";
}

.bi-file-play:before {
  content: "";
}

.bi-file-plus-fill:before {
  content: "";
}

.bi-file-plus:before {
  content: "";
}

.bi-file-post-fill:before {
  content: "";
}

.bi-file-post:before {
  content: "";
}

.bi-file-ppt-fill:before {
  content: "";
}

.bi-file-ppt:before {
  content: "";
}

.bi-file-richtext-fill:before {
  content: "";
}

.bi-file-richtext:before {
  content: "";
}

.bi-file-ruled-fill:before {
  content: "";
}

.bi-file-ruled:before {
  content: "";
}

.bi-file-slides-fill:before {
  content: "";
}

.bi-file-slides:before {
  content: "";
}

.bi-file-spreadsheet-fill:before {
  content: "";
}

.bi-file-spreadsheet:before {
  content: "";
}

.bi-file-text-fill:before {
  content: "";
}

.bi-file-text:before {
  content: "";
}

.bi-file-word-fill:before {
  content: "";
}

.bi-file-word:before {
  content: "";
}

.bi-file-x-fill:before {
  content: "";
}

.bi-file-x:before {
  content: "";
}

.bi-file-zip-fill:before {
  content: "";
}

.bi-file-zip:before {
  content: "";
}

.bi-file:before {
  content: "";
}

.bi-files-alt:before {
  content: "";
}

.bi-files:before {
  content: "";
}

.bi-film:before {
  content: "";
}

.bi-filter-circle-fill:before {
  content: "";
}

.bi-filter-circle:before {
  content: "";
}

.bi-filter-left:before {
  content: "";
}

.bi-filter-right:before {
  content: "";
}

.bi-filter-square-fill:before {
  content: "";
}

.bi-filter-square:before {
  content: "";
}

.bi-filter:before {
  content: "";
}

.bi-flag-fill:before {
  content: "";
}

.bi-flag:before {
  content: "";
}

.bi-flower1:before {
  content: "";
}

.bi-flower2:before {
  content: "";
}

.bi-flower3:before {
  content: "";
}

.bi-folder-check:before {
  content: "";
}

.bi-folder-fill:before {
  content: "";
}

.bi-folder-minus:before {
  content: "";
}

.bi-folder-plus:before {
  content: "";
}

.bi-folder-symlink-fill:before {
  content: "";
}

.bi-folder-symlink:before {
  content: "";
}

.bi-folder-x:before {
  content: "";
}

.bi-folder:before {
  content: "";
}

.bi-folder2-open:before {
  content: "";
}

.bi-folder2:before {
  content: "";
}

.bi-fonts:before {
  content: "";
}

.bi-forward-fill:before {
  content: "";
}

.bi-forward:before {
  content: "";
}

.bi-front:before {
  content: "";
}

.bi-fullscreen-exit:before {
  content: "";
}

.bi-fullscreen:before {
  content: "";
}

.bi-funnel-fill:before {
  content: "";
}

.bi-funnel:before {
  content: "";
}

.bi-gear-fill:before {
  content: "";
}

.bi-gear-wide-connected:before {
  content: "";
}

.bi-gear-wide:before {
  content: "";
}

.bi-gear:before {
  content: "";
}

.bi-gem:before {
  content: "";
}

.bi-geo-alt-fill:before {
  content: "";
}

.bi-geo-alt:before {
  content: "";
}

.bi-geo-fill:before {
  content: "";
}

.bi-geo:before {
  content: "";
}

.bi-gift-fill:before {
  content: "";
}

.bi-gift:before {
  content: "";
}

.bi-github:before {
  content: "";
}

.bi-globe:before {
  content: "";
}

.bi-globe2:before {
  content: "";
}

.bi-google:before {
  content: "";
}

.bi-graph-down:before {
  content: "";
}

.bi-graph-up:before {
  content: "";
}

.bi-grid-1x2-fill:before {
  content: "";
}

.bi-grid-1x2:before {
  content: "";
}

.bi-grid-3x2-gap-fill:before {
  content: "";
}

.bi-grid-3x2-gap:before {
  content: "";
}

.bi-grid-3x2:before {
  content: "";
}

.bi-grid-3x3-gap-fill:before {
  content: "";
}

.bi-grid-3x3-gap:before {
  content: "";
}

.bi-grid-3x3:before {
  content: "";
}

.bi-grid-fill:before {
  content: "";
}

.bi-grid:before {
  content: "";
}

.bi-grip-horizontal:before {
  content: "";
}

.bi-grip-vertical:before {
  content: "";
}

.bi-hammer:before {
  content: "";
}

.bi-hand-index-fill:before {
  content: "";
}

.bi-hand-index-thumb-fill:before {
  content: "";
}

.bi-hand-index-thumb:before {
  content: "";
}

.bi-hand-index:before {
  content: "";
}

.bi-hand-thumbs-down-fill:before {
  content: "";
}

.bi-hand-thumbs-down:before {
  content: "";
}

.bi-hand-thumbs-up-fill:before {
  content: "";
}

.bi-hand-thumbs-up:before {
  content: "";
}

.bi-handbag-fill:before {
  content: "";
}

.bi-handbag:before {
  content: "";
}

.bi-hash:before {
  content: "";
}

.bi-hdd-fill:before {
  content: "";
}

.bi-hdd-network-fill:before {
  content: "";
}

.bi-hdd-network:before {
  content: "";
}

.bi-hdd-rack-fill:before {
  content: "";
}

.bi-hdd-rack:before {
  content: "";
}

.bi-hdd-stack-fill:before {
  content: "";
}

.bi-hdd-stack:before {
  content: "";
}

.bi-hdd:before {
  content: "";
}

.bi-headphones:before {
  content: "";
}

.bi-headset:before {
  content: "";
}

.bi-heart-fill:before {
  content: "";
}

.bi-heart-half:before {
  content: "";
}

.bi-heart:before {
  content: "";
}

.bi-heptagon-fill:before {
  content: "";
}

.bi-heptagon-half:before {
  content: "";
}

.bi-heptagon:before {
  content: "";
}

.bi-hexagon-fill:before {
  content: "";
}

.bi-hexagon-half:before {
  content: "";
}

.bi-hexagon:before {
  content: "";
}

.bi-hourglass-bottom:before {
  content: "";
}

.bi-hourglass-split:before {
  content: "";
}

.bi-hourglass-top:before {
  content: "";
}

.bi-hourglass:before {
  content: "";
}

.bi-house-door-fill:before {
  content: "";
}

.bi-house-door:before {
  content: "";
}

.bi-house-fill:before {
  content: "";
}

.bi-house:before {
  content: "";
}

.bi-hr:before {
  content: "";
}

.bi-hurricane:before {
  content: "";
}

.bi-image-alt:before {
  content: "";
}

.bi-image-fill:before {
  content: "";
}

.bi-image:before {
  content: "";
}

.bi-images:before {
  content: "";
}

.bi-inbox-fill:before {
  content: "";
}

.bi-inbox:before {
  content: "";
}

.bi-inboxes-fill:before {
  content: "";
}

.bi-inboxes:before {
  content: "";
}

.bi-info-circle-fill:before {
  content: "";
}

.bi-info-circle:before {
  content: "";
}

.bi-info-square-fill:before {
  content: "";
}

.bi-info-square:before {
  content: "";
}

.bi-info:before {
  content: "";
}

.bi-input-cursor-text:before {
  content: "";
}

.bi-input-cursor:before {
  content: "";
}

.bi-instagram:before {
  content: "";
}

.bi-intersect:before {
  content: "";
}

.bi-journal-album:before {
  content: "";
}

.bi-journal-arrow-down:before {
  content: "";
}

.bi-journal-arrow-up:before {
  content: "";
}

.bi-journal-bookmark-fill:before {
  content: "";
}

.bi-journal-bookmark:before {
  content: "";
}

.bi-journal-check:before {
  content: "";
}

.bi-journal-code:before {
  content: "";
}

.bi-journal-medical:before {
  content: "";
}

.bi-journal-minus:before {
  content: "";
}

.bi-journal-plus:before {
  content: "";
}

.bi-journal-richtext:before {
  content: "";
}

.bi-journal-text:before {
  content: "";
}

.bi-journal-x:before {
  content: "";
}

.bi-journal:before {
  content: "";
}

.bi-journals:before {
  content: "";
}

.bi-joystick:before {
  content: "";
}

.bi-justify-left:before {
  content: "";
}

.bi-justify-right:before {
  content: "";
}

.bi-justify:before {
  content: "";
}

.bi-kanban-fill:before {
  content: "";
}

.bi-kanban:before {
  content: "";
}

.bi-key-fill:before {
  content: "";
}

.bi-key:before {
  content: "";
}

.bi-keyboard-fill:before {
  content: "";
}

.bi-keyboard:before {
  content: "";
}

.bi-ladder:before {
  content: "";
}

.bi-lamp-fill:before {
  content: "";
}

.bi-lamp:before {
  content: "";
}

.bi-laptop-fill:before {
  content: "";
}

.bi-laptop:before {
  content: "";
}

.bi-layer-backward:before {
  content: "";
}

.bi-layer-forward:before {
  content: "";
}

.bi-layers-fill:before {
  content: "";
}

.bi-layers-half:before {
  content: "";
}

.bi-layers:before {
  content: "";
}

.bi-layout-sidebar-inset-reverse:before {
  content: "";
}

.bi-layout-sidebar-inset:before {
  content: "";
}

.bi-layout-sidebar-reverse:before {
  content: "";
}

.bi-layout-sidebar:before {
  content: "";
}

.bi-layout-split:before {
  content: "";
}

.bi-layout-text-sidebar-reverse:before {
  content: "";
}

.bi-layout-text-sidebar:before {
  content: "";
}

.bi-layout-text-window-reverse:before {
  content: "";
}

.bi-layout-text-window:before {
  content: "";
}

.bi-layout-three-columns:before {
  content: "";
}

.bi-layout-wtf:before {
  content: "";
}

.bi-life-preserver:before {
  content: "";
}

.bi-lightbulb-fill:before {
  content: "";
}

.bi-lightbulb-off-fill:before {
  content: "";
}

.bi-lightbulb-off:before {
  content: "";
}

.bi-lightbulb:before {
  content: "";
}

.bi-lightning-charge-fill:before {
  content: "";
}

.bi-lightning-charge:before {
  content: "";
}

.bi-lightning-fill:before {
  content: "";
}

.bi-lightning:before {
  content: "";
}

.bi-link-45deg:before {
  content: "";
}

.bi-link:before {
  content: "";
}

.bi-linkedin:before {
  content: "";
}

.bi-list-check:before {
  content: "";
}

.bi-list-nested:before {
  content: "";
}

.bi-list-ol:before {
  content: "";
}

.bi-list-stars:before {
  content: "";
}

.bi-list-task:before {
  content: "";
}

.bi-list-ul:before {
  content: "";
}

.bi-list:before {
  content: "";
}

.bi-lock-fill:before {
  content: "";
}

.bi-lock:before {
  content: "";
}

.bi-mailbox:before {
  content: "";
}

.bi-mailbox2:before {
  content: "";
}

.bi-map-fill:before {
  content: "";
}

.bi-map:before {
  content: "";
}

.bi-markdown-fill:before {
  content: "";
}

.bi-markdown:before {
  content: "";
}

.bi-mask:before {
  content: "";
}

.bi-megaphone-fill:before {
  content: "";
}

.bi-megaphone:before {
  content: "";
}

.bi-menu-app-fill:before {
  content: "";
}

.bi-menu-app:before {
  content: "";
}

.bi-menu-button-fill:before {
  content: "";
}

.bi-menu-button-wide-fill:before {
  content: "";
}

.bi-menu-button-wide:before {
  content: "";
}

.bi-menu-button:before {
  content: "";
}

.bi-menu-down:before {
  content: "";
}

.bi-menu-up:before {
  content: "";
}

.bi-mic-fill:before {
  content: "";
}

.bi-mic-mute-fill:before {
  content: "";
}

.bi-mic-mute:before {
  content: "";
}

.bi-mic:before {
  content: "";
}

.bi-minecart-loaded:before {
  content: "";
}

.bi-minecart:before {
  content: "";
}

.bi-moisture:before {
  content: "";
}

.bi-moon-fill:before {
  content: "";
}

.bi-moon-stars-fill:before {
  content: "";
}

.bi-moon-stars:before {
  content: "";
}

.bi-moon:before {
  content: "";
}

.bi-mouse-fill:before {
  content: "";
}

.bi-mouse:before {
  content: "";
}

.bi-mouse2-fill:before {
  content: "";
}

.bi-mouse2:before {
  content: "";
}

.bi-mouse3-fill:before {
  content: "";
}

.bi-mouse3:before {
  content: "";
}

.bi-music-note-beamed:before {
  content: "";
}

.bi-music-note-list:before {
  content: "";
}

.bi-music-note:before {
  content: "";
}

.bi-music-player-fill:before {
  content: "";
}

.bi-music-player:before {
  content: "";
}

.bi-newspaper:before {
  content: "";
}

.bi-node-minus-fill:before {
  content: "";
}

.bi-node-minus:before {
  content: "";
}

.bi-node-plus-fill:before {
  content: "";
}

.bi-node-plus:before {
  content: "";
}

.bi-nut-fill:before {
  content: "";
}

.bi-nut:before {
  content: "";
}

.bi-octagon-fill:before {
  content: "";
}

.bi-octagon-half:before {
  content: "";
}

.bi-octagon:before {
  content: "";
}

.bi-option:before {
  content: "";
}

.bi-outlet:before {
  content: "";
}

.bi-paint-bucket:before {
  content: "";
}

.bi-palette-fill:before {
  content: "";
}

.bi-palette:before {
  content: "";
}

.bi-palette2:before {
  content: "";
}

.bi-paperclip:before {
  content: "";
}

.bi-paragraph:before {
  content: "";
}

.bi-patch-check-fill:before {
  content: "";
}

.bi-patch-check:before {
  content: "";
}

.bi-patch-exclamation-fill:before {
  content: "";
}

.bi-patch-exclamation:before {
  content: "";
}

.bi-patch-minus-fill:before {
  content: "";
}

.bi-patch-minus:before {
  content: "";
}

.bi-patch-plus-fill:before {
  content: "";
}

.bi-patch-plus:before {
  content: "";
}

.bi-patch-question-fill:before {
  content: "";
}

.bi-patch-question:before {
  content: "";
}

.bi-pause-btn-fill:before {
  content: "";
}

.bi-pause-btn:before {
  content: "";
}

.bi-pause-circle-fill:before {
  content: "";
}

.bi-pause-circle:before {
  content: "";
}

.bi-pause-fill:before {
  content: "";
}

.bi-pause:before {
  content: "";
}

.bi-peace-fill:before {
  content: "";
}

.bi-peace:before {
  content: "";
}

.bi-pen-fill:before {
  content: "";
}

.bi-pen:before {
  content: "";
}

.bi-pencil-fill:before {
  content: "";
}

.bi-pencil-square:before {
  content: "";
}

.bi-pencil:before {
  content: "";
}

.bi-pentagon-fill:before {
  content: "";
}

.bi-pentagon-half:before {
  content: "";
}

.bi-pentagon:before {
  content: "";
}

.bi-people-fill:before {
  content: "";
}

.bi-people:before {
  content: "";
}

.bi-percent:before {
  content: "";
}

.bi-person-badge-fill:before {
  content: "";
}

.bi-person-badge:before {
  content: "";
}

.bi-person-bounding-box:before {
  content: "";
}

.bi-person-check-fill:before {
  content: "";
}

.bi-person-check:before {
  content: "";
}

.bi-person-circle:before {
  content: "";
}

.bi-person-dash-fill:before {
  content: "";
}

.bi-person-dash:before {
  content: "";
}

.bi-person-fill:before {
  content: "";
}

.bi-person-lines-fill:before {
  content: "";
}

.bi-person-plus-fill:before {
  content: "";
}

.bi-person-plus:before {
  content: "";
}

.bi-person-square:before {
  content: "";
}

.bi-person-x-fill:before {
  content: "";
}

.bi-person-x:before {
  content: "";
}

.bi-person:before {
  content: "";
}

.bi-phone-fill:before {
  content: "";
}

.bi-phone-landscape-fill:before {
  content: "";
}

.bi-phone-landscape:before {
  content: "";
}

.bi-phone-vibrate-fill:before {
  content: "";
}

.bi-phone-vibrate:before {
  content: "";
}

.bi-phone:before {
  content: "";
}

.bi-pie-chart-fill:before {
  content: "";
}

.bi-pie-chart:before {
  content: "";
}

.bi-pin-angle-fill:before {
  content: "";
}

.bi-pin-angle:before {
  content: "";
}

.bi-pin-fill:before {
  content: "";
}

.bi-pin:before {
  content: "";
}

.bi-pip-fill:before {
  content: "";
}

.bi-pip:before {
  content: "";
}

.bi-play-btn-fill:before {
  content: "";
}

.bi-play-btn:before {
  content: "";
}

.bi-play-circle-fill:before {
  content: "";
}

.bi-play-circle:before {
  content: "";
}

.bi-play-fill:before {
  content: "";
}

.bi-play:before {
  content: "";
}

.bi-plug-fill:before {
  content: "";
}

.bi-plug:before {
  content: "";
}

.bi-plus-circle-dotted:before {
  content: "";
}

.bi-plus-circle-fill:before {
  content: "";
}

.bi-plus-circle:before {
  content: "";
}

.bi-plus-square-dotted:before {
  content: "";
}

.bi-plus-square-fill:before {
  content: "";
}

.bi-plus-square:before {
  content: "";
}

.bi-plus:before {
  content: "";
}

.bi-power:before {
  content: "";
}

.bi-printer-fill:before {
  content: "";
}

.bi-printer:before {
  content: "";
}

.bi-puzzle-fill:before {
  content: "";
}

.bi-puzzle:before {
  content: "";
}

.bi-question-circle-fill:before {
  content: "";
}

.bi-question-circle:before {
  content: "";
}

.bi-question-diamond-fill:before {
  content: "";
}

.bi-question-diamond:before {
  content: "";
}

.bi-question-octagon-fill:before {
  content: "";
}

.bi-question-octagon:before {
  content: "";
}

.bi-question-square-fill:before {
  content: "";
}

.bi-question-square:before {
  content: "";
}

.bi-question:before {
  content: "";
}

.bi-rainbow:before {
  content: "";
}

.bi-receipt-cutoff:before {
  content: "";
}

.bi-receipt:before {
  content: "";
}

.bi-reception-0:before {
  content: "";
}

.bi-reception-1:before {
  content: "";
}

.bi-reception-2:before {
  content: "";
}

.bi-reception-3:before {
  content: "";
}

.bi-reception-4:before {
  content: "";
}

.bi-record-btn-fill:before {
  content: "";
}

.bi-record-btn:before {
  content: "";
}

.bi-record-circle-fill:before {
  content: "";
}

.bi-record-circle:before {
  content: "";
}

.bi-record-fill:before {
  content: "";
}

.bi-record:before {
  content: "";
}

.bi-record2-fill:before {
  content: "";
}

.bi-record2:before {
  content: "";
}

.bi-reply-all-fill:before {
  content: "";
}

.bi-reply-all:before {
  content: "";
}

.bi-reply-fill:before {
  content: "";
}

.bi-reply:before {
  content: "";
}

.bi-rss-fill:before {
  content: "";
}

.bi-rss:before {
  content: "";
}

.bi-rulers:before {
  content: "";
}

.bi-save-fill:before {
  content: "";
}

.bi-save:before {
  content: "";
}

.bi-save2-fill:before {
  content: "";
}

.bi-save2:before {
  content: "";
}

.bi-scissors:before {
  content: "";
}

.bi-screwdriver:before {
  content: "";
}

.bi-search:before {
  content: "";
}

.bi-segmented-nav:before {
  content: "";
}

.bi-server:before {
  content: "";
}

.bi-share-fill:before {
  content: "";
}

.bi-share:before {
  content: "";
}

.bi-shield-check:before {
  content: "";
}

.bi-shield-exclamation:before {
  content: "";
}

.bi-shield-fill-check:before {
  content: "";
}

.bi-shield-fill-exclamation:before {
  content: "";
}

.bi-shield-fill-minus:before {
  content: "";
}

.bi-shield-fill-plus:before {
  content: "";
}

.bi-shield-fill-x:before {
  content: "";
}

.bi-shield-fill:before {
  content: "";
}

.bi-shield-lock-fill:before {
  content: "";
}

.bi-shield-lock:before {
  content: "";
}

.bi-shield-minus:before {
  content: "";
}

.bi-shield-plus:before {
  content: "";
}

.bi-shield-shaded:before {
  content: "";
}

.bi-shield-slash-fill:before {
  content: "";
}

.bi-shield-slash:before {
  content: "";
}

.bi-shield-x:before {
  content: "";
}

.bi-shield:before {
  content: "";
}

.bi-shift-fill:before {
  content: "";
}

.bi-shift:before {
  content: "";
}

.bi-shop-window:before {
  content: "";
}

.bi-shop:before {
  content: "";
}

.bi-shuffle:before {
  content: "";
}

.bi-signpost-2-fill:before {
  content: "";
}

.bi-signpost-2:before {
  content: "";
}

.bi-signpost-fill:before {
  content: "";
}

.bi-signpost-split-fill:before {
  content: "";
}

.bi-signpost-split:before {
  content: "";
}

.bi-signpost:before {
  content: "";
}

.bi-sim-fill:before {
  content: "";
}

.bi-sim:before {
  content: "";
}

.bi-skip-backward-btn-fill:before {
  content: "";
}

.bi-skip-backward-btn:before {
  content: "";
}

.bi-skip-backward-circle-fill:before {
  content: "";
}

.bi-skip-backward-circle:before {
  content: "";
}

.bi-skip-backward-fill:before {
  content: "";
}

.bi-skip-backward:before {
  content: "";
}

.bi-skip-end-btn-fill:before {
  content: "";
}

.bi-skip-end-btn:before {
  content: "";
}

.bi-skip-end-circle-fill:before {
  content: "";
}

.bi-skip-end-circle:before {
  content: "";
}

.bi-skip-end-fill:before {
  content: "";
}

.bi-skip-end:before {
  content: "";
}

.bi-skip-forward-btn-fill:before {
  content: "";
}

.bi-skip-forward-btn:before {
  content: "";
}

.bi-skip-forward-circle-fill:before {
  content: "";
}

.bi-skip-forward-circle:before {
  content: "";
}

.bi-skip-forward-fill:before {
  content: "";
}

.bi-skip-forward:before {
  content: "";
}

.bi-skip-start-btn-fill:before {
  content: "";
}

.bi-skip-start-btn:before {
  content: "";
}

.bi-skip-start-circle-fill:before {
  content: "";
}

.bi-skip-start-circle:before {
  content: "";
}

.bi-skip-start-fill:before {
  content: "";
}

.bi-skip-start:before {
  content: "";
}

.bi-slack:before {
  content: "";
}

.bi-slash-circle-fill:before {
  content: "";
}

.bi-slash-circle:before {
  content: "";
}

.bi-slash-square-fill:before {
  content: "";
}

.bi-slash-square:before {
  content: "";
}

.bi-slash:before {
  content: "";
}

.bi-sliders:before {
  content: "";
}

.bi-smartwatch:before {
  content: "";
}

.bi-snow:before {
  content: "";
}

.bi-snow2:before {
  content: "";
}

.bi-snow3:before {
  content: "";
}

.bi-sort-alpha-down-alt:before {
  content: "";
}

.bi-sort-alpha-down:before {
  content: "";
}

.bi-sort-alpha-up-alt:before {
  content: "";
}

.bi-sort-alpha-up:before {
  content: "";
}

.bi-sort-down-alt:before {
  content: "";
}

.bi-sort-down:before {
  content: "";
}

.bi-sort-numeric-down-alt:before {
  content: "";
}

.bi-sort-numeric-down:before {
  content: "";
}

.bi-sort-numeric-up-alt:before {
  content: "";
}

.bi-sort-numeric-up:before {
  content: "";
}

.bi-sort-up-alt:before {
  content: "";
}

.bi-sort-up:before {
  content: "";
}

.bi-soundwave:before {
  content: "";
}

.bi-speaker-fill:before {
  content: "";
}

.bi-speaker:before {
  content: "";
}

.bi-speedometer:before {
  content: "";
}

.bi-speedometer2:before {
  content: "";
}

.bi-spellcheck:before {
  content: "";
}

.bi-square-fill:before {
  content: "";
}

.bi-square-half:before {
  content: "";
}

.bi-square:before {
  content: "";
}

.bi-stack:before {
  content: "";
}

.bi-star-fill:before {
  content: "";
}

.bi-star-half:before {
  content: "";
}

.bi-star:before {
  content: "";
}

.bi-stars:before {
  content: "";
}

.bi-stickies-fill:before {
  content: "";
}

.bi-stickies:before {
  content: "";
}

.bi-sticky-fill:before {
  content: "";
}

.bi-sticky:before {
  content: "";
}

.bi-stop-btn-fill:before {
  content: "";
}

.bi-stop-btn:before {
  content: "";
}

.bi-stop-circle-fill:before {
  content: "";
}

.bi-stop-circle:before {
  content: "";
}

.bi-stop-fill:before {
  content: "";
}

.bi-stop:before {
  content: "";
}

.bi-stoplights-fill:before {
  content: "";
}

.bi-stoplights:before {
  content: "";
}

.bi-stopwatch-fill:before {
  content: "";
}

.bi-stopwatch:before {
  content: "";
}

.bi-subtract:before {
  content: "";
}

.bi-suit-club-fill:before {
  content: "";
}

.bi-suit-club:before {
  content: "";
}

.bi-suit-diamond-fill:before {
  content: "";
}

.bi-suit-diamond:before {
  content: "";
}

.bi-suit-heart-fill:before {
  content: "";
}

.bi-suit-heart:before {
  content: "";
}

.bi-suit-spade-fill:before {
  content: "";
}

.bi-suit-spade:before {
  content: "";
}

.bi-sun-fill:before {
  content: "";
}

.bi-sun:before {
  content: "";
}

.bi-sunglasses:before {
  content: "";
}

.bi-sunrise-fill:before {
  content: "";
}

.bi-sunrise:before {
  content: "";
}

.bi-sunset-fill:before {
  content: "";
}

.bi-sunset:before {
  content: "";
}

.bi-symmetry-horizontal:before {
  content: "";
}

.bi-symmetry-vertical:before {
  content: "";
}

.bi-table:before {
  content: "";
}

.bi-tablet-fill:before {
  content: "";
}

.bi-tablet-landscape-fill:before {
  content: "";
}

.bi-tablet-landscape:before {
  content: "";
}

.bi-tablet:before {
  content: "";
}

.bi-tag-fill:before {
  content: "";
}

.bi-tag:before {
  content: "";
}

.bi-tags-fill:before {
  content: "";
}

.bi-tags:before {
  content: "";
}

.bi-telegram:before {
  content: "";
}

.bi-telephone-fill:before {
  content: "";
}

.bi-telephone-forward-fill:before {
  content: "";
}

.bi-telephone-forward:before {
  content: "";
}

.bi-telephone-inbound-fill:before {
  content: "";
}

.bi-telephone-inbound:before {
  content: "";
}

.bi-telephone-minus-fill:before {
  content: "";
}

.bi-telephone-minus:before {
  content: "";
}

.bi-telephone-outbound-fill:before {
  content: "";
}

.bi-telephone-outbound:before {
  content: "";
}

.bi-telephone-plus-fill:before {
  content: "";
}

.bi-telephone-plus:before {
  content: "";
}

.bi-telephone-x-fill:before {
  content: "";
}

.bi-telephone-x:before {
  content: "";
}

.bi-telephone:before {
  content: "";
}

.bi-terminal-fill:before {
  content: "";
}

.bi-terminal:before {
  content: "";
}

.bi-text-center:before {
  content: "";
}

.bi-text-indent-left:before {
  content: "";
}

.bi-text-indent-right:before {
  content: "";
}

.bi-text-left:before {
  content: "";
}

.bi-text-paragraph:before {
  content: "";
}

.bi-text-right:before {
  content: "";
}

.bi-textarea-resize:before {
  content: "";
}

.bi-textarea-t:before {
  content: "";
}

.bi-textarea:before {
  content: "";
}

.bi-thermometer-half:before {
  content: "";
}

.bi-thermometer-high:before {
  content: "";
}

.bi-thermometer-low:before {
  content: "";
}

.bi-thermometer-snow:before {
  content: "";
}

.bi-thermometer-sun:before {
  content: "";
}

.bi-thermometer:before {
  content: "";
}

.bi-three-dots-vertical:before {
  content: "";
}

.bi-three-dots:before {
  content: "";
}

.bi-toggle-off:before {
  content: "";
}

.bi-toggle-on:before {
  content: "";
}

.bi-toggle2-off:before {
  content: "";
}

.bi-toggle2-on:before {
  content: "";
}

.bi-toggles:before {
  content: "";
}

.bi-toggles2:before {
  content: "";
}

.bi-tools:before {
  content: "";
}

.bi-tornado:before {
  content: "";
}

.bi-trash-fill:before {
  content: "";
}

.bi-trash:before {
  content: "";
}

.bi-trash2-fill:before {
  content: "";
}

.bi-trash2:before {
  content: "";
}

.bi-tree-fill:before {
  content: "";
}

.bi-tree:before {
  content: "";
}

.bi-triangle-fill:before {
  content: "";
}

.bi-triangle-half:before {
  content: "";
}

.bi-triangle:before {
  content: "";
}

.bi-trophy-fill:before {
  content: "";
}

.bi-trophy:before {
  content: "";
}

.bi-tropical-storm:before {
  content: "";
}

.bi-truck-flatbed:before {
  content: "";
}

.bi-truck:before {
  content: "";
}

.bi-tsunami:before {
  content: "";
}

.bi-tv-fill:before {
  content: "";
}

.bi-tv:before {
  content: "";
}

.bi-twitch:before {
  content: "";
}

.bi-twitter:before {
  content: "";
}

.bi-type-bold:before {
  content: "";
}

.bi-type-h1:before {
  content: "";
}

.bi-type-h2:before {
  content: "";
}

.bi-type-h3:before {
  content: "";
}

.bi-type-italic:before {
  content: "";
}

.bi-type-strikethrough:before {
  content: "";
}

.bi-type-underline:before {
  content: "";
}

.bi-type:before {
  content: "";
}

.bi-ui-checks-grid:before {
  content: "";
}

.bi-ui-checks:before {
  content: "";
}

.bi-ui-radios-grid:before {
  content: "";
}

.bi-ui-radios:before {
  content: "";
}

.bi-umbrella-fill:before {
  content: "";
}

.bi-umbrella:before {
  content: "";
}

.bi-union:before {
  content: "";
}

.bi-unlock-fill:before {
  content: "";
}

.bi-unlock:before {
  content: "";
}

.bi-upc-scan:before {
  content: "";
}

.bi-upc:before {
  content: "";
}

.bi-upload:before {
  content: "";
}

.bi-vector-pen:before {
  content: "";
}

.bi-view-list:before {
  content: "";
}

.bi-view-stacked:before {
  content: "";
}

.bi-vinyl-fill:before {
  content: "";
}

.bi-vinyl:before {
  content: "";
}

.bi-voicemail:before {
  content: "";
}

.bi-volume-down-fill:before {
  content: "";
}

.bi-volume-down:before {
  content: "";
}

.bi-volume-mute-fill:before {
  content: "";
}

.bi-volume-mute:before {
  content: "";
}

.bi-volume-off-fill:before {
  content: "";
}

.bi-volume-off:before {
  content: "";
}

.bi-volume-up-fill:before {
  content: "";
}

.bi-volume-up:before {
  content: "";
}

.bi-vr:before {
  content: "";
}

.bi-wallet-fill:before {
  content: "";
}

.bi-wallet:before {
  content: "";
}

.bi-wallet2:before {
  content: "";
}

.bi-watch:before {
  content: "";
}

.bi-water:before {
  content: "";
}

.bi-whatsapp:before {
  content: "";
}

.bi-wifi-1:before {
  content: "";
}

.bi-wifi-2:before {
  content: "";
}

.bi-wifi-off:before {
  content: "";
}

.bi-wifi:before {
  content: "";
}

.bi-wind:before {
  content: "";
}

.bi-window-dock:before {
  content: "";
}

.bi-window-sidebar:before {
  content: "";
}

.bi-window:before {
  content: "";
}

.bi-wrench:before {
  content: "";
}

.bi-x-circle-fill:before {
  content: "";
}

.bi-x-circle:before {
  content: "";
}

.bi-x-diamond-fill:before {
  content: "";
}

.bi-x-diamond:before {
  content: "";
}

.bi-x-octagon-fill:before {
  content: "";
}

.bi-x-octagon:before {
  content: "";
}

.bi-x-square-fill:before {
  content: "";
}

.bi-x-square:before {
  content: "";
}

.bi-x:before {
  content: "";
}

.bi-youtube:before {
  content: "";
}

.bi-zoom-in:before {
  content: "";
}

.bi-zoom-out:before {
  content: "";
}

.bi-bank:before {
  content: "";
}

.bi-bank2:before {
  content: "";
}

.bi-bell-slash-fill:before {
  content: "";
}

.bi-bell-slash:before {
  content: "";
}

.bi-cash-coin:before {
  content: "";
}

.bi-check-lg:before {
  content: "";
}

.bi-coin:before {
  content: "";
}

.bi-currency-bitcoin:before {
  content: "";
}

.bi-currency-dollar:before {
  content: "";
}

.bi-currency-euro:before {
  content: "";
}

.bi-currency-exchange:before {
  content: "";
}

.bi-currency-pound:before {
  content: "";
}

.bi-currency-yen:before {
  content: "";
}

.bi-dash-lg:before {
  content: "";
}

.bi-exclamation-lg:before {
  content: "";
}

.bi-file-earmark-pdf-fill:before {
  content: "";
}

.bi-file-earmark-pdf:before {
  content: "";
}

.bi-file-pdf-fill:before {
  content: "";
}

.bi-file-pdf:before {
  content: "";
}

.bi-gender-ambiguous:before {
  content: "";
}

.bi-gender-female:before {
  content: "";
}

.bi-gender-male:before {
  content: "";
}

.bi-gender-trans:before {
  content: "";
}

.bi-headset-vr:before {
  content: "";
}

.bi-info-lg:before {
  content: "";
}

.bi-mastodon:before {
  content: "";
}

.bi-messenger:before {
  content: "";
}

.bi-piggy-bank-fill:before {
  content: "";
}

.bi-piggy-bank:before {
  content: "";
}

.bi-pin-map-fill:before {
  content: "";
}

.bi-pin-map:before {
  content: "";
}

.bi-plus-lg:before {
  content: "";
}

.bi-question-lg:before {
  content: "";
}

.bi-recycle:before {
  content: "";
}

.bi-reddit:before {
  content: "";
}

.bi-safe-fill:before {
  content: "";
}

.bi-safe2-fill:before {
  content: "";
}

.bi-safe2:before {
  content: "";
}

.bi-sd-card-fill:before {
  content: "";
}

.bi-sd-card:before {
  content: "";
}

.bi-skype:before {
  content: "";
}

.bi-slash-lg:before {
  content: "";
}

.bi-translate:before {
  content: "";
}

.bi-x-lg:before {
  content: "";
}

.bi-safe:before {
  content: "";
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "拏";
}

.fa-boxes:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dailymotion:before {
  content: "勒";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "串";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "龜";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "樂";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "諾";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-wash:before {
  content: "丹";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "寧";
}

.fa-handshake-slash:before {
  content: "怒";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "率";
}

.fa-head-side-cough-slash:before {
  content: "異";
}

.fa-head-side-mask:before {
  content: "北";
}

.fa-head-side-virus:before {
  content: "磻";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-user:before {
  content: "便";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "邏";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "凌";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "復";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "不";
}

.fa-lyft:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "駱";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "稜";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "泌";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "爛";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "數";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pump-medical:before {
  content: "索";
}

.fa-pump-soap:before {
  content: "參";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "塞";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "綾";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "葉";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "說";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "殺";
}

.fa-store-slash:before {
  content: "辰";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "沈";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-trailer:before {
  content: "論";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "雷";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-virus:before {
  content: "若";
}

.fa-virus-slash:before {
  content: "掠";
}

.fa-viruses:before {
  content: "略";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.8cbcf94f.eot");
  src: url("fa-brands-400.8cbcf94f.eot#iefix") format("embedded-opentype"), url("fa-brands-400.ccd4367d.woff2") format("woff2"), url("fa-brands-400.42a366ea.woff") format("woff"), url("fa-brands-400.07f7396f.ttf") format("truetype"), url("fa-brands-400.9058b386.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.205b19bc.eot");
  src: url("fa-regular-400.205b19bc.eot#iefix") format("embedded-opentype"), url("fa-regular-400.d2526071.woff2") format("woff2"), url("fa-regular-400.8ac56b9e.woff") format("woff"), url("fa-regular-400.787d5e9c.ttf") format("truetype"), url("fa-regular-400.21aab298.svg#fontawesome") format("svg");
}

.fab, .far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.56a336d1.eot");
  src: url("fa-solid-900.56a336d1.eot#iefix") format("embedded-opentype"), url("fa-solid-900.2dd19dd0.woff2") format("woff2"), url("fa-solid-900.c7a67308.woff") format("woff"), url("fa-solid-900.d4e0ff2f.ttf") format("truetype"), url("fa-solid-900.5e1bef1f.svg#fontawesome") format("svg");
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free";
}

.fa, .fas {
  font-weight: 900;
}

@font-face {
  font-family: themify;
  src: url("themify.6cf27c62.eot");
  src: url("themify.6cf27c62.eot#iefix-fvbane") format("embedded-opentype"), url("themify.3a50b060.woff") format("woff"), url("themify.c97a53ee.ttf") format("truetype"), url("themify.d7c68abb.svg#themify") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: themify;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.ti-wand:before {
  content: "";
}

.ti-volume:before {
  content: "";
}

.ti-user:before {
  content: "";
}

.ti-unlock:before {
  content: "";
}

.ti-unlink:before {
  content: "";
}

.ti-trash:before {
  content: "";
}

.ti-thought:before {
  content: "";
}

.ti-target:before {
  content: "";
}

.ti-tag:before {
  content: "";
}

.ti-tablet:before {
  content: "";
}

.ti-star:before {
  content: "";
}

.ti-spray:before {
  content: "";
}

.ti-signal:before {
  content: "";
}

.ti-shopping-cart:before {
  content: "";
}

.ti-shopping-cart-full:before {
  content: "";
}

.ti-settings:before {
  content: "";
}

.ti-search:before {
  content: "";
}

.ti-zoom-in:before {
  content: "";
}

.ti-zoom-out:before {
  content: "";
}

.ti-cut:before {
  content: "";
}

.ti-ruler:before {
  content: "";
}

.ti-ruler-pencil:before {
  content: "";
}

.ti-ruler-alt:before {
  content: "";
}

.ti-bookmark:before {
  content: "";
}

.ti-bookmark-alt:before {
  content: "";
}

.ti-reload:before {
  content: "";
}

.ti-plus:before {
  content: "";
}

.ti-pin:before {
  content: "";
}

.ti-pencil:before {
  content: "";
}

.ti-pencil-alt:before {
  content: "";
}

.ti-paint-roller:before {
  content: "";
}

.ti-paint-bucket:before {
  content: "";
}

.ti-na:before {
  content: "";
}

.ti-mobile:before {
  content: "";
}

.ti-minus:before {
  content: "";
}

.ti-medall:before {
  content: "";
}

.ti-medall-alt:before {
  content: "";
}

.ti-marker:before {
  content: "";
}

.ti-marker-alt:before {
  content: "";
}

.ti-arrow-up:before {
  content: "";
}

.ti-arrow-right:before {
  content: "";
}

.ti-arrow-left:before {
  content: "";
}

.ti-arrow-down:before {
  content: "";
}

.ti-lock:before {
  content: "";
}

.ti-location-arrow:before {
  content: "";
}

.ti-link:before {
  content: "";
}

.ti-layout:before {
  content: "";
}

.ti-layers:before {
  content: "";
}

.ti-layers-alt:before {
  content: "";
}

.ti-key:before {
  content: "";
}

.ti-import:before {
  content: "";
}

.ti-image:before {
  content: "";
}

.ti-heart:before {
  content: "";
}

.ti-heart-broken:before {
  content: "";
}

.ti-hand-stop:before {
  content: "";
}

.ti-hand-open:before {
  content: "";
}

.ti-hand-drag:before {
  content: "";
}

.ti-folder:before {
  content: "";
}

.ti-flag:before {
  content: "";
}

.ti-flag-alt:before {
  content: "";
}

.ti-flag-alt-2:before {
  content: "";
}

.ti-eye:before {
  content: "";
}

.ti-export:before {
  content: "";
}

.ti-exchange-vertical:before {
  content: "";
}

.ti-desktop:before {
  content: "";
}

.ti-cup:before {
  content: "";
}

.ti-crown:before {
  content: "";
}

.ti-comments:before {
  content: "";
}

.ti-comment:before {
  content: "";
}

.ti-comment-alt:before {
  content: "";
}

.ti-close:before {
  content: "";
}

.ti-clip:before {
  content: "";
}

.ti-angle-up:before {
  content: "";
}

.ti-angle-right:before {
  content: "";
}

.ti-angle-left:before {
  content: "";
}

.ti-angle-down:before {
  content: "";
}

.ti-check:before {
  content: "";
}

.ti-check-box:before {
  content: "";
}

.ti-camera:before {
  content: "";
}

.ti-announcement:before {
  content: "";
}

.ti-brush:before {
  content: "";
}

.ti-briefcase:before {
  content: "";
}

.ti-bolt:before {
  content: "";
}

.ti-bolt-alt:before {
  content: "";
}

.ti-blackboard:before {
  content: "";
}

.ti-bag:before {
  content: "";
}

.ti-move:before {
  content: "";
}

.ti-arrows-vertical:before {
  content: "";
}

.ti-arrows-horizontal:before {
  content: "";
}

.ti-fullscreen:before {
  content: "";
}

.ti-arrow-top-right:before {
  content: "";
}

.ti-arrow-top-left:before {
  content: "";
}

.ti-arrow-circle-up:before {
  content: "";
}

.ti-arrow-circle-right:before {
  content: "";
}

.ti-arrow-circle-left:before {
  content: "";
}

.ti-arrow-circle-down:before {
  content: "";
}

.ti-angle-double-up:before {
  content: "";
}

.ti-angle-double-right:before {
  content: "";
}

.ti-angle-double-left:before {
  content: "";
}

.ti-angle-double-down:before {
  content: "";
}

.ti-zip:before {
  content: "";
}

.ti-world:before {
  content: "";
}

.ti-wheelchair:before {
  content: "";
}

.ti-view-list:before {
  content: "";
}

.ti-view-list-alt:before {
  content: "";
}

.ti-view-grid:before {
  content: "";
}

.ti-uppercase:before {
  content: "";
}

.ti-upload:before {
  content: "";
}

.ti-underline:before {
  content: "";
}

.ti-truck:before {
  content: "";
}

.ti-timer:before {
  content: "";
}

.ti-ticket:before {
  content: "";
}

.ti-thumb-up:before {
  content: "";
}

.ti-thumb-down:before {
  content: "";
}

.ti-text:before {
  content: "";
}

.ti-stats-up:before {
  content: "";
}

.ti-stats-down:before {
  content: "";
}

.ti-split-v:before {
  content: "";
}

.ti-split-h:before {
  content: "";
}

.ti-smallcap:before {
  content: "";
}

.ti-shine:before {
  content: "";
}

.ti-shift-right:before {
  content: "";
}

.ti-shift-left:before {
  content: "";
}

.ti-shield:before {
  content: "";
}

.ti-notepad:before {
  content: "";
}

.ti-server:before {
  content: "";
}

.ti-quote-right:before {
  content: "";
}

.ti-quote-left:before {
  content: "";
}

.ti-pulse:before {
  content: "";
}

.ti-printer:before {
  content: "";
}

.ti-power-off:before {
  content: "";
}

.ti-plug:before {
  content: "";
}

.ti-pie-chart:before {
  content: "";
}

.ti-paragraph:before {
  content: "";
}

.ti-panel:before {
  content: "";
}

.ti-package:before {
  content: "";
}

.ti-music:before {
  content: "";
}

.ti-music-alt:before {
  content: "";
}

.ti-mouse:before {
  content: "";
}

.ti-mouse-alt:before {
  content: "";
}

.ti-money:before {
  content: "";
}

.ti-microphone:before {
  content: "";
}

.ti-menu:before {
  content: "";
}

.ti-menu-alt:before {
  content: "";
}

.ti-map:before {
  content: "";
}

.ti-map-alt:before {
  content: "";
}

.ti-loop:before {
  content: "";
}

.ti-location-pin:before {
  content: "";
}

.ti-list:before {
  content: "";
}

.ti-light-bulb:before {
  content: "";
}

.ti-Italic:before {
  content: "";
}

.ti-info:before {
  content: "";
}

.ti-infinite:before {
  content: "";
}

.ti-id-badge:before {
  content: "";
}

.ti-hummer:before {
  content: "";
}

.ti-home:before {
  content: "";
}

.ti-help:before {
  content: "";
}

.ti-headphone:before {
  content: "";
}

.ti-harddrives:before {
  content: "";
}

.ti-harddrive:before {
  content: "";
}

.ti-gift:before {
  content: "";
}

.ti-game:before {
  content: "";
}

.ti-filter:before {
  content: "";
}

.ti-files:before {
  content: "";
}

.ti-file:before {
  content: "";
}

.ti-eraser:before {
  content: "";
}

.ti-envelope:before {
  content: "";
}

.ti-download:before {
  content: "";
}

.ti-direction:before {
  content: "";
}

.ti-direction-alt:before {
  content: "";
}

.ti-dashboard:before {
  content: "";
}

.ti-control-stop:before {
  content: "";
}

.ti-control-shuffle:before {
  content: "";
}

.ti-control-play:before {
  content: "";
}

.ti-control-pause:before {
  content: "";
}

.ti-control-forward:before {
  content: "";
}

.ti-control-backward:before {
  content: "";
}

.ti-cloud:before {
  content: "";
}

.ti-cloud-up:before {
  content: "";
}

.ti-cloud-down:before {
  content: "";
}

.ti-clipboard:before {
  content: "";
}

.ti-car:before {
  content: "";
}

.ti-calendar:before {
  content: "";
}

.ti-book:before {
  content: "";
}

.ti-bell:before {
  content: "";
}

.ti-basketball:before {
  content: "";
}

.ti-bar-chart:before {
  content: "";
}

.ti-bar-chart-alt:before {
  content: "";
}

.ti-back-right:before {
  content: "";
}

.ti-back-left:before {
  content: "";
}

.ti-arrows-corner:before {
  content: "";
}

.ti-archive:before {
  content: "";
}

.ti-anchor:before {
  content: "";
}

.ti-align-right:before {
  content: "";
}

.ti-align-left:before {
  content: "";
}

.ti-align-justify:before {
  content: "";
}

.ti-align-center:before {
  content: "";
}

.ti-alert:before {
  content: "";
}

.ti-alarm-clock:before {
  content: "";
}

.ti-agenda:before {
  content: "";
}

.ti-write:before {
  content: "";
}

.ti-window:before {
  content: "";
}

.ti-widgetized:before {
  content: "";
}

.ti-widget:before {
  content: "";
}

.ti-widget-alt:before {
  content: "";
}

.ti-wallet:before {
  content: "";
}

.ti-video-clapper:before {
  content: "";
}

.ti-video-camera:before {
  content: "";
}

.ti-vector:before {
  content: "";
}

.ti-themify-logo:before {
  content: "";
}

.ti-themify-favicon:before {
  content: "";
}

.ti-themify-favicon-alt:before {
  content: "";
}

.ti-support:before {
  content: "";
}

.ti-stamp:before {
  content: "";
}

.ti-split-v-alt:before {
  content: "";
}

.ti-slice:before {
  content: "";
}

.ti-shortcode:before {
  content: "";
}

.ti-shift-right-alt:before {
  content: "";
}

.ti-shift-left-alt:before {
  content: "";
}

.ti-ruler-alt-2:before {
  content: "";
}

.ti-receipt:before {
  content: "";
}

.ti-pin2:before {
  content: "";
}

.ti-pin-alt:before {
  content: "";
}

.ti-pencil-alt2:before {
  content: "";
}

.ti-palette:before {
  content: "";
}

.ti-more:before {
  content: "";
}

.ti-more-alt:before {
  content: "";
}

.ti-microphone-alt:before {
  content: "";
}

.ti-magnet:before {
  content: "";
}

.ti-line-double:before {
  content: "";
}

.ti-line-dotted:before {
  content: "";
}

.ti-line-dashed:before {
  content: "";
}

.ti-layout-width-full:before {
  content: "";
}

.ti-layout-width-default:before {
  content: "";
}

.ti-layout-width-default-alt:before {
  content: "";
}

.ti-layout-tab:before {
  content: "";
}

.ti-layout-tab-window:before {
  content: "";
}

.ti-layout-tab-v:before {
  content: "";
}

.ti-layout-tab-min:before {
  content: "";
}

.ti-layout-slider:before {
  content: "";
}

.ti-layout-slider-alt:before {
  content: "";
}

.ti-layout-sidebar-right:before {
  content: "";
}

.ti-layout-sidebar-none:before {
  content: "";
}

.ti-layout-sidebar-left:before {
  content: "";
}

.ti-layout-placeholder:before {
  content: "";
}

.ti-layout-menu:before {
  content: "";
}

.ti-layout-menu-v:before {
  content: "";
}

.ti-layout-menu-separated:before {
  content: "";
}

.ti-layout-menu-full:before {
  content: "";
}

.ti-layout-media-right-alt:before {
  content: "";
}

.ti-layout-media-right:before {
  content: "";
}

.ti-layout-media-overlay:before {
  content: "";
}

.ti-layout-media-overlay-alt:before {
  content: "";
}

.ti-layout-media-overlay-alt-2:before {
  content: "";
}

.ti-layout-media-left-alt:before {
  content: "";
}

.ti-layout-media-left:before {
  content: "";
}

.ti-layout-media-center-alt:before {
  content: "";
}

.ti-layout-media-center:before {
  content: "";
}

.ti-layout-list-thumb:before {
  content: "";
}

.ti-layout-list-thumb-alt:before {
  content: "";
}

.ti-layout-list-post:before {
  content: "";
}

.ti-layout-list-large-image:before {
  content: "";
}

.ti-layout-line-solid:before {
  content: "";
}

.ti-layout-grid4:before {
  content: "";
}

.ti-layout-grid3:before {
  content: "";
}

.ti-layout-grid2:before {
  content: "";
}

.ti-layout-grid2-thumb:before {
  content: "";
}

.ti-layout-cta-right:before {
  content: "";
}

.ti-layout-cta-left:before {
  content: "";
}

.ti-layout-cta-center:before {
  content: "";
}

.ti-layout-cta-btn-right:before {
  content: "";
}

.ti-layout-cta-btn-left:before {
  content: "";
}

.ti-layout-column4:before {
  content: "";
}

.ti-layout-column3:before {
  content: "";
}

.ti-layout-column2:before {
  content: "";
}

.ti-layout-accordion-separated:before {
  content: "";
}

.ti-layout-accordion-merged:before {
  content: "";
}

.ti-layout-accordion-list:before {
  content: "";
}

.ti-ink-pen:before {
  content: "";
}

.ti-info-alt:before {
  content: "";
}

.ti-help-alt:before {
  content: "";
}

.ti-headphone-alt:before {
  content: "";
}

.ti-hand-point-up:before {
  content: "";
}

.ti-hand-point-right:before {
  content: "";
}

.ti-hand-point-left:before {
  content: "";
}

.ti-hand-point-down:before {
  content: "";
}

.ti-gallery:before {
  content: "";
}

.ti-face-smile:before {
  content: "";
}

.ti-face-sad:before {
  content: "";
}

.ti-credit-card:before {
  content: "";
}

.ti-control-skip-forward:before {
  content: "";
}

.ti-control-skip-backward:before {
  content: "";
}

.ti-control-record:before {
  content: "";
}

.ti-control-eject:before {
  content: "";
}

.ti-comments-smiley:before {
  content: "";
}

.ti-brush-alt:before {
  content: "";
}

.ti-youtube:before {
  content: "";
}

.ti-vimeo:before {
  content: "";
}

.ti-twitter:before {
  content: "";
}

.ti-time:before {
  content: "";
}

.ti-tumblr:before {
  content: "";
}

.ti-skype:before {
  content: "";
}

.ti-share:before {
  content: "";
}

.ti-share-alt:before {
  content: "";
}

.ti-rocket:before {
  content: "";
}

.ti-pinterest:before {
  content: "";
}

.ti-new-window:before {
  content: "";
}

.ti-microsoft:before {
  content: "";
}

.ti-list-ol:before {
  content: "";
}

.ti-linkedin:before {
  content: "";
}

.ti-layout-sidebar-2:before {
  content: "";
}

.ti-layout-grid4-alt:before {
  content: "";
}

.ti-layout-grid3-alt:before {
  content: "";
}

.ti-layout-grid2-alt:before {
  content: "";
}

.ti-layout-column4-alt:before {
  content: "";
}

.ti-layout-column3-alt:before {
  content: "";
}

.ti-layout-column2-alt:before {
  content: "";
}

.ti-instagram:before {
  content: "";
}

.ti-google:before {
  content: "";
}

.ti-github:before {
  content: "";
}

.ti-flickr:before {
  content: "";
}

.ti-facebook:before {
  content: "";
}

.ti-dropbox:before {
  content: "";
}

.ti-dribbble:before {
  content: "";
}

.ti-apple:before {
  content: "";
}

.ti-android:before {
  content: "";
}

.ti-save:before {
  content: "";
}

.ti-save-alt:before {
  content: "";
}

.ti-yahoo:before {
  content: "";
}

.ti-wordpress:before {
  content: "";
}

.ti-vimeo-alt:before {
  content: "";
}

.ti-twitter-alt:before {
  content: "";
}

.ti-tumblr-alt:before {
  content: "";
}

.ti-trello:before {
  content: "";
}

.ti-stack-overflow:before {
  content: "";
}

.ti-soundcloud:before {
  content: "";
}

.ti-sharethis:before {
  content: "";
}

.ti-sharethis-alt:before {
  content: "";
}

.ti-reddit:before {
  content: "";
}

.ti-pinterest-alt:before {
  content: "";
}

.ti-microsoft-alt:before {
  content: "";
}

.ti-linux:before {
  content: "";
}

.ti-jsfiddle:before {
  content: "";
}

.ti-joomla:before {
  content: "";
}

.ti-html5:before {
  content: "";
}

.ti-flickr-alt:before {
  content: "";
}

.ti-email:before {
  content: "";
}

.ti-drupal:before {
  content: "";
}

.ti-dropbox-alt:before {
  content: "";
}

.ti-css3:before {
  content: "";
}

.ti-rss:before {
  content: "";
}

.ti-rss-alt:before {
  content: "";
}

.mfp-bg {
  width: 100%;
  height: 100%;
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  width: 100%;
  height: 100%;
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  width: auto;
  text-align: center;
  z-index: 1044;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  text-align: center;
  opacity: .65;
  color: #fff;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  width: 0;
  height: 0;
  border: inset #0000;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  width: 100%;
  height: 100%;
  background: #000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  width: auto;
  height: auto;
  z-index: -1;
  background: #444;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  width: 100%;
  cursor: auto;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #0009;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (max-width: 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse {
  animation-name: pulse;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }

  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%, 20% {
    transform: scale(.9)rotate(-3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale(1.1)rotate(3deg);
  }

  40%, 60%, 80% {
    transform: scale(1.1)rotate(-3deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%)rotate(-5deg);
  }

  30% {
    transform: translateX(20%)rotate(3deg);
  }

  45% {
    transform: translateX(-15%)rotate(-3deg);
  }

  60% {
    transform: translateX(10%)rotate(2deg);
  }

  75% {
    transform: translateX(-5%)rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(.3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(0)rotateY(0)scale(1);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)translateZ(150px)rotateY(170deg)scale(1);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(150px)rotateY(190deg)scale(1);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(.95);
  }

  100% {
    animation-timing-function: ease-in;
    transform: perspective(400px)translateZ(0)rotateY(360deg)scale(1);
  }
}

.animated.flip {
  backface-visibility: visible;
  -ms-backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }

  40% {
    transform: perspective(400px)rotateX(-10deg);
  }

  70% {
    transform: perspective(400px)rotateX(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipInY {
  0% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }

  40% {
    transform: perspective(400px)rotateY(-10deg);
  }

  70% {
    transform: perspective(400px)rotateY(10deg);
  }

  100% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipOutX {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateX(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateX(90deg);
  }
}

.flipOutX {
  animation-name: flipOutX;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes flipOutY {
  0% {
    opacity: 1;
    transform: perspective(400px)rotateY(0);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotateY(90deg);
  }
}

.flipOutY {
  animation-name: flipOutY;
  backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
}

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: translateX(-20%)skewX(30deg);
  }

  80% {
    opacity: 1;
    transform: translateX(0%)skewX(-15deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }
}

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    transform: translateX(0%)skewX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)skewX(-30deg);
  }
}

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  0% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(-200deg);
  }

  100% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateIn {
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownLeft {
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInDownRight {
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpLeft {
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }
}

.rotateInUpRight {
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  0% {
    transform-origin: center;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: center;
    opacity: 0;
    transform: rotate(200deg);
  }
}

.rotateOut {
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.rotateOutDownRight {
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: 0 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 0 100%;
    opacity: 0;
    transform: rotate(-90deg);
  }
}

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: 100% 100%;
    opacity: 1;
    transform: rotate(0);
  }

  100% {
    transform-origin: 100% 100%;
    opacity: 0;
    transform: rotate(90deg);
  }
}

.rotateOutUpRight {
  animation-name: rotateOutUpRight;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

@keyframes hinge {
  0% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(0);
  }

  20%, 60% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(80deg);
  }

  40% {
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg);
  }

  80% {
    opacity: 1;
    transform-origin: 0 0;
    animation-timing-function: ease-in-out;
    transform: rotate(60deg)translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(700px);
  }
}

.hinge {
  animation-name: hinge;
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%)rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%)rotate(120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:after, .noUi-handle:before {
  content: "";
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:after, .noUi-vertical .noUi-handle:before {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled] .noUi-handle, [disabled].noUi-handle, [disabled].noUi-target {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  height: 80px;
  width: 100%;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

:root {
  --bs-blue: #03c;
  --bs-indigo: #6e00ff;
  --bs-purple: #510fa8;
  --bs-pink: #f074ad;
  --bs-red: #f25767;
  --bs-orange: #ffbe3d;
  --bs-yellow: #ffc431;
  --bs-green: #5cc9a7;
  --bs-teal: #50b5ff;
  --bs-cyan: #4bd6e5;
  --bs-white: #fff;
  --bs-gray: #718096;
  --bs-gray-dark: #2d3748;
  --bs-gray-gray-100: #f1f6fd;
  --bs-gray-gray-200: #eaecf3;
  --bs-gray-gray-300: #e2e8f0;
  --bs-gray-gray-400: #cbd5e0;
  --bs-gray-gray-500: #a0aec0;
  --bs-gray-gray-600: #718096;
  --bs-gray-gray-700: #4a5568;
  --bs-gray-gray-800: #2d3748;
  --bs-gray-gray-900: #273444;
  --bs-primary: #03c;
  --bs-secondary: #15db95;
  --bs-success: #5cc9a7;
  --bs-info: #50b5ff;
  --bs-warning: #ffbe3d;
  --bs-danger: #f25767;
  --bs-light: #f1f6fd;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-dark: #08052f;
  --bs-primary-rgb: 0, 51, 204;
  --bs-secondary-rgb: 21, 219, 149;
  --bs-success-rgb: 92, 201, 167;
  --bs-info-rgb: 80, 181, 255;
  --bs-warning-rgb: 255, 190, 61;
  --bs-danger-rgb: 242, 87, 103;
  --bs-light-rgb: 241, 246, 253;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-dark-rgb: 8, 5, 47;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 113, 128, 150;
  --bs-font-sans-serif: "Inter", "Roboto", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #718096;
  --bs-body-bg: #fff;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: #08052f;
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: Inter, Roboto, sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-bs-original-title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #03c;
  text-decoration: none;
}

a:hover {
  color: #0029a3;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  direction: ltr ;
  unicode-bidi: bidi-override;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: #f074ad;
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #273444;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #a0aec0;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.725rem + 5.7vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 6rem;
  }
}

.display-2 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3rem;
  }
}

.display-5 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.5rem;
  }
}

.display-6 {
  font-size: calc(1.325rem + .9vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2rem;
  }
}

.display-7 {
  font-size: calc(1.3rem + .6vw);
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-7 {
    font-size: 1.75rem;
  }
}

.display-8 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #718096;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: .25rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #718096;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 2rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 2rem;
}

.g-6, .gx-6 {
  --bs-gutter-x: 2.5rem;
}

.g-6, .gy-6 {
  --bs-gutter-y: 2.5rem;
}

.g-7, .gx-7 {
  --bs-gutter-x: 3rem;
}

.g-7, .gy-7 {
  --bs-gutter-y: 3rem;
}

.g-8, .gx-8 {
  --bs-gutter-x: 3.5rem;
}

.g-8, .gy-8 {
  --bs-gutter-y: 3.5rem;
}

.g-9, .gx-9 {
  --bs-gutter-x: 4rem;
}

.g-9, .gy-9 {
  --bs-gutter-y: 4rem;
}

.g-10, .gx-10 {
  --bs-gutter-x: 5rem;
}

.g-10, .gy-10 {
  --bs-gutter-y: 5rem;
}

.g-11, .gx-11 {
  --bs-gutter-x: 6rem;
}

.g-11, .gy-11 {
  --bs-gutter-y: 6rem;
}

.g-12, .gx-12 {
  --bs-gutter-x: 9rem;
}

.g-12, .gy-12 {
  --bs-gutter-y: 9rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-6, .gx-sm-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-7, .gx-sm-7 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-7, .gy-sm-7 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-8, .gx-sm-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-8, .gy-sm-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-9, .gx-sm-9 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-9, .gy-sm-9 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-10, .gx-sm-10 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-10, .gy-sm-10 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-11, .gx-sm-11 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-11, .gy-sm-11 {
    --bs-gutter-y: 6rem;
  }

  .g-sm-12, .gx-sm-12 {
    --bs-gutter-x: 9rem;
  }

  .g-sm-12, .gy-sm-12 {
    --bs-gutter-y: 9rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 2rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 2rem;
  }

  .g-md-6, .gx-md-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-6, .gy-md-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-7, .gx-md-7 {
    --bs-gutter-x: 3rem;
  }

  .g-md-7, .gy-md-7 {
    --bs-gutter-y: 3rem;
  }

  .g-md-8, .gx-md-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-8, .gy-md-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-9, .gx-md-9 {
    --bs-gutter-x: 4rem;
  }

  .g-md-9, .gy-md-9 {
    --bs-gutter-y: 4rem;
  }

  .g-md-10, .gx-md-10 {
    --bs-gutter-x: 5rem;
  }

  .g-md-10, .gy-md-10 {
    --bs-gutter-y: 5rem;
  }

  .g-md-11, .gx-md-11 {
    --bs-gutter-x: 6rem;
  }

  .g-md-11, .gy-md-11 {
    --bs-gutter-y: 6rem;
  }

  .g-md-12, .gx-md-12 {
    --bs-gutter-x: 9rem;
  }

  .g-md-12, .gy-md-12 {
    --bs-gutter-y: 9rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-6, .gx-lg-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-7, .gx-lg-7 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-7, .gy-lg-7 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-8, .gx-lg-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-8, .gy-lg-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-9, .gx-lg-9 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-9, .gy-lg-9 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-10, .gx-lg-10 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-10, .gy-lg-10 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-11, .gx-lg-11 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-11, .gy-lg-11 {
    --bs-gutter-y: 6rem;
  }

  .g-lg-12, .gx-lg-12 {
    --bs-gutter-x: 9rem;
  }

  .g-lg-12, .gy-lg-12 {
    --bs-gutter-y: 9rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-6, .gx-xl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-7, .gx-xl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-7, .gy-xl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-8, .gx-xl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-8, .gy-xl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-9, .gx-xl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-9, .gy-xl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-10, .gx-xl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-10, .gy-xl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-11, .gx-xl-11 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-11, .gy-xl-11 {
    --bs-gutter-y: 6rem;
  }

  .g-xl-12, .gx-xl-12 {
    --bs-gutter-x: 9rem;
  }

  .g-xl-12, .gy-xl-12 {
    --bs-gutter-y: 9rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-8, .gx-xxl-8 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-8, .gy-xxl-8 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-9, .gx-xxl-9 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-9, .gy-xxl-9 {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-10, .gx-xxl-10 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-10, .gy-xxl-10 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-11, .gx-xxl-11 {
    --bs-gutter-x: 6rem;
  }

  .g-xxl-11, .gy-xxl-11 {
    --bs-gutter-y: 6rem;
  }

  .g-xxl-12, .gx-xxl-12 {
    --bs-gutter-x: 9rem;
  }

  .g-xxl-12, .gy-xxl-12 {
    --bs-gutter-y: 9rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #718096;
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: #718096;
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: #718096;
  --bs-table-hover-bg: #f1f6fd;
  width: 100%;
  color: #718096;
  vertical-align: top;
  border-color: #e2e8f0;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ccd6f5;
  --bs-table-striped-bg: #c2cbe9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c1dd;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #bdc6e3;
  --bs-table-hover-color: #fff;
  color: #000;
  border-color: #b8c1dd;
}

.table-secondary {
  --bs-table-bg: #d0f8ea;
  --bs-table-striped-bg: #c6ecde;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbdfd3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e5d8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bbdfd3;
}

.table-success {
  --bs-table-bg: #def4ed;
  --bs-table-striped-bg: #d3e8e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8dcd5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cde2db;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c8dcd5;
}

.table-info {
  --bs-table-bg: #dcf0ff;
  --bs-table-striped-bg: #d1e4f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c6d8e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ccdeec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c6d8e6;
}

.table-warning {
  --bs-table-bg: #fff2d8;
  --bs-table-striped-bg: #f2e6cd;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dac2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0c8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dac2;
}

.table-danger {
  --bs-table-bg: #fcdde1;
  --bs-table-striped-bg: #efd2d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c7cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9ccd0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3c7cb;
}

.table-light {
  --bs-table-bg: #f1f6fd;
  --bs-table-striped-bg: #e5eaf0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9dde4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfe4ea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d9dde4;
}

.table-dark {
  --bs-table-bg: #08052f;
  --bs-table-striped-bg: #141239;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #211e44;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #1b183f;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #211e44;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  color: #718096;
  margin-bottom: .5rem;
  font-size: .7875rem;
}

.col-form-label {
  font-size: inherit;
  color: #718096;
  margin-bottom: 0;
  padding-top: calc(.575rem + 1px);
  padding-bottom: calc(.575rem + 1px);
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(.825rem + 1px);
  padding-bottom: calc(.825rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.425rem + 1px);
  padding-bottom: calc(.425rem + 1px);
  font-size: .8125rem;
}

.form-text {
  color: #a0aec0;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #718096;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cbd5e0;
  border-radius: .25rem;
  padding: .575rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #718096;
  box-shadow: unset;
  background-color: #fff;
  border-color: #8099e6;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  height: 1.7em;
}

.form-control::placeholder {
  color: #718096;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #eaecf3;
}

.form-control::file-selector-button {
  margin: -.575rem -1.5rem;
  color: #718096;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #eaecf3;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: 1.5rem;
  padding: .575rem 1.5rem;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dee0e7;
}

.form-control::-webkit-file-upload-button {
  color: #718096;
  pointer-events: none;
  border-color: inherit;
  background-color: #eaecf3;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin: -.575rem -1.5rem;
  margin-inline-end: 1.5rem;
  padding: .575rem 1.5rem;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dee0e7;
}

.form-control-plaintext {
  width: 100%;
  color: #718096;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .575rem 0;
  line-height: 1.7;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.7em + .85rem + 2px);
  border-radius: .2rem;
  padding: .425rem 1rem;
  font-size: .8125rem;
}

.form-control-sm::-webkit-file-upload-button {
  margin: -.425rem -1rem;
  margin-inline-end: 1rem;
  padding: .425rem 1rem;
}

.form-control-sm::file-selector-button {
  margin: -.425rem -1rem;
  margin-inline-end: 1rem;
  padding: .425rem 1rem;
}

.form-control-lg {
  min-height: calc(1.7em + 1.65rem + 2px);
  border-radius: .75rem;
  padding: .825rem 1.75rem;
  font-size: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  margin: -.825rem -1.75rem;
  margin-inline-end: 1.75rem;
  padding: .825rem 1.75rem;
}

.form-control-lg::file-selector-button {
  margin: -.825rem -1.75rem;
  margin-inline-end: 1.75rem;
  padding: .825rem 1.75rem;
}

textarea.form-control {
  min-height: calc(1.7em + 1.15rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.7em + .85rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.7em + 1.65rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: .575rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.7em;
  border-radius: .25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.7em;
  border-radius: .25rem;
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(1.5rem - 3px);
  color: #718096;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 1.5rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #cbd5e0;
  border-radius: .25rem;
  padding: .575rem 4.5rem .575rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8099e6;
  outline: 0;
  box-shadow: 0 0 0 .125rem #0033cc40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1.5rem;
}

.form-select:disabled {
  background-color: #eaecf3;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #718096;
}

.form-select-sm {
  padding-top: .425rem;
  padding-bottom: .425rem;
  padding-left: 1rem;
  font-size: .8125rem;
}

.form-select-lg {
  padding-top: .825rem;
  padding-bottom: .825rem;
  padding-left: 1.75rem;
  font-size: 1rem;
}

.form-check {
  min-height: 1.7rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  appearance: none;
  color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  margin-top: .35em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  box-shadow: unset;
  border-color: #8099e6;
  outline: 0;
}

.form-check-input:checked {
  background-color: #03c;
  border-color: #03c;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #03c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #03c;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 3.5em;
}

.form-switch .form-check-input {
  width: 3em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 3em;
  margin-left: -3.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238099e6'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: 1.25rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #03c;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3c2f0;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #e2e8f0;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #03c;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3c2f0;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: #0000;
  cursor: pointer;
  background-color: #e2e8f0;
  border-color: #0000;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #a0aec0;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #a0aec0;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  height: 100%;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem 1.5rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 1.5rem;
}

.form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  color: #718096;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf3;
  border: 1px solid #cbd5e0;
  border-radius: .25rem;
  align-items: center;
  padding: .575rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .75rem;
  padding: .825rem 1.75rem;
  font-size: 1rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .2rem;
  padding: .425rem 1rem;
  font-size: .8125rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.valid-feedback {
  width: 100%;
  color: #5cc9a7;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #5cc9a7e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235cc9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.425em + .2875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.85em + .575rem) calc(.85em + .575rem);
  border-color: #5cc9a7;
  padding-right: calc(1.7em + 1.15rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #5cc9a7;
  box-shadow: 0 0 0 .125rem #5cc9a740;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.425em + .2875rem) top calc(.425em + .2875rem);
  padding-right: calc(1.7em + 1.15rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #5cc9a7;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235cc9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.5rem center, right 4.5rem center;
  background-size: 16px 12px, calc(.85em + .575rem) calc(.85em + .575rem);
  padding-right: 8.25rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #5cc9a7;
  box-shadow: 0 0 0 .125rem #5cc9a740;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #5cc9a7;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #5cc9a7;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .125rem #5cc9a740;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5cc9a7;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #f25767;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #f25767e6;
  border-radius: .25rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f25767'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f25767' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.425em + .2875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.85em + .575rem) calc(.85em + .575rem);
  border-color: #f25767;
  padding-right: calc(1.7em + 1.15rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f25767;
  box-shadow: 0 0 0 .125rem #f2576740;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.425em + .2875rem) top calc(.425em + .2875rem);
  padding-right: calc(1.7em + 1.15rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f25767;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f25767'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f25767' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.5rem center, right 4.5rem center;
  background-size: 16px 12px, calc(.85em + .575rem) calc(.85em + .575rem);
  padding-right: 8.25rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f25767;
  box-shadow: 0 0 0 .125rem #f2576740;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f25767;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f25767;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .125rem #f2576740;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f25767;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  color: #718096;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .575rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.7;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #718096;
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: unset;
  outline: 0;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65;
}

.btn-primary {
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.btn-primary:hover {
  color: #fff;
  background-color: #002bad;
  border-color: #0029a3;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #002bad;
  border-color: #0029a3;
  box-shadow: 0 0 #2652d480;
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0029a3;
  border-color: #002699;
}

.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 #2652d480;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.btn-secondary {
  color: #fff;
  background-color: #15db95;
  border-color: #15db95;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #12ba7f;
  border-color: #11af77;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #12ba7f;
  border-color: #11af77;
  box-shadow: 0 0 #38e0a580;
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #11af77;
  border-color: #10a470;
}

.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 #38e0a580;
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #15db95;
  border-color: #15db95;
}

.btn-success {
  color: #fff;
  background-color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-success:hover {
  color: #fff;
  background-color: #4eab8e;
  border-color: #4aa186;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #4eab8e;
  border-color: #4aa186;
  box-shadow: 0 0 #74d1b480;
}

.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4aa186;
  border-color: #45977d;
}

.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 #74d1b480;
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-info {
  color: #fff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}

.btn-info:hover {
  color: #fff;
  background-color: #449ad9;
  border-color: #4091cc;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #449ad9;
  border-color: #4091cc;
  box-shadow: 0 0 #6ac0ff80;
}

.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #4091cc;
  border-color: #3c88bf;
}

.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 #6ac0ff80;
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}

.btn-warning {
  color: #fff;
  background-color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #d9a234;
  border-color: #cc9831;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #d9a234;
  border-color: #cc9831;
  box-shadow: 0 0 #ffc85a80;
}

.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc9831;
  border-color: #bf8f2e;
}

.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 #ffc85a80;
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #fff;
  background-color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-danger {
  color: #fff;
  background-color: #f25767;
  border-color: #f25767;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ce4a58;
  border-color: #c24652;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #ce4a58;
  border-color: #c24652;
  box-shadow: 0 0 #f4707e80;
}

.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c24652;
  border-color: #b6414d;
}

.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 #f4707e80;
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #f25767;
  border-color: #f25767;
}

.btn-light {
  color: #000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-light:hover {
  color: #000;
  background-color: #f3f7fd;
  border-color: #f2f7fd;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f3f7fd;
  border-color: #f2f7fd;
  box-shadow: 0 0 #cdd1d780;
}

.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f4f8fd;
  border-color: #f2f7fd;
}

.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 #cdd1d780;
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-white, .btn-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 #d9d9d980;
}

.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 #d9d9d980;
}

.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-black, .btn-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:focus + .btn-black, .btn-black:focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 #26262680;
}

.btn-check:checked + .btn-black, .btn-check:active + .btn-black, .btn-black:active, .btn-black.active, .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:checked + .btn-black:focus, .btn-check:active + .btn-black:focus, .btn-black:active:focus, .btn-black.active:focus, .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 #26262680;
}

.btn-black:disabled, .btn-black.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-dark {
  color: #fff;
  background-color: #08052f;
  border-color: #08052f;
}

.btn-dark:hover {
  color: #fff;
  background-color: #070428;
  border-color: #060426;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #070428;
  border-color: #060426;
  box-shadow: 0 0 #2d2b4e80;
}

.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #060426;
  border-color: #060423;
}

.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 #2d2b4e80;
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #08052f;
  border-color: #08052f;
}

.btn-outline-primary {
  color: #03c;
  border-color: #03c;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 #0033cc80;
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 #0033cc80;
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #03c;
  background-color: #0000;
}

.btn-outline-secondary {
  color: #15db95;
  border-color: #15db95;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #15db95;
  border-color: #15db95;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 #15db9580;
}

.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #15db95;
  border-color: #15db95;
}

.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 #15db9580;
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #15db95;
  background-color: #0000;
}

.btn-outline-success {
  color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 #5cc9a780;
}

.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #5cc9a7;
  border-color: #5cc9a7;
}

.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 #5cc9a780;
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #5cc9a7;
  background-color: #0000;
}

.btn-outline-info {
  color: #50b5ff;
  border-color: #50b5ff;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 #50b5ff80;
}

.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #50b5ff;
  border-color: #50b5ff;
}

.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 #50b5ff80;
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #50b5ff;
  background-color: #0000;
}

.btn-outline-warning {
  color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 #ffbe3d80;
}

.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #ffbe3d;
  border-color: #ffbe3d;
}

.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 #ffbe3d80;
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffbe3d;
  background-color: #0000;
}

.btn-outline-danger {
  color: #f25767;
  border-color: #f25767;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #f25767;
  border-color: #f25767;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 #f2576780;
}

.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #f25767;
  border-color: #f25767;
}

.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 #f2576780;
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #f25767;
  background-color: #0000;
}

.btn-outline-light {
  color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 #f1f6fd80;
}

.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f1f6fd;
  border-color: #f1f6fd;
}

.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 #f1f6fd80;
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f1f6fd;
  background-color: #0000;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 #ffffff80;
}

.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 #ffffff80;
}

.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: #0000;
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:focus + .btn-outline-black, .btn-outline-black:focus {
  box-shadow: 0 0 #00000080;
}

.btn-check:checked + .btn-outline-black, .btn-check:active + .btn-outline-black, .btn-outline-black:active, .btn-outline-black.active, .btn-outline-black.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-check:checked + .btn-outline-black:focus, .btn-check:active + .btn-outline-black:focus, .btn-outline-black:active:focus, .btn-outline-black.active:focus, .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 #00000080;
}

.btn-outline-black:disabled, .btn-outline-black.disabled {
  color: #000;
  background-color: #0000;
}

.btn-outline-dark {
  color: #08052f;
  border-color: #08052f;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #08052f;
  border-color: #08052f;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 #08052f80;
}

.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #08052f;
  border-color: #08052f;
}

.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 #08052f80;
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #08052f;
  background-color: #0000;
}

.btn-link {
  color: #4a5568;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #03c;
}

.btn-link:disabled, .btn-link.disabled {
  color: #718096;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .25rem;
  padding: .825rem 1.75rem;
  font-size: 1rem;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .2rem;
  padding: .425rem 1rem;
  font-size: .8125rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  color: #718096;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #00000026;
  border-radius: .25rem;
  margin: 0;
  padding: .5rem 0;
  font-size: .95rem;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: .125rem;
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #00000026;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #273444;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .65rem 1rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #03c;
  background-color: #0000;
}

.dropdown-item.active, .dropdown-item:active {
  color: #03c;
  background-color: #0000;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #a0aec0;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #718096;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: .8125rem;
  display: block;
}

.dropdown-item-text {
  color: #273444;
  padding: .65rem 1rem;
  display: block;
}

.dropdown-menu-dark {
  color: #e2e8f0;
  background-color: #2d3748;
  border-color: #00000026;
}

.dropdown-menu-dark .dropdown-item {
  color: #e2e8f0;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: #ffffff26;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #03c;
  background-color: #0000;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #a0aec0;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #00000026;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #e2e8f0;
}

.dropdown-menu-dark .dropdown-header {
  color: #a0aec0;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.3125rem;
  padding-right: 1.3125rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  color: #03c;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #0029a3;
}

.nav-link.disabled {
  color: #718096;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e2e8f0;
}

.nav-tabs .nav-link {
  background: none;
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #eaecf3 #eaecf3 #e2e8f0;
}

.nav-tabs .nav-link.disabled {
  color: #718096;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #4a5568;
  background-color: #fff;
  border-color: #e2e8f0 #e2e8f0 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #03c;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .2875rem;
  padding-bottom: .2875rem;
  font-size: 1.25rem;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-sm .offcanvas-top, .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-md .offcanvas-top, .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-lg .offcanvas-top, .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xl .offcanvas-top, .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xxl .offcanvas-top, .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler, .navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  z-index: 1000;
  background-color: #0000;
  border-left: 0;
  border-right: 0;
  flex-grow: 1;
  transition: none;
  bottom: 0;
  transform: none;
  visibility: visible !important;
}

.navbar-expand .offcanvas-top, .navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #03c;
}

.navbar-light .navbar-nav .nav-link {
  color: #08052f;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #03c;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
  color: #03c;
}

.navbar-light .navbar-toggler {
  color: #08052f;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%2308052f' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #08052f;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #03c;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffffd9 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffd9 !important;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #ffffffd9 !important;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffffd9 !important;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e2e8f0;
  border-radius: .75rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.75rem - 1px);
  border-bottom-left-radius: calc(.75rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: auto;
  padding: 1.75rem;
}

.card-title {
  margin-bottom: .5rem;
}

.card-subtitle {
  margin-top: -.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.75rem;
}

.card-header {
  background-color: #0000;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 0;
  padding: .875rem 1.75rem;
}

.card-header:first-child {
  border-radius: calc(.75rem - 1px) calc(.75rem - 1px) 0 0;
}

.card-footer {
  background-color: #0000;
  border-top: 1px solid #e2e8f0;
  padding: .875rem 1.75rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.75rem - 1px) calc(.75rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.875rem;
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.card-header-pills {
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.card-img-overlay {
  border-radius: calc(.75rem - 1px);
  padding: 1rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.75rem - 1px);
  border-bottom-left-radius: calc(.75rem - 1px);
}

.card-group > .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  width: 100%;
  color: #2d3748;
  text-align: left;
  overflow-anchor: none;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out, border-radius .15s;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #03c;
  background-color: #0000;
  box-shadow: inset 0 -1px #e2e8f0;
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2303c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232D3748'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  flex-shrink: 0;
  margin-left: auto;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  box-shadow: unset;
  border-color: #e2e8f0;
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #e2e8f0;
}

.accordion-item:first-of-type {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(.75rem - 1px);
  border-bottom-left-radius: calc(.75rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.accordion-body {
  padding: 1rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  background-color: #0000;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #718096;
  content: var(--bs-breadcrumb-divider, "/");
  padding-right: .5rem;
}

.breadcrumb-item.active {
  color: #718096;
}

.pagination {
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #03c;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0029a3;
  background-color: #eaecf3;
  border-color: #e2e8f0;
}

.page-link:focus {
  z-index: 3;
  color: #0029a3;
  box-shadow: unset;
  background-color: #eaecf3;
  outline: 0;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.page-item.disabled .page-link {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
  border-color: #e2e8f0;
}

.page-link {
  padding: .375rem .75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .8125rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #001f7a;
  background-color: #ccd6f5;
  border-color: #b3c2f0;
}

.alert-primary .alert-link {
  color: #001962;
}

.alert-secondary {
  color: #0d8359;
  background-color: #d0f8ea;
  border-color: #b9f4df;
}

.alert-secondary .alert-link {
  color: #0a6947;
}

.alert-success {
  color: #377964;
  background-color: #def4ed;
  border-color: #ceefe5;
}

.alert-success .alert-link {
  color: #2c6150;
}

.alert-info {
  color: #306d99;
  background-color: #dcf0ff;
  border-color: #cbe9ff;
}

.alert-info .alert-link {
  color: #26577a;
}

.alert-warning {
  color: #997225;
  background-color: #fff2d8;
  border-color: #ffecc5;
}

.alert-warning .alert-link {
  color: #7a5b1e;
}

.alert-danger {
  color: #91343e;
  background-color: #fcdde1;
  border-color: #fbcdd1;
}

.alert-danger .alert-link {
  color: #742a32;
}

.alert-light {
  color: #919498;
  background-color: #fcfdff;
  border-color: #fbfcfe;
}

.alert-light .alert-link {
  color: #74767a;
}

.alert-white {
  color: #999;
  background-color: #fff;
  border-color: #fff;
}

.alert-white .alert-link {
  color: #7a7a7a;
}

.alert-black {
  color: #000;
  background-color: #ccc;
  border-color: #b3b3b3;
}

.alert-black .alert-link {
  color: #000;
}

.alert-dark {
  color: #05031c;
  background-color: #cecdd5;
  border-color: #b5b4c1;
}

.alert-dark .alert-link {
  color: #040216;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  height: 1rem;
  background-color: #eaecf3;
  border-radius: .25rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #03c;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  border-radius: .25rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > li:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #4a5568;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #4a5568;
  background-color: #f1f6fd;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #718096;
  background-color: #eaecf3;
}

.list-group-item {
  color: #273444;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  padding: 1rem 1.5rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #718096;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #03c;
  border-color: #03c;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .25rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .25rem;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #001f7a;
  background-color: #ccd6f5;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #001f7a;
  background-color: #b8c1dd;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001f7a;
  border-color: #001f7a;
}

.list-group-item-secondary {
  color: #0d8359;
  background-color: #d0f8ea;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #0d8359;
  background-color: #bbdfd3;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #0d8359;
  border-color: #0d8359;
}

.list-group-item-success {
  color: #377964;
  background-color: #def4ed;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #377964;
  background-color: #c8dcd5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #377964;
  border-color: #377964;
}

.list-group-item-info {
  color: #306d99;
  background-color: #dcf0ff;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #306d99;
  background-color: #c6d8e6;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #306d99;
  border-color: #306d99;
}

.list-group-item-warning {
  color: #997225;
  background-color: #fff2d8;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997225;
  background-color: #e6dac2;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997225;
  border-color: #997225;
}

.list-group-item-danger {
  color: #91343e;
  background-color: #fcdde1;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #91343e;
  background-color: #e3c7cb;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #91343e;
  border-color: #91343e;
}

.list-group-item-light {
  color: #919498;
  background-color: #fcfdff;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #919498;
  background-color: #e3e4e6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #919498;
  border-color: #919498;
}

.list-group-item-white {
  color: #999;
  background-color: #fff;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999;
  background-color: #e6e6e6;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.list-group-item-black {
  color: #000;
  background-color: #ccc;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: #000;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.list-group-item-dark {
  color: #05031c;
  background-color: #cecdd5;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #05031c;
  background-color: #b9b9c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #05031c;
  border-color: #05031c;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .25rem;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: unset;
  opacity: 1;
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  pointer-events: auto;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  font-size: .875rem;
  box-shadow: 0 .375rem 1.5rem #8c98a420;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: .75rem;
}

.toast-header {
  color: #718096;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  align-items: center;
  padding: .5rem .75rem;
  display: flex;
}

.toast-header .btn-close {
  margin-left: .75rem;
  margin-right: -.375rem;
}

.toast-body {
  word-wrap: break-word;
  padding: .75rem;
}

.modal {
  z-index: 1055;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #0003;
  border-radius: .75rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e2e8f0;
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-header .btn-close {
  margin: -.5rem -.5rem -.5rem auto;
  padding: .5rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e2e8f0;
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  z-index: 1080;
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-size: .8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .tooltip-arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: -1px;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: -1px;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: -1px;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: -1px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1070;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .75rem;
  font-size: .8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .popover-arrow {
  width: 1rem;
  height: .5rem;
  display: block;
  position: absolute;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  left: calc(-.5rem - 1px);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  right: calc(-.5rem - 1px);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  color: #08052f;
  background-color: #f0f0f0;
  border-bottom: 1px solid #0003;
  border-top-left-radius: calc(.75rem - 1px);
  border-top-right-radius: calc(.75rem - 1px);
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #718096;
  padding: 1rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: .75s linear infinite spinner-grow;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  z-index: 1045;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.offcanvas-header .btn-close {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
  margin-right: -.5rem;
  padding: .5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  width: 400px;
  border-right: 0 solid #0003;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas-end {
  width: 400px;
  border-left: 0 solid #0003;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas-top {
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid #0003;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas-bottom {
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid #0003;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.link-primary {
  color: #03c;
}

.link-primary:hover, .link-primary:focus {
  color: #0029a3;
}

.link-secondary {
  color: #15db95;
}

.link-secondary:hover, .link-secondary:focus {
  color: #11af77;
}

.link-success {
  color: #5cc9a7;
}

.link-success:hover, .link-success:focus {
  color: #4aa186;
}

.link-info {
  color: #50b5ff;
}

.link-info:hover, .link-info:focus {
  color: #4091cc;
}

.link-warning {
  color: #ffbe3d;
}

.link-warning:hover, .link-warning:focus {
  color: #cc9831;
}

.link-danger {
  color: #f25767;
}

.link-danger:hover, .link-danger:focus {
  color: #c24652;
}

.link-light {
  color: #f1f6fd;
}

.link-light:hover, .link-light:focus {
  color: #f4f8fd;
}

.link-white, .link-white:hover, .link-white:focus {
  color: #fff;
}

.link-black, .link-black:hover, .link-black:focus {
  color: #000;
}

.link-dark {
  color: #08052f;
}

.link-dark:hover, .link-dark:focus {
  color: #060426;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: .1 !important;
}

.opacity-2 {
  opacity: .2 !important;
}

.opacity-3 {
  opacity: .3 !important;
}

.opacity-4 {
  opacity: .4 !important;
}

.opacity-5 {
  opacity: .5 !important;
}

.opacity-6 {
  opacity: .6 !important;
}

.opacity-7 {
  opacity: .7 !important;
}

.opacity-8 {
  opacity: .8 !important;
}

.opacity-9 {
  opacity: .9 !important;
}

.opacity-10 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .375rem 1.5rem #8c98a420 !important;
}

.shadow-sm {
  box-shadow: 0 12px 15px #8c98a41a !important;
}

.shadow-lg {
  box-shadow: 0 10px 40px 10px #8c98a42d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e2e8f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e2e8f0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #e2e8f0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e2e8f0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #e2e8f0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #03c !important;
}

.border-secondary {
  border-color: #15db95 !important;
}

.border-success {
  border-color: #5cc9a7 !important;
}

.border-info {
  border-color: #50b5ff !important;
}

.border-warning {
  border-color: #ffbe3d !important;
}

.border-danger {
  border-color: #f25767 !important;
}

.border-light {
  border-color: #f1f6fd !important;
}

.border-white {
  border-color: #fff !important;
}

.border-black {
  border-color: #000 !important;
}

.border-dark {
  border-color: #08052f !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-65 {
  min-height: 65vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-85 {
  min-height: 85vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 5rem !important;
}

.gap-11 {
  gap: 6rem !important;
}

.gap-12 {
  gap: 9rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 5rem !important;
}

.m-11 {
  margin: 6rem !important;
}

.m-12 {
  margin: 9rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-7 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-8 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-9 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-11 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-12 {
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-12 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.mt-11 {
  margin-top: 6rem !important;
}

.mt-12 {
  margin-top: 9rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 5rem !important;
}

.me-11 {
  margin-right: 6rem !important;
}

.me-12 {
  margin-right: 9rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 5rem !important;
}

.mb-11 {
  margin-bottom: 6rem !important;
}

.mb-12 {
  margin-bottom: 9rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 5rem !important;
}

.ms-11 {
  margin-left: 6rem !important;
}

.ms-12 {
  margin-left: 9rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -5rem !important;
}

.m-n11 {
  margin: -6rem !important;
}

.m-n12 {
  margin: -9rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.mx-n6 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.mx-n7 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.mx-n8 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.mx-n9 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.mx-n10 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-n11 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.mx-n12 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n12 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -5rem !important;
}

.mt-n11 {
  margin-top: -6rem !important;
}

.mt-n12 {
  margin-top: -9rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -5rem !important;
}

.me-n11 {
  margin-right: -6rem !important;
}

.me-n12 {
  margin-right: -9rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -5rem !important;
}

.mb-n11 {
  margin-bottom: -6rem !important;
}

.mb-n12 {
  margin-bottom: -9rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -5rem !important;
}

.ms-n11 {
  margin-left: -6rem !important;
}

.ms-n12 {
  margin-left: -9rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 5rem !important;
}

.p-11 {
  padding: 6rem !important;
}

.p-12 {
  padding: 9rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-7 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-8 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-9 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-11 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.px-12 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-12 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 5rem !important;
}

.pt-11 {
  padding-top: 6rem !important;
}

.pt-12 {
  padding-top: 9rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 5rem !important;
}

.pe-11 {
  padding-right: 6rem !important;
}

.pe-12 {
  padding-right: 9rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 5rem !important;
}

.pb-11 {
  padding-bottom: 6rem !important;
}

.pb-12 {
  padding-bottom: 9rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 5rem !important;
}

.ps-11 {
  padding-left: 6rem !important;
}

.ps-12 {
  padding-left: 9rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.7 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline, [aria-current="page"] {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #a0aec0 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .2rem !important;
}

.rounded-2 {
  border-radius: .25rem !important;
}

.rounded-3 {
  border-radius: .75rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-end {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-start {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.w-px-50 {
  width: 50px !important;
}

.w-px-100 {
  width: 100px !important;
}

.w-px-150 {
  width: 150px !important;
}

.w-px-200 {
  width: 200px !important;
}

.w-px-250 {
  width: 250px !important;
}

.w-px-300 {
  width: 300px !important;
}

.w-px-350 {
  width: 350px !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .w-sm-5 {
    width: 5% !important;
  }

  .w-sm-10 {
    width: 10% !important;
  }

  .w-sm-15 {
    width: 15% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-30 {
    width: 30% !important;
  }

  .w-sm-35 {
    width: 35% !important;
  }

  .w-sm-40 {
    width: 40% !important;
  }

  .w-sm-45 {
    width: 45% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-55 {
    width: 55% !important;
  }

  .w-sm-60 {
    width: 60% !important;
  }

  .w-sm-65 {
    width: 65% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-80 {
    width: 80% !important;
  }

  .w-sm-85 {
    width: 85% !important;
  }

  .w-sm-90 {
    width: 90% !important;
  }

  .w-sm-95 {
    width: 95% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .min-vh-sm-65 {
    min-height: 65vh !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .min-vh-sm-85 {
    min-height: 85vh !important;
  }

  .min-vh-sm-100 {
    min-height: 100vh !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 2rem !important;
  }

  .gap-sm-6 {
    gap: 2.5rem !important;
  }

  .gap-sm-7 {
    gap: 3rem !important;
  }

  .gap-sm-8 {
    gap: 3.5rem !important;
  }

  .gap-sm-9 {
    gap: 4rem !important;
  }

  .gap-sm-10 {
    gap: 5rem !important;
  }

  .gap-sm-11 {
    gap: 6rem !important;
  }

  .gap-sm-12 {
    gap: 9rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 2rem !important;
  }

  .m-sm-6 {
    margin: 2.5rem !important;
  }

  .m-sm-7 {
    margin: 3rem !important;
  }

  .m-sm-8 {
    margin: 3.5rem !important;
  }

  .m-sm-9 {
    margin: 4rem !important;
  }

  .m-sm-10 {
    margin: 5rem !important;
  }

  .m-sm-11 {
    margin: 6rem !important;
  }

  .m-sm-12 {
    margin: 9rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-8 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-12 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 2rem !important;
  }

  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 3rem !important;
  }

  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-9 {
    margin-top: 4rem !important;
  }

  .mt-sm-10 {
    margin-top: 5rem !important;
  }

  .mt-sm-11 {
    margin-top: 6rem !important;
  }

  .mt-sm-12 {
    margin-top: 9rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 2rem !important;
  }

  .me-sm-6 {
    margin-right: 2.5rem !important;
  }

  .me-sm-7 {
    margin-right: 3rem !important;
  }

  .me-sm-8 {
    margin-right: 3.5rem !important;
  }

  .me-sm-9 {
    margin-right: 4rem !important;
  }

  .me-sm-10 {
    margin-right: 5rem !important;
  }

  .me-sm-11 {
    margin-right: 6rem !important;
  }

  .me-sm-12 {
    margin-right: 9rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 9rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 2rem !important;
  }

  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 3rem !important;
  }

  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-9 {
    margin-left: 4rem !important;
  }

  .ms-sm-10 {
    margin-left: 5rem !important;
  }

  .ms-sm-11 {
    margin-left: 6rem !important;
  }

  .ms-sm-12 {
    margin-left: 9rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -2rem !important;
  }

  .m-sm-n6 {
    margin: -2.5rem !important;
  }

  .m-sm-n7 {
    margin: -3rem !important;
  }

  .m-sm-n8 {
    margin: -3.5rem !important;
  }

  .m-sm-n9 {
    margin: -4rem !important;
  }

  .m-sm-n10 {
    margin: -5rem !important;
  }

  .m-sm-n11 {
    margin: -6rem !important;
  }

  .m-sm-n12 {
    margin: -9rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-sm-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-sm-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-sm-n8 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-sm-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-sm-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-sm-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-sm-n12 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-sm-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -2rem !important;
  }

  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -3rem !important;
  }

  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n9 {
    margin-top: -4rem !important;
  }

  .mt-sm-n10 {
    margin-top: -5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -6rem !important;
  }

  .mt-sm-n12 {
    margin-top: -9rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -2rem !important;
  }

  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -3rem !important;
  }

  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n9 {
    margin-right: -4rem !important;
  }

  .me-sm-n10 {
    margin-right: -5rem !important;
  }

  .me-sm-n11 {
    margin-right: -6rem !important;
  }

  .me-sm-n12 {
    margin-right: -9rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -9rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -2rem !important;
  }

  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -3rem !important;
  }

  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n9 {
    margin-left: -4rem !important;
  }

  .ms-sm-n10 {
    margin-left: -5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -6rem !important;
  }

  .ms-sm-n12 {
    margin-left: -9rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 2rem !important;
  }

  .p-sm-6 {
    padding: 2.5rem !important;
  }

  .p-sm-7 {
    padding: 3rem !important;
  }

  .p-sm-8 {
    padding: 3.5rem !important;
  }

  .p-sm-9 {
    padding: 4rem !important;
  }

  .p-sm-10 {
    padding: 5rem !important;
  }

  .p-sm-11 {
    padding: 6rem !important;
  }

  .p-sm-12 {
    padding: 9rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-sm-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-sm-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-sm-12 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-sm-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 2rem !important;
  }

  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 3rem !important;
  }

  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-9 {
    padding-top: 4rem !important;
  }

  .pt-sm-10 {
    padding-top: 5rem !important;
  }

  .pt-sm-11 {
    padding-top: 6rem !important;
  }

  .pt-sm-12 {
    padding-top: 9rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 2rem !important;
  }

  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 3rem !important;
  }

  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-9 {
    padding-right: 4rem !important;
  }

  .pe-sm-10 {
    padding-right: 5rem !important;
  }

  .pe-sm-11 {
    padding-right: 6rem !important;
  }

  .pe-sm-12 {
    padding-right: 9rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 6rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 9rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 2rem !important;
  }

  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 3rem !important;
  }

  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-9 {
    padding-left: 4rem !important;
  }

  .ps-sm-10 {
    padding-left: 5rem !important;
  }

  .ps-sm-11 {
    padding-left: 6rem !important;
  }

  .ps-sm-12 {
    padding-left: 9rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .w-px-sm-50 {
    width: 50px !important;
  }

  .w-px-sm-100 {
    width: 100px !important;
  }

  .w-px-sm-150 {
    width: 150px !important;
  }

  .w-px-sm-200 {
    width: 200px !important;
  }

  .w-px-sm-250 {
    width: 250px !important;
  }

  .w-px-sm-300 {
    width: 300px !important;
  }

  .w-px-sm-350 {
    width: 350px !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .w-md-5 {
    width: 5% !important;
  }

  .w-md-10 {
    width: 10% !important;
  }

  .w-md-15 {
    width: 15% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-45 {
    width: 45% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-55 {
    width: 55% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-80 {
    width: 80% !important;
  }

  .w-md-85 {
    width: 85% !important;
  }

  .w-md-90 {
    width: 90% !important;
  }

  .w-md-95 {
    width: 95% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .min-vh-md-65 {
    min-height: 65vh !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .min-vh-md-85 {
    min-height: 85vh !important;
  }

  .min-vh-md-100 {
    min-height: 100vh !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 2rem !important;
  }

  .gap-md-6 {
    gap: 2.5rem !important;
  }

  .gap-md-7 {
    gap: 3rem !important;
  }

  .gap-md-8 {
    gap: 3.5rem !important;
  }

  .gap-md-9 {
    gap: 4rem !important;
  }

  .gap-md-10 {
    gap: 5rem !important;
  }

  .gap-md-11 {
    gap: 6rem !important;
  }

  .gap-md-12 {
    gap: 9rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 2rem !important;
  }

  .m-md-6 {
    margin: 2.5rem !important;
  }

  .m-md-7 {
    margin: 3rem !important;
  }

  .m-md-8 {
    margin: 3.5rem !important;
  }

  .m-md-9 {
    margin: 4rem !important;
  }

  .m-md-10 {
    margin: 5rem !important;
  }

  .m-md-11 {
    margin: 6rem !important;
  }

  .m-md-12 {
    margin: 9rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-8 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-12 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 2rem !important;
  }

  .mt-md-6 {
    margin-top: 2.5rem !important;
  }

  .mt-md-7 {
    margin-top: 3rem !important;
  }

  .mt-md-8 {
    margin-top: 3.5rem !important;
  }

  .mt-md-9 {
    margin-top: 4rem !important;
  }

  .mt-md-10 {
    margin-top: 5rem !important;
  }

  .mt-md-11 {
    margin-top: 6rem !important;
  }

  .mt-md-12 {
    margin-top: 9rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 2rem !important;
  }

  .me-md-6 {
    margin-right: 2.5rem !important;
  }

  .me-md-7 {
    margin-right: 3rem !important;
  }

  .me-md-8 {
    margin-right: 3.5rem !important;
  }

  .me-md-9 {
    margin-right: 4rem !important;
  }

  .me-md-10 {
    margin-right: 5rem !important;
  }

  .me-md-11 {
    margin-right: 6rem !important;
  }

  .me-md-12 {
    margin-right: 9rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 3rem !important;
  }

  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-9 {
    margin-bottom: 4rem !important;
  }

  .mb-md-10 {
    margin-bottom: 5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 6rem !important;
  }

  .mb-md-12 {
    margin-bottom: 9rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 2rem !important;
  }

  .ms-md-6 {
    margin-left: 2.5rem !important;
  }

  .ms-md-7 {
    margin-left: 3rem !important;
  }

  .ms-md-8 {
    margin-left: 3.5rem !important;
  }

  .ms-md-9 {
    margin-left: 4rem !important;
  }

  .ms-md-10 {
    margin-left: 5rem !important;
  }

  .ms-md-11 {
    margin-left: 6rem !important;
  }

  .ms-md-12 {
    margin-left: 9rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -2rem !important;
  }

  .m-md-n6 {
    margin: -2.5rem !important;
  }

  .m-md-n7 {
    margin: -3rem !important;
  }

  .m-md-n8 {
    margin: -3.5rem !important;
  }

  .m-md-n9 {
    margin: -4rem !important;
  }

  .m-md-n10 {
    margin: -5rem !important;
  }

  .m-md-n11 {
    margin: -6rem !important;
  }

  .m-md-n12 {
    margin: -9rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-md-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-md-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-md-n8 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-md-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-md-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-md-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-md-n12 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-md-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -2rem !important;
  }

  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -3rem !important;
  }

  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n9 {
    margin-top: -4rem !important;
  }

  .mt-md-n10 {
    margin-top: -5rem !important;
  }

  .mt-md-n11 {
    margin-top: -6rem !important;
  }

  .mt-md-n12 {
    margin-top: -9rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -2rem !important;
  }

  .me-md-n6 {
    margin-right: -2.5rem !important;
  }

  .me-md-n7 {
    margin-right: -3rem !important;
  }

  .me-md-n8 {
    margin-right: -3.5rem !important;
  }

  .me-md-n9 {
    margin-right: -4rem !important;
  }

  .me-md-n10 {
    margin-right: -5rem !important;
  }

  .me-md-n11 {
    margin-right: -6rem !important;
  }

  .me-md-n12 {
    margin-right: -9rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -9rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -2rem !important;
  }

  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -3rem !important;
  }

  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n9 {
    margin-left: -4rem !important;
  }

  .ms-md-n10 {
    margin-left: -5rem !important;
  }

  .ms-md-n11 {
    margin-left: -6rem !important;
  }

  .ms-md-n12 {
    margin-left: -9rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 2rem !important;
  }

  .p-md-6 {
    padding: 2.5rem !important;
  }

  .p-md-7 {
    padding: 3rem !important;
  }

  .p-md-8 {
    padding: 3.5rem !important;
  }

  .p-md-9 {
    padding: 4rem !important;
  }

  .p-md-10 {
    padding: 5rem !important;
  }

  .p-md-11 {
    padding: 6rem !important;
  }

  .p-md-12 {
    padding: 9rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-md-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-md-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-md-12 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-md-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 2rem !important;
  }

  .pt-md-6 {
    padding-top: 2.5rem !important;
  }

  .pt-md-7 {
    padding-top: 3rem !important;
  }

  .pt-md-8 {
    padding-top: 3.5rem !important;
  }

  .pt-md-9 {
    padding-top: 4rem !important;
  }

  .pt-md-10 {
    padding-top: 5rem !important;
  }

  .pt-md-11 {
    padding-top: 6rem !important;
  }

  .pt-md-12 {
    padding-top: 9rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 2rem !important;
  }

  .pe-md-6 {
    padding-right: 2.5rem !important;
  }

  .pe-md-7 {
    padding-right: 3rem !important;
  }

  .pe-md-8 {
    padding-right: 3.5rem !important;
  }

  .pe-md-9 {
    padding-right: 4rem !important;
  }

  .pe-md-10 {
    padding-right: 5rem !important;
  }

  .pe-md-11 {
    padding-right: 6rem !important;
  }

  .pe-md-12 {
    padding-right: 9rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 2rem !important;
  }

  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 3rem !important;
  }

  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-9 {
    padding-bottom: 4rem !important;
  }

  .pb-md-10 {
    padding-bottom: 5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 6rem !important;
  }

  .pb-md-12 {
    padding-bottom: 9rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 2rem !important;
  }

  .ps-md-6 {
    padding-left: 2.5rem !important;
  }

  .ps-md-7 {
    padding-left: 3rem !important;
  }

  .ps-md-8 {
    padding-left: 3.5rem !important;
  }

  .ps-md-9 {
    padding-left: 4rem !important;
  }

  .ps-md-10 {
    padding-left: 5rem !important;
  }

  .ps-md-11 {
    padding-left: 6rem !important;
  }

  .ps-md-12 {
    padding-left: 9rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .w-px-md-50 {
    width: 50px !important;
  }

  .w-px-md-100 {
    width: 100px !important;
  }

  .w-px-md-150 {
    width: 150px !important;
  }

  .w-px-md-200 {
    width: 200px !important;
  }

  .w-px-md-250 {
    width: 250px !important;
  }

  .w-px-md-300 {
    width: 300px !important;
  }

  .w-px-md-350 {
    width: 350px !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .w-lg-5 {
    width: 5% !important;
  }

  .w-lg-10 {
    width: 10% !important;
  }

  .w-lg-15 {
    width: 15% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-35 {
    width: 35% !important;
  }

  .w-lg-40 {
    width: 40% !important;
  }

  .w-lg-45 {
    width: 45% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-55 {
    width: 55% !important;
  }

  .w-lg-60 {
    width: 60% !important;
  }

  .w-lg-65 {
    width: 65% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-80 {
    width: 80% !important;
  }

  .w-lg-85 {
    width: 85% !important;
  }

  .w-lg-90 {
    width: 90% !important;
  }

  .w-lg-95 {
    width: 95% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .min-vh-lg-65 {
    min-height: 65vh !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .min-vh-lg-85 {
    min-height: 85vh !important;
  }

  .min-vh-lg-100 {
    min-height: 100vh !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 2rem !important;
  }

  .gap-lg-6 {
    gap: 2.5rem !important;
  }

  .gap-lg-7 {
    gap: 3rem !important;
  }

  .gap-lg-8 {
    gap: 3.5rem !important;
  }

  .gap-lg-9 {
    gap: 4rem !important;
  }

  .gap-lg-10 {
    gap: 5rem !important;
  }

  .gap-lg-11 {
    gap: 6rem !important;
  }

  .gap-lg-12 {
    gap: 9rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 2rem !important;
  }

  .m-lg-6 {
    margin: 2.5rem !important;
  }

  .m-lg-7 {
    margin: 3rem !important;
  }

  .m-lg-8 {
    margin: 3.5rem !important;
  }

  .m-lg-9 {
    margin: 4rem !important;
  }

  .m-lg-10 {
    margin: 5rem !important;
  }

  .m-lg-11 {
    margin: 6rem !important;
  }

  .m-lg-12 {
    margin: 9rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-8 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-12 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 2rem !important;
  }

  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 3rem !important;
  }

  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-9 {
    margin-top: 4rem !important;
  }

  .mt-lg-10 {
    margin-top: 5rem !important;
  }

  .mt-lg-11 {
    margin-top: 6rem !important;
  }

  .mt-lg-12 {
    margin-top: 9rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 2rem !important;
  }

  .me-lg-6 {
    margin-right: 2.5rem !important;
  }

  .me-lg-7 {
    margin-right: 3rem !important;
  }

  .me-lg-8 {
    margin-right: 3.5rem !important;
  }

  .me-lg-9 {
    margin-right: 4rem !important;
  }

  .me-lg-10 {
    margin-right: 5rem !important;
  }

  .me-lg-11 {
    margin-right: 6rem !important;
  }

  .me-lg-12 {
    margin-right: 9rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 9rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 2rem !important;
  }

  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 3rem !important;
  }

  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-9 {
    margin-left: 4rem !important;
  }

  .ms-lg-10 {
    margin-left: 5rem !important;
  }

  .ms-lg-11 {
    margin-left: 6rem !important;
  }

  .ms-lg-12 {
    margin-left: 9rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -2rem !important;
  }

  .m-lg-n6 {
    margin: -2.5rem !important;
  }

  .m-lg-n7 {
    margin: -3rem !important;
  }

  .m-lg-n8 {
    margin: -3.5rem !important;
  }

  .m-lg-n9 {
    margin: -4rem !important;
  }

  .m-lg-n10 {
    margin: -5rem !important;
  }

  .m-lg-n11 {
    margin: -6rem !important;
  }

  .m-lg-n12 {
    margin: -9rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-lg-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-lg-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-lg-n8 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-lg-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-lg-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-lg-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-lg-n12 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-lg-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -2rem !important;
  }

  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -3rem !important;
  }

  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n9 {
    margin-top: -4rem !important;
  }

  .mt-lg-n10 {
    margin-top: -5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -6rem !important;
  }

  .mt-lg-n12 {
    margin-top: -9rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -2rem !important;
  }

  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -3rem !important;
  }

  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n9 {
    margin-right: -4rem !important;
  }

  .me-lg-n10 {
    margin-right: -5rem !important;
  }

  .me-lg-n11 {
    margin-right: -6rem !important;
  }

  .me-lg-n12 {
    margin-right: -9rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -9rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -2rem !important;
  }

  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -3rem !important;
  }

  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n9 {
    margin-left: -4rem !important;
  }

  .ms-lg-n10 {
    margin-left: -5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -6rem !important;
  }

  .ms-lg-n12 {
    margin-left: -9rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 2rem !important;
  }

  .p-lg-6 {
    padding: 2.5rem !important;
  }

  .p-lg-7 {
    padding: 3rem !important;
  }

  .p-lg-8 {
    padding: 3.5rem !important;
  }

  .p-lg-9 {
    padding: 4rem !important;
  }

  .p-lg-10 {
    padding: 5rem !important;
  }

  .p-lg-11 {
    padding: 6rem !important;
  }

  .p-lg-12 {
    padding: 9rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-lg-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-lg-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-lg-12 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-lg-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 2rem !important;
  }

  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 3rem !important;
  }

  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-9 {
    padding-top: 4rem !important;
  }

  .pt-lg-10 {
    padding-top: 5rem !important;
  }

  .pt-lg-11 {
    padding-top: 6rem !important;
  }

  .pt-lg-12 {
    padding-top: 9rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 2rem !important;
  }

  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 3rem !important;
  }

  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-9 {
    padding-right: 4rem !important;
  }

  .pe-lg-10 {
    padding-right: 5rem !important;
  }

  .pe-lg-11 {
    padding-right: 6rem !important;
  }

  .pe-lg-12 {
    padding-right: 9rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 6rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 9rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 2rem !important;
  }

  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 3rem !important;
  }

  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-9 {
    padding-left: 4rem !important;
  }

  .ps-lg-10 {
    padding-left: 5rem !important;
  }

  .ps-lg-11 {
    padding-left: 6rem !important;
  }

  .ps-lg-12 {
    padding-left: 9rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .w-px-lg-50 {
    width: 50px !important;
  }

  .w-px-lg-100 {
    width: 100px !important;
  }

  .w-px-lg-150 {
    width: 150px !important;
  }

  .w-px-lg-200 {
    width: 200px !important;
  }

  .w-px-lg-250 {
    width: 250px !important;
  }

  .w-px-lg-300 {
    width: 300px !important;
  }

  .w-px-lg-350 {
    width: 350px !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .w-xl-5 {
    width: 5% !important;
  }

  .w-xl-10 {
    width: 10% !important;
  }

  .w-xl-15 {
    width: 15% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-35 {
    width: 35% !important;
  }

  .w-xl-40 {
    width: 40% !important;
  }

  .w-xl-45 {
    width: 45% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-55 {
    width: 55% !important;
  }

  .w-xl-60 {
    width: 60% !important;
  }

  .w-xl-65 {
    width: 65% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-80 {
    width: 80% !important;
  }

  .w-xl-85 {
    width: 85% !important;
  }

  .w-xl-90 {
    width: 90% !important;
  }

  .w-xl-95 {
    width: 95% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .min-vh-xl-65 {
    min-height: 65vh !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .min-vh-xl-85 {
    min-height: 85vh !important;
  }

  .min-vh-xl-100 {
    min-height: 100vh !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 2rem !important;
  }

  .gap-xl-6 {
    gap: 2.5rem !important;
  }

  .gap-xl-7 {
    gap: 3rem !important;
  }

  .gap-xl-8 {
    gap: 3.5rem !important;
  }

  .gap-xl-9 {
    gap: 4rem !important;
  }

  .gap-xl-10 {
    gap: 5rem !important;
  }

  .gap-xl-11 {
    gap: 6rem !important;
  }

  .gap-xl-12 {
    gap: 9rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 2rem !important;
  }

  .m-xl-6 {
    margin: 2.5rem !important;
  }

  .m-xl-7 {
    margin: 3rem !important;
  }

  .m-xl-8 {
    margin: 3.5rem !important;
  }

  .m-xl-9 {
    margin: 4rem !important;
  }

  .m-xl-10 {
    margin: 5rem !important;
  }

  .m-xl-11 {
    margin: 6rem !important;
  }

  .m-xl-12 {
    margin: 9rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-8 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-12 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 2rem !important;
  }

  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 3rem !important;
  }

  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-9 {
    margin-top: 4rem !important;
  }

  .mt-xl-10 {
    margin-top: 5rem !important;
  }

  .mt-xl-11 {
    margin-top: 6rem !important;
  }

  .mt-xl-12 {
    margin-top: 9rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 2rem !important;
  }

  .me-xl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xl-7 {
    margin-right: 3rem !important;
  }

  .me-xl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xl-9 {
    margin-right: 4rem !important;
  }

  .me-xl-10 {
    margin-right: 5rem !important;
  }

  .me-xl-11 {
    margin-right: 6rem !important;
  }

  .me-xl-12 {
    margin-right: 9rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 9rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 2rem !important;
  }

  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 3rem !important;
  }

  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-9 {
    margin-left: 4rem !important;
  }

  .ms-xl-10 {
    margin-left: 5rem !important;
  }

  .ms-xl-11 {
    margin-left: 6rem !important;
  }

  .ms-xl-12 {
    margin-left: 9rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -2rem !important;
  }

  .m-xl-n6 {
    margin: -2.5rem !important;
  }

  .m-xl-n7 {
    margin: -3rem !important;
  }

  .m-xl-n8 {
    margin: -3.5rem !important;
  }

  .m-xl-n9 {
    margin: -4rem !important;
  }

  .m-xl-n10 {
    margin: -5rem !important;
  }

  .m-xl-n11 {
    margin: -6rem !important;
  }

  .m-xl-n12 {
    margin: -9rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xl-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xl-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xl-n8 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xl-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xl-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xl-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xl-n12 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xl-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xl-n10 {
    margin-top: -5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -6rem !important;
  }

  .mt-xl-n12 {
    margin-top: -9rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -2rem !important;
  }

  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -3rem !important;
  }

  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n9 {
    margin-right: -4rem !important;
  }

  .me-xl-n10 {
    margin-right: -5rem !important;
  }

  .me-xl-n11 {
    margin-right: -6rem !important;
  }

  .me-xl-n12 {
    margin-right: -9rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -9rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xl-n10 {
    margin-left: -5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -6rem !important;
  }

  .ms-xl-n12 {
    margin-left: -9rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 2rem !important;
  }

  .p-xl-6 {
    padding: 2.5rem !important;
  }

  .p-xl-7 {
    padding: 3rem !important;
  }

  .p-xl-8 {
    padding: 3.5rem !important;
  }

  .p-xl-9 {
    padding: 4rem !important;
  }

  .p-xl-10 {
    padding: 5rem !important;
  }

  .p-xl-11 {
    padding: 6rem !important;
  }

  .p-xl-12 {
    padding: 9rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xl-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xl-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xl-12 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xl-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 2rem !important;
  }

  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 3rem !important;
  }

  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-9 {
    padding-top: 4rem !important;
  }

  .pt-xl-10 {
    padding-top: 5rem !important;
  }

  .pt-xl-11 {
    padding-top: 6rem !important;
  }

  .pt-xl-12 {
    padding-top: 9rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 2rem !important;
  }

  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 3rem !important;
  }

  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-9 {
    padding-right: 4rem !important;
  }

  .pe-xl-10 {
    padding-right: 5rem !important;
  }

  .pe-xl-11 {
    padding-right: 6rem !important;
  }

  .pe-xl-12 {
    padding-right: 9rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 9rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 2rem !important;
  }

  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 3rem !important;
  }

  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-9 {
    padding-left: 4rem !important;
  }

  .ps-xl-10 {
    padding-left: 5rem !important;
  }

  .ps-xl-11 {
    padding-left: 6rem !important;
  }

  .ps-xl-12 {
    padding-left: 9rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .w-px-xl-50 {
    width: 50px !important;
  }

  .w-px-xl-100 {
    width: 100px !important;
  }

  .w-px-xl-150 {
    width: 150px !important;
  }

  .w-px-xl-200 {
    width: 200px !important;
  }

  .w-px-xl-250 {
    width: 250px !important;
  }

  .w-px-xl-300 {
    width: 300px !important;
  }

  .w-px-xl-350 {
    width: 350px !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .w-xxl-5 {
    width: 5% !important;
  }

  .w-xxl-10 {
    width: 10% !important;
  }

  .w-xxl-15 {
    width: 15% !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-30 {
    width: 30% !important;
  }

  .w-xxl-35 {
    width: 35% !important;
  }

  .w-xxl-40 {
    width: 40% !important;
  }

  .w-xxl-45 {
    width: 45% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-55 {
    width: 55% !important;
  }

  .w-xxl-60 {
    width: 60% !important;
  }

  .w-xxl-65 {
    width: 65% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-80 {
    width: 80% !important;
  }

  .w-xxl-85 {
    width: 85% !important;
  }

  .w-xxl-90 {
    width: 90% !important;
  }

  .w-xxl-95 {
    width: 95% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }

  .min-vh-xxl-65 {
    min-height: 65vh !important;
  }

  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }

  .min-vh-xxl-85 {
    min-height: 85vh !important;
  }

  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .25rem !important;
  }

  .gap-xxl-2 {
    gap: .5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 2rem !important;
  }

  .gap-xxl-6 {
    gap: 2.5rem !important;
  }

  .gap-xxl-7 {
    gap: 3rem !important;
  }

  .gap-xxl-8 {
    gap: 3.5rem !important;
  }

  .gap-xxl-9 {
    gap: 4rem !important;
  }

  .gap-xxl-10 {
    gap: 5rem !important;
  }

  .gap-xxl-11 {
    gap: 6rem !important;
  }

  .gap-xxl-12 {
    gap: 9rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .25rem !important;
  }

  .m-xxl-2 {
    margin: .5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 2rem !important;
  }

  .m-xxl-6 {
    margin: 2.5rem !important;
  }

  .m-xxl-7 {
    margin: 3rem !important;
  }

  .m-xxl-8 {
    margin: 3.5rem !important;
  }

  .m-xxl-9 {
    margin: 4rem !important;
  }

  .m-xxl-10 {
    margin: 5rem !important;
  }

  .m-xxl-11 {
    margin: 6rem !important;
  }

  .m-xxl-12 {
    margin: 9rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xxl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xxl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xxl-6 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xxl-7 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xxl-8 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xxl-9 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xxl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xxl-11 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xxl-12 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xxl-12 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .25rem !important;
  }

  .mt-xxl-2 {
    margin-top: .5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 2rem !important;
  }

  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 3rem !important;
  }

  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-9 {
    margin-top: 4rem !important;
  }

  .mt-xxl-10 {
    margin-top: 5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 6rem !important;
  }

  .mt-xxl-12 {
    margin-top: 9rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .25rem !important;
  }

  .me-xxl-2 {
    margin-right: .5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 2rem !important;
  }

  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 3rem !important;
  }

  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-9 {
    margin-right: 4rem !important;
  }

  .me-xxl-10 {
    margin-right: 5rem !important;
  }

  .me-xxl-11 {
    margin-right: 6rem !important;
  }

  .me-xxl-12 {
    margin-right: 9rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 6rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 9rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .25rem !important;
  }

  .ms-xxl-2 {
    margin-left: .5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 2rem !important;
  }

  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 3rem !important;
  }

  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-9 {
    margin-left: 4rem !important;
  }

  .ms-xxl-10 {
    margin-left: 5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 6rem !important;
  }

  .ms-xxl-12 {
    margin-left: 9rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.25rem !important;
  }

  .m-xxl-n2 {
    margin: -.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -2rem !important;
  }

  .m-xxl-n6 {
    margin: -2.5rem !important;
  }

  .m-xxl-n7 {
    margin: -3rem !important;
  }

  .m-xxl-n8 {
    margin: -3.5rem !important;
  }

  .m-xxl-n9 {
    margin: -4rem !important;
  }

  .m-xxl-n10 {
    margin: -5rem !important;
  }

  .m-xxl-n11 {
    margin: -6rem !important;
  }

  .m-xxl-n12 {
    margin: -9rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .mx-xxl-n6 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .mx-xxl-n7 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .mx-xxl-n8 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .mx-xxl-n9 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .mx-xxl-n10 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .mx-xxl-n11 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .mx-xxl-n12 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .my-xxl-n12 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -6rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -9rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -2rem !important;
  }

  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -3rem !important;
  }

  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n9 {
    margin-right: -4rem !important;
  }

  .me-xxl-n10 {
    margin-right: -5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -6rem !important;
  }

  .me-xxl-n12 {
    margin-right: -9rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -6rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -9rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -6rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -9rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .25rem !important;
  }

  .p-xxl-2 {
    padding: .5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 2rem !important;
  }

  .p-xxl-6 {
    padding: 2.5rem !important;
  }

  .p-xxl-7 {
    padding: 3rem !important;
  }

  .p-xxl-8 {
    padding: 3.5rem !important;
  }

  .p-xxl-9 {
    padding: 4rem !important;
  }

  .p-xxl-10 {
    padding: 5rem !important;
  }

  .p-xxl-11 {
    padding: 6rem !important;
  }

  .p-xxl-12 {
    padding: 9rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xxl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xxl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xxl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xxl-6 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xxl-7 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xxl-8 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xxl-9 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xxl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xxl-11 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .px-xxl-12 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .py-xxl-12 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .25rem !important;
  }

  .pt-xxl-2 {
    padding-top: .5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 2rem !important;
  }

  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 3rem !important;
  }

  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-9 {
    padding-top: 4rem !important;
  }

  .pt-xxl-10 {
    padding-top: 5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 6rem !important;
  }

  .pt-xxl-12 {
    padding-top: 9rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .25rem !important;
  }

  .pe-xxl-2 {
    padding-right: .5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 2rem !important;
  }

  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 3rem !important;
  }

  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-9 {
    padding-right: 4rem !important;
  }

  .pe-xxl-10 {
    padding-right: 5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 6rem !important;
  }

  .pe-xxl-12 {
    padding-right: 9rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 6rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 9rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .25rem !important;
  }

  .ps-xxl-2 {
    padding-left: .5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 2rem !important;
  }

  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 3rem !important;
  }

  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-9 {
    padding-left: 4rem !important;
  }

  .ps-xxl-10 {
    padding-left: 5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 6rem !important;
  }

  .ps-xxl-12 {
    padding-left: 9rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .w-px-xxl-50 {
    width: 50px !important;
  }

  .w-px-xxl-100 {
    width: 100px !important;
  }

  .w-px-xxl-150 {
    width: 150px !important;
  }

  .w-px-xxl-200 {
    width: 200px !important;
  }

  .w-px-xxl-250 {
    width: 250px !important;
  }

  .w-px-xxl-300 {
    width: 300px !important;
  }

  .w-px-xxl-350 {
    width: 350px !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.header-dark .logo-dark {
  display: none;
}

.fixed-header {
  box-shadow: 0 .375rem 1.5rem #8c98a420;
}

.fixed-header .logo-light {
  display: none;
}

.fixed-header .logo-dark {
  display: inline-flex;
}

.header-main {
  transition: all .35s;
}

.navbar-nav .nav-link {
  font-weight: 600;
}

.fixed-header.header-main {
  background: #fff;
}

.header-blur-light {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background: #ffffff1a;
}

.header-blur-light.fixed-header {
  background: #fffc;
}

.navbar {
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar .header-search .dropdown-toggle {
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 23px;
  line-height: 1;
  display: inline-flex;
  outline: none !important;
}

.navbar .header-search .dropdown-toggle:after {
  display: none;
}

.navbar .header-search .dropdown-menu {
  width: 100vw;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0;
  margin: 0;
  padding: 14px;
  left: auto;
  right: 0;
}

.navbar .header-user .dropdown-toggle {
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 23px;
  line-height: 1;
  display: inline-flex;
  outline: none !important;
}

.navbar .header-user .dropdown-toggle:after {
  display: none;
}

.header-light .navbar .header-user .dropdown-toggle {
  color: #08052f;
  border: 1px solid #0000004d;
}

.px-dropdown-toggle {
  color: #08052f;
  padding: .9rem .6rem;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.px-dropdown-toggle.open:before {
  content: "";
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    width: 100%;
    max-height: 80vh;
    background: #fff;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
  }

  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-link {
    color: #08052f;
  }

  .navbar-expand-sm .dropdown-menu {
    background: #f1f6fd;
    border: 1px solid #e2e8f0;
  }

  .navbar-expand-sm .dropdown-menu .dropdown-menu {
    background: #fff;
    margin: 0 12px;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-expand-sm > .container {
    position: relative;
  }

  .navbar-expand-sm .px-dropdown-toggle {
    display: none;
  }

  .navbar-expand-sm .header-search {
    width: 100%;
    position: relative;
  }

  .navbar-expand-sm .header-search .dropdown-toggle {
    display: none;
  }

  .navbar-expand-sm .header-search .dropdown-menu {
    z-index: auto;
    width: auto;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    top: auto;
  }

  .navbar-expand-sm .header-search .input-group-text {
    background: none;
    border-left: none;
    padding: 0 12px;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
  }

  .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    margin-left: 7px;
    font-size: 75%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-sm .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-sm .navbar-nav .px-mega-dropdown {
    position: static;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    border-top: 2px solid #03c;
    margin: 0;
    transition: all .35s;
    display: block;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 10px 40px 10px #8c98a42d;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.left {
    left: 0;
    transform: translate(0);
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0);
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
    padding-right: 18px;
    position: relative;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    height: 10px;
    margin: auto;
    font-size: 85%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu-sub {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(0);
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 22rem;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 35rem;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.dropdown-menu-xl {
    min-width: 42rem;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu.px-mega-menu {
    width: 100%;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-item {
    padding: .4rem 1rem;
    font-weight: 600;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse {
    width: 100%;
    max-height: 80vh;
    background: #fff;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
  }

  .navbar-expand-md .navbar-collapse .navbar-nav .nav-link {
    color: #08052f;
  }

  .navbar-expand-md .dropdown-menu {
    background: #f1f6fd;
    border: 1px solid #e2e8f0;
  }

  .navbar-expand-md .dropdown-menu .dropdown-menu {
    background: #fff;
    margin: 0 12px;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-expand-md > .container {
    position: relative;
  }

  .navbar-expand-md .px-dropdown-toggle {
    display: none;
  }

  .navbar-expand-md .header-search {
    width: 100%;
    position: relative;
  }

  .navbar-expand-md .header-search .dropdown-toggle {
    display: none;
  }

  .navbar-expand-md .header-search .dropdown-menu {
    z-index: auto;
    width: auto;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    top: auto;
  }

  .navbar-expand-md .header-search .input-group-text {
    background: none;
    border-left: none;
    padding: 0 12px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
  }

  .navbar-expand-md .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    margin-left: 7px;
    font-size: 75%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-md .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-md .navbar-nav .px-mega-dropdown {
    position: static;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    border-top: 2px solid #03c;
    margin: 0;
    transition: all .35s;
    display: block;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 10px 40px 10px #8c98a42d;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.left {
    left: 0;
    transform: translate(0);
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0);
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
    padding-right: 18px;
    position: relative;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    height: 10px;
    margin: auto;
    font-size: 85%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu-sub {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(0);
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 22rem;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 35rem;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.dropdown-menu-xl {
    min-width: 42rem;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu.px-mega-menu {
    width: 100%;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-item {
    padding: .4rem 1rem;
    font-weight: 600;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    width: 100%;
    max-height: 80vh;
    background: #fff;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
  }

  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #08052f;
  }

  .navbar-expand-lg .dropdown-menu {
    background: #f1f6fd;
    border: 1px solid #e2e8f0;
  }

  .navbar-expand-lg .dropdown-menu .dropdown-menu {
    background: #fff;
    margin: 0 12px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-expand-lg > .container {
    position: relative;
  }

  .navbar-expand-lg .px-dropdown-toggle {
    display: none;
  }

  .navbar-expand-lg .header-search {
    width: 100%;
    position: relative;
  }

  .navbar-expand-lg .header-search .dropdown-toggle {
    display: none;
  }

  .navbar-expand-lg .header-search .dropdown-menu {
    z-index: auto;
    width: auto;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    top: auto;
  }

  .navbar-expand-lg .header-search .input-group-text {
    background: none;
    border-left: none;
    padding: 0 12px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
  }

  .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    margin-left: 7px;
    font-size: 75%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-lg .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-lg .navbar-nav .px-mega-dropdown {
    position: static;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    border-top: 2px solid #03c;
    margin: 0;
    transition: all .35s;
    display: block;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 10px 40px 10px #8c98a42d;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.left {
    left: 0;
    transform: translate(0);
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0);
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
    padding-right: 18px;
    position: relative;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    height: 10px;
    margin: auto;
    font-size: 85%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-sub {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(0);
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 22rem;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 35rem;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.dropdown-menu-xl {
    min-width: 42rem;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu.px-mega-menu {
    width: 100%;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-item {
    padding: .4rem 1rem;
    font-weight: 600;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    width: 100%;
    max-height: 80vh;
    background: #fff;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
  }

  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-link {
    color: #08052f;
  }

  .navbar-expand-xl .dropdown-menu {
    background: #f1f6fd;
    border: 1px solid #e2e8f0;
  }

  .navbar-expand-xl .dropdown-menu .dropdown-menu {
    background: #fff;
    margin: 0 12px;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-expand-xl > .container {
    position: relative;
  }

  .navbar-expand-xl .px-dropdown-toggle {
    display: none;
  }

  .navbar-expand-xl .header-search {
    width: 100%;
    position: relative;
  }

  .navbar-expand-xl .header-search .dropdown-toggle {
    display: none;
  }

  .navbar-expand-xl .header-search .dropdown-menu {
    z-index: auto;
    width: auto;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    top: auto;
  }

  .navbar-expand-xl .header-search .input-group-text {
    background: none;
    border-left: none;
    padding: 0 12px;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
  }

  .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    margin-left: 7px;
    font-size: 75%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-xl .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-xl .navbar-nav .px-mega-dropdown {
    position: static;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    border-top: 2px solid #03c;
    margin: 0;
    transition: all .35s;
    display: block;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 10px 40px 10px #8c98a42d;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.left {
    left: 0;
    transform: translate(0);
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0);
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
    padding-right: 18px;
    position: relative;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    height: 10px;
    margin: auto;
    font-size: 85%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu-sub {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(0);
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 22rem;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 35rem;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.dropdown-menu-xl {
    min-width: 42rem;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu.px-mega-menu {
    width: 100%;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-item {
    padding: .4rem 1rem;
    font-weight: 600;
  }
}

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-collapse {
    width: 100%;
    max-height: 80vh;
    background: #fff;
    padding: 8px 15px;
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: auto;
  }

  .navbar-expand-xxl .navbar-collapse .navbar-nav .nav-link {
    color: #08052f;
  }

  .navbar-expand-xxl .dropdown-menu {
    background: #f1f6fd;
    border: 1px solid #e2e8f0;
  }

  .navbar-expand-xxl .dropdown-menu .dropdown-menu {
    background: #fff;
    margin: 0 12px;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-expand-xxl > .container {
    position: relative;
  }

  .navbar-expand-xxl .px-dropdown-toggle {
    display: none;
  }

  .navbar-expand-xxl .header-search {
    width: 100%;
    position: relative;
  }

  .navbar-expand-xxl .header-search .dropdown-toggle {
    display: none;
  }

  .navbar-expand-xxl .header-search .dropdown-menu {
    z-index: auto;
    width: auto;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    top: auto;
  }

  .navbar-expand-xxl .header-search .input-group-text {
    background: none;
    border-left: none;
    padding: 0 12px;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 80px;
  }

  .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    margin-left: 7px;
    font-size: 75%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-xxl .navbar-nav > .nav-item:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-xxl .navbar-nav .px-mega-dropdown {
    position: static;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    border-top: 2px solid #03c;
    margin: 0;
    transition: all .35s;
    display: block;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 10px 40px 10px #8c98a42d;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.left {
    left: 0;
    transform: translate(0);
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.right {
    left: auto;
    right: 0;
    transform: translate(0);
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
    padding-right: 18px;
    position: relative;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
    content: "";
    font-variant: normal;
    text-transform: none;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    height: 10px;
    margin: auto;
    font-size: 85%;
    font-style: normal;
    line-height: 1;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    font-family: bootstrap-icons !important;
    font-weight: normal !important;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu-sub {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(0);
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
    left: auto;
    right: 100%;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-md {
    min-width: 22rem;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-lg {
    min-width: 35rem;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.dropdown-menu-xl {
    min-width: 42rem;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu.px-mega-menu {
    width: 100%;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-item {
    padding: .4rem 1rem;
    font-weight: 600;
  }
}

.navbar-expand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .navbar-collapse {
  width: 100%;
  max-height: 80vh;
  background: #fff;
  padding: 8px 15px;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.navbar-expand .navbar-collapse .navbar-nav .nav-link {
  color: #08052f;
}

.navbar-expand .dropdown-menu {
  background: #f1f6fd;
  border: 1px solid #e2e8f0;
}

.navbar-expand .dropdown-menu .dropdown-menu {
  background: #fff;
  margin: 0 12px;
}

.navbar-expand > .container {
  position: relative;
}

.navbar-expand .px-dropdown-toggle {
  display: none;
}

.navbar-expand .header-search {
  width: 100%;
  position: relative;
}

.navbar-expand .header-search .dropdown-toggle {
  display: none;
}

.navbar-expand .header-search .dropdown-menu {
  z-index: auto;
  width: auto;
  border: none;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  top: auto;
}

.navbar-expand .header-search .input-group-text {
  background: none;
  border-left: none;
  padding: 0 12px;
}

.navbar-expand .navbar-nav .nav-link {
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 80px;
}

.navbar-expand .navbar-nav > .nav-item.dropdown > .nav-link:after {
  content: "";
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: currentColor;
  margin-left: 7px;
  font-size: 75%;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.navbar-expand .navbar-nav > .nav-item:hover > .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
}

.navbar-expand .navbar-nav .px-mega-dropdown {
  position: static;
}

.navbar-expand .navbar-nav .dropdown-menu {
  opacity: 0;
  pointer-events: none;
  border-top: 2px solid #03c;
  margin: 0;
  transition: all .35s;
  display: block;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 10px 40px 10px #8c98a42d;
}

.navbar-expand .navbar-nav .dropdown-menu.left {
  left: 0;
  transform: translate(0);
}

.navbar-expand .navbar-nav .dropdown-menu.right {
  left: auto;
  right: 0;
  transform: translate(0);
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown > .dropdown-item {
  padding-right: 18px;
  position: relative;
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown > .dropdown-item:after {
  content: "";
  font-variant: normal;
  text-transform: none;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: currentColor;
  height: 10px;
  margin: auto;
  font-size: 85%;
  font-style: normal;
  line-height: 1;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  font-family: bootstrap-icons !important;
  font-weight: normal !important;
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown:hover .dropdown-menu-sub {
  opacity: 1;
  pointer-events: auto;
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown-menu-sub {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(0);
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown-menu-sub.right {
  left: auto;
  right: 100%;
}

.navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-md {
  min-width: 22rem;
}

.navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-lg {
  min-width: 35rem;
}

.navbar-expand .navbar-nav .dropdown-menu.dropdown-menu-xl {
  min-width: 42rem;
}

.navbar-expand .navbar-nav .dropdown-menu.px-mega-menu {
  width: 100%;
}

.navbar-expand .navbar-nav .dropdown-menu .dropdown-item {
  padding: .4rem 1rem;
  font-weight: 600;
}

.footer .footer-title-01 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
  font-size: 13px;
  font-weight: 300;
}

@media (max-width: 991.98px) {
  .footer .footer-title-01 {
    margin-bottom: 20px;
  }
}

.footer .footer-title-02 {
  margin-bottom: 30px;
  font-size: 18px;
}

@media (max-width: 991.98px) {
  .footer .footer-title-02 {
    margin-bottom: 20px;
  }
}

.footer .footer-link-01 li + li {
  padding-top: 15px;
}

@media (max-width: 991.98px) {
  .footer .footer-link-01 li + li {
    padding-top: 8px;
  }
}

.footer .footer-link-01 a {
  vertical-align: top;
  display: inline-block;
  position: relative;
}

.footer .footer-link-01 a:after {
  content: "";
  width: 0;
  height: 1px;
  background: currentColor;
  transition: all .35s;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
}

.footer .footer-link-01 a:hover:after {
  width: 100%;
  left: 0;
  right: auto;
}

body {
  overflow-x: hidden;
}

body, html {
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  flex-flow: column;
  display: flex;
  position: relative;
}

.wrapper > main {
  flex: 1 0 auto;
}

.wrapper > * {
  flex: none;
}

.bg-white-soft {
  background-color: #ffffff4d;
}

.bg-primary-soft {
  background-color: #0033cc1a;
}

.bg-secondary-soft {
  background-color: #15db951a;
}

.bg-success-soft {
  background-color: #5cc9a71a;
}

.bg-info-soft {
  background-color: #50b5ff1a;
}

.bg-warning-soft {
  background-color: #ffbe3d26;
}

.bg-danger-soft {
  background-color: #f257671a;
}

.bg-light-soft {
  background-color: #f1f6fd1a;
}

.bg-dark-soft {
  background-color: #08052f1a;
}

.bg-primary-after:after {
  background-color: #03c;
}

.bg-secondary-after:after {
  background-color: #15db95;
}

.bg-success-after:after {
  background-color: #5cc9a7;
}

.bg-info-after:after {
  background-color: #50b5ff;
}

.bg-warning-after:after {
  background-color: #ffbe3d;
}

.bg-danger-after:after {
  background-color: #f25767;
}

.bg-light-after:after {
  background-color: #f1f6fd;
}

.bg-white-after:after {
  background-color: #fff;
}

.bg-black-after:after {
  background-color: #000;
}

.bg-dark-after:after {
  background-color: #08052f;
}

.bg-white-dark {
  background-color: #e6e6e6;
}

.bg-primary-dark {
  background-color: #002699;
}

.bg-secondary-dark {
  background-color: #11ac75;
}

.bg-success-dark {
  background-color: #3cb690;
}

.bg-info-dark {
  background-color: #1d9fff;
}

.bg-warning-dark {
  background-color: #ffad0a;
}

.bg-danger-dark {
  background-color: #ee283c;
}

.bg-light-dark {
  background-color: #c4d9f7;
}

.bg-dark-dark {
  background-color: #000001;
}

.bg-white-gradient {
  background: linear-gradient(#ffffff4d, #fff0 100%) !important;
}

.bg-primary-gradient {
  background: linear-gradient(#0033cc1a, #fff0 100%) !important;
}

.bg-secondary-gradient {
  background: linear-gradient(#15db951a, #fff0 100%) !important;
}

.bg-success-gradient {
  background: linear-gradient(#5cc9a71a, #fff0 100%) !important;
}

.bg-info-gradient {
  background: linear-gradient(#50b5ff1a, #fff0 100%) !important;
}

.bg-warning-gradient {
  background: linear-gradient(#ffbe3d26, #fff0 100%) !important;
}

.bg-danger-gradient {
  background: linear-gradient(#f257671a, #fff0 100%) !important;
}

.bg-light-gradient {
  background: linear-gradient(#a0aec01a, #fff0 100%) !important;
}

.bg-dark-gradient {
  background: linear-gradient(#08052f1a, #fff0 100%) !important;
}

.bg-primary-gradient-dark {
  background: linear-gradient(#03c, #002080 100%) !important;
}

.bg-secondary-gradient-dark {
  background: linear-gradient(#15db95, #0e9566 100%) !important;
}

.bg-success-gradient-dark {
  background: linear-gradient(#5cc9a7, #36a381 100%) !important;
}

.bg-info-gradient-dark {
  background: linear-gradient(#50b5ff, #0495ff 100%) !important;
}

.bg-warning-gradient-dark {
  background: linear-gradient(#ffbe3d, #f09f00 100%) !important;
}

.bg-danger-gradient-dark {
  background: linear-gradient(#f25767, #ea1228 100%) !important;
}

.bg-light-gradient-dark {
  background: linear-gradient(#f1f6fd, #aecbf3 100%) !important;
}

.bg-white-gradient-dark {
  background: linear-gradient(#fff, #d9d9d9 100%) !important;
}

.bg-black-gradient-dark {
  background: linear-gradient(#000, #000 100%) !important;
}

.bg-dark-gradient-dark {
  background: linear-gradient(#08052f, #000 100%) !important;
}

.bg-white-gradient-reverse {
  background: linear-gradient(0deg, #ffffff4d, #fff0 100%) !important;
}

.bg-primary-gradient-reverse {
  background: linear-gradient(0deg, #0033cc1a, #fff0 100%) !important;
}

.bg-secondary-gradient-reverse {
  background: linear-gradient(0deg, #15db951a, #fff0 100%) !important;
}

.bg-success-gradient-reverse {
  background: linear-gradient(0deg, #5cc9a71a, #fff0 100%) !important;
}

.bg-info-gradient-reverse {
  background: linear-gradient(0deg, #50b5ff1a, #fff0 100%) !important;
}

.bg-warning-gradient-reverse {
  background: linear-gradient(0deg, #ffbe3d26, #fff0 100%) !important;
}

.bg-danger-gradient-reverse {
  background: linear-gradient(0deg, #f257671a, #fff0 100%) !important;
}

.bg-light-gradient-reverse {
  background: linear-gradient(0deg, #a0aec01a, #fff0 100%) !important;
}

.bg-dark-gradient-reverse {
  background: linear-gradient(0deg, #08052f1a, #fff0 100%) !important;
}

a.bg-primary:hover {
  background: #0029a3 !important;
}

a.bg-secondary:hover {
  background: #11b67c !important;
}

a.bg-success:hover {
  background: #3fbd96 !important;
}

a.bg-info:hover {
  background: #27a4ff !important;
}

a.bg-warning:hover {
  background: #ffb014 !important;
}

a.bg-danger:hover {
  background: #ef3145 !important;
}

a.bg-light:hover {
  background: #cddff8 !important;
}

a.bg-white:hover {
  background: #ebebeb !important;
}

a.bg-black:hover {
  background: #000 !important;
}

a.bg-dark:hover {
  background: #02010a !important;
}

.bg-gray-gradient-reverse {
  background: linear-gradient(0deg, #f1f6fd, #fff0 100%) !important;
}

.bg-gray-100 {
  background: #f1f6fd !important;
}

.bg-gray-200 {
  background: #eaecf3 !important;
}

.bg-gray-300 {
  background: #e2e8f0 !important;
}

.bg-gray-400 {
  background: #cbd5e0 !important;
}

.bg-gray-500 {
  background: #a0aec0 !important;
}

.bg-gray-600 {
  background: #718096 !important;
}

.bg-gray-700 {
  background: #4a5568 !important;
}

.bg-gray-800 {
  background: #2d3748 !important;
}

.bg-gray-900 {
  background: #273444 !important;
}

.bg-cover {
  background-size: cover;
}

.bg-right-center {
  background-position: 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center;
}

@media (min-width: 992px) {
  .bg-fixed {
    background-attachment: fixed;
  }
}

.breadcrumb-light .breadcrumb-item.active {
  color: #fffc;
}

.breadcrumb-light .breadcrumb-item a {
  color: #fff;
}

.breadcrumb-light .breadcrumb-item:before {
  color: #fff9;
}

.preloader {
  z-index: 99999;
  background: #fff;
  position: fixed;
  inset: 0;
}

.preloader .spinner-border {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.btn-primary-light {
  color: #03c;
  background-color: #03c3;
}

.btn-primary-light:hover {
  color: #fff;
  background-color: #03c;
}

.btn-secondary-light {
  color: #15db95;
  background-color: #15db9533;
}

.btn-secondary-light:hover {
  color: #fff;
  background-color: #15db95;
}

.btn-success-light {
  color: #5cc9a7;
  background-color: #5cc9a733;
}

.btn-success-light:hover {
  color: #fff;
  background-color: #5cc9a7;
}

.btn-info-light {
  color: #50b5ff;
  background-color: #50b5ff33;
}

.btn-info-light:hover {
  color: #fff;
  background-color: #50b5ff;
}

.btn-warning-light {
  color: #ffbe3d;
  background-color: #ffbe3d33;
}

.btn-warning-light:hover {
  color: #fff;
  background-color: #ffbe3d;
}

.btn-danger-light {
  color: #f25767;
  background-color: #f2576733;
}

.btn-danger-light:hover {
  color: #fff;
  background-color: #f25767;
}

.btn-light-light {
  color: #f1f6fd;
  background-color: #f1f6fd33;
}

.btn-light-light:hover {
  color: #000;
  background-color: #f1f6fd;
}

.btn-white-light {
  color: #fff;
  background-color: #fff3;
}

.btn-white-light:hover {
  color: #000;
  background-color: #fff;
}

.btn-black-light {
  color: #000;
  background-color: #0003;
}

.btn-black-light:hover {
  color: #fff;
  background-color: #000;
}

.btn-dark-light {
  color: #08052f;
  background-color: #08052f33;
}

.btn-dark-light:hover {
  color: #fff;
  background-color: #08052f;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.show {
  background-color: #002aa8;
  border-color: #002aa8;
  box-shadow: 0 .25rem .25rem .125rem #0033cc1a, 0 .375rem .75rem -.125rem #03c6;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.show {
  background-color: #12ba7f;
  border-color: #12ba7f;
  box-shadow: 0 .25rem .25rem .125rem #15db951a, 0 .375rem .75rem -.125rem #15db9566;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.show {
  background-color: #41c099;
  border-color: #41c099;
  box-shadow: 0 .25rem .25rem .125rem #5cc9a71a, 0 .375rem .75rem -.125rem #5cc9a766;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.show {
  background-color: #2ca6ff;
  border-color: #2ca6ff;
  box-shadow: 0 .25rem .25rem .125rem #50b5ff1a, 0 .375rem .75rem -.125rem #50b5ff66;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.show {
  background-color: #ffb219;
  border-color: #ffb219;
  box-shadow: 0 .25rem .25rem .125rem #ffbe3d1a, 0 .375rem .75rem -.125rem #ffbe3d66;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.show {
  background-color: #ef3649;
  border-color: #ef3649;
  box-shadow: 0 .25rem .25rem .125rem #f257671a, 0 .375rem .75rem -.125rem #f2576766;
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.show {
  background-color: #d2e2f9;
  border-color: #d2e2f9;
  box-shadow: 0 .25rem .25rem .125rem #f1f6fd1a, 0 .375rem .75rem -.125rem #f1f6fd66;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .btn-white.show {
  background-color: #ededed;
  border-color: #ededed;
  box-shadow: 0 .25rem .25rem .125rem #ffffff1a, 0 .375rem .75rem -.125rem #fff6;
}

.btn-black:hover, .btn-black:focus, .btn-black:active, .btn-black.active, .btn-black.show {
  background-color: #000;
  border-color: #000;
  box-shadow: 0 .25rem .25rem .125rem #0000001a, 0 .375rem .75rem -.125rem #0006;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.show {
  background-color: #03020f;
  border-color: #03020f;
  box-shadow: 0 .25rem .25rem .125rem #08052f1a, 0 .375rem .75rem -.125rem #08052f66;
}

.btn-white {
  border-color: #cbd5e0;
}

.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: .5em;
}

.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: .5em;
}

.btn .spinner-grow-sm, .btn .spinner-border-sm {
  vertical-align: middle;
}

.max-w-100px {
  max-width: 100px !important;
}

.max-w-150px {
  max-width: 150px !important;
}

.max-w-200px {
  max-width: 200px !important;
}

.max-w-250px {
  max-width: 250px !important;
}

.card {
  box-shadow: 0 0 1.25rem #1f2d3d0d;
}

.min-h-150px {
  min-height: 150px;
}

.min-h-250px {
  min-height: 250px;
}

.min-h-350px {
  min-height: 350px;
}

.section {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
}

@media (min-width: 768px) {
  .section {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
}

@media (min-width: 992px) {
  .section {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
}

.section-heading {
  margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .section-heading {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .section-heading {
    margin-bottom: 3.5rem;
  }
}

.avatar {
  height: 3.125rem;
  width: 3.125rem;
  position: relative;
  display: inline-block !important;
}

.avatar .avatar-status {
  height: 20%;
  width: 20%;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.avatar .avatar-text {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-group {
  padding: 0;
  display: flex;
}

.avatar-group .avatar-img, .avatar-group .avatar-text {
  border: 2px solid #fff;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.6125rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -.875rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.5rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

.avatar-group .avatarxxl + .avatarxxl {
  margin-left: -2.5rem;
}

.avatar-group .avatar-xxxl + .avatar-xxxl {
  margin-left: -3rem;
}

.avatar .avatar-name {
  margin-left: 7px;
}

.avatar-xs {
  height: 1.75rem;
  width: 1.75rem;
}

.avatar-xs .avatar-text {
  font-size: .75rem;
}

.avatar-sm {
  height: 2.6875rem;
  width: 2.6875rem;
}

.avatar-sm .avatar-text {
  font-size: .8125rem;
}

.avatar-lg {
  height: 4.25rem;
  width: 4.25rem;
}

.avatar-lg .avatar-text {
  font-size: 1.25rem;
}

.avatar-xl {
  height: 5.625rem;
  width: 5.625rem;
}

.avatar-xl .avatar-text {
  font-size: 2rem;
}

.avatar-xxl {
  height: 7rem;
  width: 7rem;
}

.avatar-xxl .avatar-text {
  font-size: 3rem;
}

.avatar-xxxl {
  width: 8rem;
  height: 8rem;
}

.avatar-xxxl .avatar-text {
  font-size: 3.5rem;
}

.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 75%;
  line-height: normal;
  display: inline-flex;
}

.icon-md {
  width: 2.8rem;
  height: 2.8rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  display: inline-flex;
}

.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1;
  display: inline-flex;
}

.icon-xl {
  width: 5rem;
  height: 5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  line-height: 1;
  display: inline-flex;
}

.only-icon {
  display: inline-flex;
}

.only-icon i {
  font-size: 1.8rem;
}

.only-icon-sm i {
  font-size: 1rem;
}

.only-icon-lg i {
  font-size: 2.25rem;
}

.only-icon-xl i {
  font-size: 3rem;
}

@keyframes pulse-border {
  0% {
    opacity: 1;
    transform: scale(.8);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.after-shadow {
  z-index: 1;
  position: relative;
}

.after-shadow:after {
  content: "";
  pointer-events: none;
  z-index: -1;
  background: currentColor;
  border-radius: 50%;
  margin: auto;
  animation: 1.5s ease-out infinite pulse-border;
  position: absolute;
  inset: 0;
}

.dots-icon {
  position: relative;
}

.dots {
  z-index: 0;
  animation: 6s linear infinite dots;
  position: absolute;
  inset: 0;
}

.dots .dot {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin-top: -3px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.dots .dot1 {
  background-color: #8ad9fd;
  animation: 1.5s infinite alternate dot1;
  transform: translate(15px, 5px);
}

.dots .dot2 {
  background-color: #b8a6f7;
  animation: 1.5s .5s infinite alternate dot2;
  transform: translate(-8px, 8px);
}

.dots .dot3 {
  background-color: #fac5a7;
  animation: 1.5s 1s infinite alternate dot3;
  transform: translate(-5px, -12px);
}

@keyframes dots {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes dot1 {
  from {
    opacity: 1;
    transform: translate(15px, 5px);
  }

  to {
    opacity: 1;
    transform: translate(25px, 5px);
  }
}

@keyframes dot2 {
  from {
    opacity: 1;
    transform: translate(-8px, 8px);
  }

  to {
    opacity: 1;
    transform: translate(-19px, 19px);
  }
}

@keyframes dot3 {
  from {
    opacity: 1;
    transform: translate(-5px, -12px);
  }

  to {
    opacity: 1;
    transform: translate(-10px, -24px);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hover-rotate .hr-rotate {
  animation: 1.2s linear infinite paused lds-dual-ring;
}

.hover-rotate .hr-rotate-after {
  z-index: 1;
  position: relative;
}

.hover-rotate .hr-rotate-after:after {
  content: "";
  z-index: -1;
  border-radius: 50px;
  margin: auto;
  transition: all .55s;
  animation: 1.2s linear infinite paused lds-dual-ring;
  position: absolute;
}

.hover-rotate .ef-1:after {
  border: 50px solid #0000;
  border-left-color: currentColor;
  top: -10px;
  left: -10px;
}

.hover-rotate .ef-2:after {
  border: 4px solid;
  border-bottom-color: #0000;
  inset: -10px;
}

.hover-rotate .ef-3:after {
  border: 2px solid #0000;
  border-color: #0000 currentColor;
  inset: -8px;
}

.hover-rotate .ef-4:after {
  border: 2px solid #0000;
  border-bottom-color: currentColor;
  inset: -8px;
}

.hover-rotate:hover .hr-rotate, .hover-rotate:hover .hr-rotate-after:after {
  animation-play-state: running;
}

.arrow-icon {
  width: 32px;
  height: 12px;
  position: relative;
}

.arrow-icon:before {
  width: 32px;
  height: 2px;
  content: "";
  background: currentColor;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.arrow-icon:after {
  content: "";
  width: 12px;
  height: 12px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg);
}

img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .md-img-140 {
    max-width: 140%;
  }

  .md-img-120 {
    max-width: 120%;
  }
}

@media (min-width: 992px) {
  .lg-img-140 {
    max-width: 140%;
  }

  .lg-img-120 {
    max-width: 120%;
  }
}

.object-fit {
  object-fit: cover;
}

.mw-auto {
  max-width: inherit;
}

.grayscale {
  filter: gray;
  -webkit-filter: grayscale();
  filter: grayscale();
}

.svg_img polygon, .svg_img path, .svg_img circle {
  fill: currentColor;
}

.link-effect {
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.link-effect:after {
  content: "";
  width: 100%;
  height: 1px;
  background: currentColor;
  transition: all .35s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
}

.link-effect:hover:after {
  width: 0;
  left: auto;
  right: 0;
}

.text-gray-100 {
  color: #f1f6fd;
}

.text-gray-200 {
  color: #eaecf3;
}

.text-gray-300 {
  color: #e2e8f0;
}

.text-gray-400 {
  color: #cbd5e0;
}

.text-gray-500 {
  color: #a0aec0;
}

.text-gray-600 {
  color: #718096;
}

.text-gray-700 {
  color: #4a5568;
}

.text-gray-800 {
  color: #2d3748;
}

.text-gray-900 {
  color: #273444;
}

.text-white-65, .text-white-85 {
  color: #ffffffa6;
}

.border-primary-light {
  border-color: #0033cc1a !important;
}

.border-secondary-light {
  border-color: #15db951a !important;
}

.border-success-light {
  border-color: #5cc9a71a !important;
}

.border-info-light {
  border-color: #50b5ff1a !important;
}

.border-warning-light {
  border-color: #ffbe3d1a !important;
}

.border-danger-light {
  border-color: #f257671a !important;
}

.border-light-light {
  border-color: #f1f6fd1a !important;
}

.border-white-light {
  border-color: #ffffff1a !important;
}

.border-black-light {
  border-color: #0000001a !important;
}

.border-dark-light {
  border-color: #08052f1a !important;
}

.border-style {
  position: relative;
}

.border-style:after {
  content: "";
  width: 80%;
  height: 1px;
  background: radial-gradient(#e2e8f0 0, #fff0 75%);
  margin: auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

.border-style.bottom:after {
  bottom: 0;
}

.border-style.top:after {
  top: 0;
}

.border-style.light:after {
  background: radial-gradient(#fff3 0, #1f2d3d00 75%);
}

.separated {
  overflow: hidden;
}

.separated > * {
  box-shadow: -1px -1px #e2e8f0;
}

.divider {
  height: 1px;
  width: 100%;
  background: #000;
  margin: 0 auto;
  display: block;
}

.divider-light {
  height: 1px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
  display: block;
}

@media (min-width: 576px) {
  .col-sm-divider > :not(:first-child) {
    position: relative;
  }

  .col-sm-divider > :not(:first-child):before {
    width: .0625rem;
    height: 100%;
    content: "";
    background-color: #00221c1a;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .col-md-divider > :not(:first-child) {
    position: relative;
  }

  .col-md-divider > :not(:first-child):before {
    width: .0625rem;
    height: 100%;
    content: "";
    background-color: #00221c1a;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  .col-lg-divider > :not(:first-child) {
    position: relative;
  }

  .col-lg-divider > :not(:first-child):before {
    width: .0625rem;
    height: 100%;
    content: "";
    background-color: #00221c1a;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .col-xl-divider > :not(:first-child) {
    position: relative;
  }

  .col-xl-divider > :not(:first-child):before {
    width: .0625rem;
    height: 100%;
    content: "";
    background-color: #00221c1a;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1400px) {
  .col-xxl-divider > :not(:first-child) {
    position: relative;
  }

  .col-xxl-divider > :not(:first-child):before {
    width: .0625rem;
    height: 100%;
    content: "";
    background-color: #00221c1a;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.col-divider > :not(:first-child) {
  position: relative;
}

.col-divider > :not(:first-child):before {
  width: .0625rem;
  height: 100%;
  content: "";
  background-color: #00221c1a;
  position: absolute;
  top: 0;
  left: 0;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.text-facebook {
  color: #1877f2 !important;
}

.text-twitter {
  color: #1da1f2 !important;
}

.text-youtube {
  color: red !important;
}

.text-instagram {
  color: #c32aa3 !important;
}

.text-pinterest {
  color: #bd081c !important;
}

.text-linkedin {
  color: #2867b2 !important;
}

.text-google {
  color: #4285f4 !important;
}

.text-snapchat {
  color: #fffc00 !important;
}

.text-whatsapp {
  color: #25d366 !important;
}

.text-tumblr {
  color: #35465d !important;
}

.text-reddit {
  color: #ff4500 !important;
}

.text-tiktok {
  color: #010101 !important;
}

.text-yelp {
  color: #d32323 !important;
}

.text-spotify {
  color: #1ed760 !important;
}

.text-apple {
  color: #a6b1b7 !important;
}

.text-amazon {
  color: #f90 !important;
}

.text-microsoft {
  color: #f35022 !important;
}

.text-skype {
  color: #00aff0 !important;
}

.text-android {
  color: #a4c639 !important;
}

.text-quora {
  color: #a20 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

.text-slack {
  color: #4a154b !important;
}

.bg-facebook {
  background: #1877f2;
}

.bg-twitter {
  background: #1da1f2;
}

.bg-youtube {
  background: red;
}

.bg-instagram {
  background: #c32aa3;
}

.bg-pinterest {
  background: #bd081c;
}

.bg-linkedin {
  background: #2867b2;
}

.bg-google {
  background: #4285f4;
}

.bg-snapchat {
  background: #fffc00;
}

.bg-whatsapp {
  background: #25d366;
}

.bg-tumblr {
  background: #35465d;
}

.bg-reddit {
  background: #ff4500;
}

.bg-tiktok {
  background: #010101;
}

.bg-yelp {
  background: #d32323;
}

.bg-spotify {
  background: #1ed760;
}

.bg-apple {
  background: #a6b1b7;
}

.bg-amazon {
  background: #f90;
}

.bg-microsoft {
  background: #f35022;
}

.bg-skype {
  background: #00aff0;
}

.bg-android {
  background: #a4c639;
}

.bg-quora {
  background: #a20;
}

.bg-dribbble {
  background: #ea4c89;
}

.bg-slack {
  background: #4a154b;
}

.hover-top {
  transition: all .35s ease-in-out;
  transform: translateY(0);
}

.hover-top:hover {
  transform: translateY(-10px);
}

.hover-top-in .hover-top--in {
  transition: all .35s ease-in-out;
  position: relative;
  top: 0;
}

.hover-top-in:hover .hover-top--in {
  top: -10px;
}

.hover-scale .hover-scale-in {
  overflow: hidden;
}

.hover-scale .hover-scale-in img {
  transition: all .35s;
  transform: scale(1);
}

.hover-scale:hover .hover-scale-in img {
  transform: scale(1.05);
}

.arrow-hover {
  position: relative;
}

.arrow-hover .arrow-icon {
  opacity: 0;
  margin: auto;
  transition: all .35s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
}

.arrow-hover:hover .arrow-icon {
  opacity: 1;
  right: 15px;
}

.theme-hover-bg:hover {
  background: #03c !important;
}

.theme-hover-bg:hover h6, .theme-hover-bg:hover .h6, .theme-hover-bg:hover p {
  color: #fff;
}

.hover-scale-box {
  transition: all .35s;
  transform: scale(1);
}

.hover-scale-box:hover {
  transform: scale(1.05);
}

.hover-bottom-show {
  position: relative;
  overflow: hidden;
}

.hover-bottom-show .hover-bottom-show-in {
  z-index: 1;
  opacity: 0;
  transition: all .35s;
  position: absolute;
  bottom: -50%;
  left: 0;
  right: 0;
}

.hover-bottom-show:hover .hover-bottom-show-in {
  opacity: 1;
  bottom: 0;
}

.grayscale-hover img {
  -webkit-filter: grayscale();
  filter: grayscale();
  opacity: .7;
  transition: all .35s;
}

.grayscale-hover:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.flip-box {
  position: relative;
  overflow: hidden;
}

.flip-box .flip-box-img {
  transition: all .35s;
  transform: rotateY(0);
}

.flip-box .flip-box-content {
  opacity: 0;
  transition: all .35s;
  position: absolute;
  inset: 0;
  transform: rotateY(180deg);
}

.flip-box:hover .flip-box-img {
  opacity: 0;
  transform: rotateY(180deg);
}

.flip-box:hover .flip-box-content {
  opacity: 1;
  transform: rotateY(0);
}

.line-hover {
  position: relative;
  overflow: hidden;
}

.line-hover:before, .line-hover:after {
  content: "";
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  position: absolute;
}

.line-hover:before {
  border-top: 5px solid #03c;
  border-left: 5px solid #03c;
  top: 0;
  left: 0;
}

.line-hover:after {
  border-bottom: 5px solid #03c;
  border-right: 5px solid #03c;
  bottom: 0;
  right: 0;
}

.line-hover:hover:after, .line-hover:hover:before {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.feature-hover-1 {
  position: relative;
  overflow: hidden;
}

.feature-hover-1 .feature-content {
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 10%;
  transition: all .35s;
  position: absolute;
  top: 0;
  left: -100%;
}

.feature-hover-1:hover .feature-content {
  opacity: 1;
  left: 0;
}

.feature-hover-2 {
  position: relative;
  overflow: hidden;
}

.feature-hover-2 .feature-content {
  width: 100%;
  height: 100%;
  padding: 8%;
  position: absolute;
  top: 0;
  left: 0;
}

.feature-hover-2 .feature-content .btn-bar {
  opacity: 0;
  margin-bottom: -30px;
  transition: all .35s;
  position: relative;
}

.feature-hover-2:hover .feature-content .btn-bar {
  opacity: 1;
  margin-bottom: 0;
}

.feature-hover-3 {
  position: relative;
  overflow: hidden;
}

.feature-hover-3 .feature-content {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #08052f99;
  margin-bottom: -30px;
  padding: 12% 8% 8%;
  transition: all .35s;
  position: absolute;
  top: 0;
  left: 0;
}

.feature-hover-3:hover .feature-content {
  opacity: 1;
  padding-top: 8%;
}

.feature-box-1 {
  position: relative;
}

.feature-box-1:not(.last):after {
  content: "";
  width: 60px;
  height: 1px;
  opacity: .2;
  z-index: 1;
  background: #000;
  position: absolute;
  top: 24%;
  right: -30px;
}

@media (max-width: 991.98px) {
  .feature-box-1[class*="col-lg"]:nth-child(2n+2):after {
    opacity: 0;
  }
}

@media (max-width: 767.98px) {
  .feature-box-1[class*="col-md"]:after {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .feature-box-1[class*="col-sm"]:after {
    display: none;
  }
}

.mask {
  position: absolute;
  inset: 0;
}

.mask.bottom {
  top: auto;
}

.mask-90 {
  position: absolute;
  inset: 0 0 10%;
}

.mask-90.bottom {
  top: 10%;
  bottom: 0;
}

.mask-80 {
  position: absolute;
  inset: 0 0 20%;
}

.mask-80.bottom {
  top: 20%;
  bottom: 0;
}

.mask-75 {
  position: absolute;
  inset: 0 0 25%;
}

.mask-75.bottom {
  top: 25%;
  bottom: 0;
}

.mask-65 {
  position: absolute;
  inset: 0 0 35%;
}

.mask-65.bottom {
  top: 35%;
  bottom: 0;
}

.mask-50 {
  position: absolute;
  inset: 0 0 50%;
}

.mask-50.bottom {
  top: 50%;
  bottom: 0;
}

.mask-40 {
  position: absolute;
  inset: 0 0 40%;
}

.mask-40.bottom {
  top: 40%;
  bottom: 0;
}

@media (min-width: 992px) {
  .mask-50vw {
    z-index: -1;
    width: calc(50vw + 15px);
    position: absolute;
    top: 0;
    bottom: 0;
  }

  .no-gutters .mask-50vw {
    width: 50vw;
  }

  .mask-50vw.left {
    left: auto;
    right: -15px;
  }

  .mask-50vw.right {
    left: -15px;
  }
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index--1 {
  z-index: -1;
}

.z-index--2 {
  z-index: -2;
}

.p-truncate {
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.effect-section {
  position: relative;
  overflow: hidden;
}

.particles-box {
  z-index: 1;
  opacity: .2;
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.effect-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.scale-ani {
  animation: 5s linear infinite scale-animation;
}

@keyframes scale-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

@media (min-width: 992px) {
  .sticky-lg-top-header {
    top: 100px;
  }
}

.effect-skew {
  pointer-events: none;
  border-radius: 0 0 180px;
  position: absolute;
  inset: 0 31% 0 -50%;
  transform: skew(-35deg);
}

@media (max-width: 575.98px) {
  .effect-skew {
    left: -90%;
    right: -40%;
  }
}

.effect-radius {
  border-radius: 0% 100% 20% 80% / 73% 0% 100% 27%;
  position: absolute;
  inset: 0;
}

@media (max-width: 575.98px) {
  .effect-radius {
    bottom: 30px;
    left: -50px;
    right: -40px;
  }
}

.effect-shape {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.effect-shape:after {
  content: "";
  background: linear-gradient(to left, #ffffff14, #0202020d);
  border-radius: 50%;
  margin: auto;
  position: absolute;
  inset: -40% -25% -40% 45%;
  transform: skew(30deg);
}

.effect-radius-bg {
  z-index: -1;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.effect-radius-bg > div {
  width: 23rem;
  height: 23rem;
  z-index: -1;
  background-image: linear-gradient(-180deg, #e3ddf61a 0%, #e3ddf633 65%, #e3ddf61a 100%);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.effect-radius-bg .radius-1 {
  transform: translate(-50%, -50%)scale(.67);
}

.effect-radius-bg .radius-2 {
  transform: translate(-50%, -50%)scale(1.05);
}

.effect-radius-bg .radius-3 {
  transform: translate(-50%, -50%)scale(1.63);
}

.effect-radius-bg .radius-4 {
  transform: translate(-50%, -50%)scale(2.1);
}

.effect-radius-bg .radius-x {
  will-change: transform;
  animation: 3s infinite pulsate;
}

@keyframes pulsate {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%)scale(.5);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%)scale(4);
  }
}

.after-k:after {
  content: "K";
}

.after-p:after {
  content: "%";
}

.after-plus:after {
  content: "+";
}

.after-50px {
  position: relative;
}

.after-50px:after {
  width: 50px;
  height: 2px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.text-center .after-50px:after {
  margin: auto;
  right: 0;
}

.tab-style-1 .nav {
  border-bottom: 1px solid #eaecf3;
}

@media (max-width: 767.98px) {
  .tab-style-1 .nav {
    margin-bottom: 15px;
  }
}

.tab-style-1 .nav-item {
  margin: 0 10px;
}

@media (max-width: 767.98px) {
  .tab-style-1 .nav-item {
    margin: 0 5px;
  }
}

.tab-style-1 .nav-item a {
  color: #08052f;
  text-align: center;
  width: 100%;
  border-bottom: none;
  padding: 12px 15px;
  display: block;
  position: relative;
}

@media (max-width: 767.98px) {
  .tab-style-1 .nav-item a {
    padding: 10px 12px;
  }
}

.tab-style-1 .nav-item a:after {
  content: "";
  width: 0;
  height: 1px;
  background: #03c;
  transition: all .55s ease-in-out;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.tab-style-1 .nav-item a.active {
  color: #03c;
}

.tab-style-1 .nav-item a.active:after {
  width: 100%;
}

@media (min-width: 768px) {
  .tab-style-1 .nav-item .icon {
    margin-bottom: 8px;
  }
}

.tab-style-1 .nav-item .icon svg {
  height: 38px;
  width: 38px;
}

@media (max-width: 767.98px) {
  .tab-style-1 .nav-item .icon svg {
    height: 30px;
    width: 30px;
  }
}

.tab-style-1 .nav-item span {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

@media (max-width: 767.98px) {
  .tab-style-1 .nav-item span {
    display: none;
  }
}

.tab-style-1 .tab-pane {
  padding-top: 20px;
}

.tab-style-2 .nav {
  border: none;
  margin-bottom: 15px;
}

.tab-style-2 .nav .nav-item {
  margin-right: 18px;
}

.tab-style-2 .nav .nav-item a {
  color: #08052f;
  padding: 8px 0;
  font-weight: 600;
  display: block;
  position: relative;
}

.tab-style-2 .nav .nav-item a:after {
  content: "";
  width: 0;
  height: 2px;
  background: #15db95;
  transition: all .55s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tab-style-2 .nav .nav-item a.active {
  color: #03c;
}

.tab-style-2 .nav .nav-item a.active:after {
  width: 100%;
}

.tab-style-3 .nav {
  background: #fff;
  border: none;
}

.tab-style-3 .nav a {
  color: #718096;
  padding: 15px 10px 18px;
  line-height: normal;
  display: block;
  position: relative;
}

.tab-style-3 .nav a:after, .tab-style-3 .nav a:before {
  content: "";
  margin: auto;
  transition: all .25s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tab-style-3 .nav a:after {
  width: 0;
  height: 3px;
  background: #15db95;
}

.tab-style-3 .nav a:before {
  width: 20px;
  z-index: 1;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  bottom: -8px;
  box-shadow: 0 .375rem 1.5rem #8c98a420;
}

.tab-style-3 .nav a.active {
  color: #15db95;
}

.tab-style-3 .nav a.active:after {
  width: 100%;
}

.tab-style-3 .nav .tab-icon {
  margin-bottom: 10px;
}

.tab-style-3 .nav .tab-icon svg {
  width: 35px;
  height: 35px;
}

.tab-style-3 .nav span {
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .tab-style-3 .nav span {
    display: none;
  }
}

.tab-style-3 .tab-content {
  padding-top: 30px;
}

.tab-style-4 .nav {
  border-bottom: 2px solid #eaecf3;
  margin-bottom: 15px;
}

.tab-style-4 .nav .nav-item .tb-icon {
  vertical-align: middle;
  color: #03c;
  font-size: 30px;
  display: inline-block;
}

.tab-style-4 .nav .nav-item span {
  vertical-align: middle;
  margin-left: 10px;
}

@media (max-width: 575.98px) {
  .tab-style-4 .nav .nav-item span {
    display: none;
  }
}

.tab-style-4 .nav .nav-item a {
  color: #08052f;
  padding: 15px 0;
  font-weight: 600;
  display: block;
  position: relative;
}

.tab-style-4 .nav .nav-item a:after {
  content: "";
  width: 0;
  height: 3px;
  background: #03c;
  transition: all .55s ease-in-out;
  position: absolute;
  bottom: -2px;
  left: auto;
  right: 0;
}

.tab-style-4 .nav .nav-item a.active:after {
  width: 100%;
  left: 0;
  right: auto;
}

.tab-style-5 .nav-tabs {
  border: none;
}

.tab-style-5 .nav-tabs .nav-item {
  margin: 5px;
}

.tab-style-5 .nav-tabs .nav-item > a {
  color: #08052f;
  background: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px 10px;
  font-weight: 600;
  position: relative;
}

.tab-style-5 .nav-tabs .nav-item > a.active {
  color: #fff;
  background: #03c;
}

.tab-style-6 .nav-tabs .nav-item + .nav-item {
  margin-top: 1.3rem;
}

.tab-style-6 .nav-tabs .nav-link {
  background: #eaecf3;
  border: none;
  border-left: 5px solid #0000;
  border-radius: .75rem;
  padding: 1.5rem;
}

.tab-style-6 .nav-tabs .nav-link.active {
  border-left: 5px solid #08052f;
}

.tab-style-6.flex-row-reverse .nav-tabs .nav-link {
  border-left: none;
  border-right: 5px solid #0000;
}

.tab-style-6.flex-row-reverse .nav-tabs .nav-link.active {
  border-right: 5px solid #08052f;
}

.tab-style-7 .nav-tabs {
  border: none;
}

.tab-style-7 .nav-tabs .nav-item .nav-link {
  color: #718096;
  background: #fff;
  border: 0;
  border-radius: .75rem;
  outline: none;
  padding: 1rem;
  box-shadow: 0 10px 40px 10px #8c98a42d;
}

.tab-style-7 .nav-tabs .nav-item .nav-link .icon-sm, .tab-style-7 .nav-tabs .nav-item .nav-link.active {
  color: #fff;
  background: #03c;
}

.tab-style-7 .nav-tabs .nav-item .nav-link.active h6, .tab-style-7 .nav-tabs .nav-item .nav-link.active .h6 {
  color: #fff;
}

.tab-style-7 .nav-tabs .nav-item .nav-link.active .icon-sm {
  color: #03c;
  background: #fff;
}

.tab-style-8 .nav-tabs {
  border: none;
}

.tab-style-8 .nav-tabs .nav-item .nav-link {
  width: 100%;
  color: #718096;
  background: none;
  border: 0;
  outline: none;
  padding: 0 0 20px;
  position: relative;
}

.tab-style-8 .nav-tabs .nav-item .nav-link:before {
  content: "";
  height: 3px;
  background: #e2e8f0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tab-style-8 .nav-tabs .nav-item .nav-link:after {
  content: "";
  height: 3px;
  width: 0;
  background: #03c;
  transition: all .35s;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
}

.tab-style-8 .nav-tabs .nav-item .nav-link .icon-sm {
  color: #fff;
  background: #08052f;
}

.tab-style-8 .nav-tabs .nav-item .nav-link.active:after {
  width: 100%;
  left: 0;
  right: auto;
}

.shadow-sm-hover:hover {
  box-shadow: 0 12px 15px #8c98a41a !important;
}

.shadow-hover:hover {
  box-shadow: 0 .375rem 1.5rem #8c98a420 !important;
}

.shadow-lg-hover:hover {
  box-shadow: 0 10px 40px 10px #8c98a42d !important;
}

.rd-mailform .has-error .invalid-feedback {
  display: block;
}

.rd-mailform .snackbars {
  background: #eaecf3;
  border: 1px solid #eaecf3;
  border-radius: 3px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  display: none;
}

.rd-mailform .snackbars.active {
  display: block;
}

.rd-mailform .snackbars.error {
  color: #f25767;
  background: #f36070;
  border: 1px solid #f46f7c;
}

.rd-mailform .snackbars.success {
  color: #5cc9a7;
  background: #64ccab;
  border: 1px solid #6fcfb1;
}

.rd-mailform .snackbars p {
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
}

.rd-mailform .snackbars p .s-icon {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

.form-switch .form-check-input {
  height: 1.5em;
}

.nav-sub .nav-icon {
  width: 1.25rem;
  opacity: .8;
  text-align: center;
  margin-right: .3rem;
  font-size: .8125rem;
  line-height: 1.4;
}

.nav-sub .nav-item {
  width: 100%;
}

.nav-sub .nav-link {
  color: #718096;
  padding: 8px 0;
}

.nav-sub .nav-link:hover, .nav-sub .nav-link.active {
  color: #03c;
}

.list {
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.list-item + .list-item {
  margin-top: .35rem;
}

.list-link {
  color: #718096;
  flex-wrap: nowrap;
  align-items: center;
  font-size: .9375rem;
  display: flex;
}

.list-link:hover, .list-link:focus {
  color: #03c;
  text-decoration: none;
}

.skill-lt:not(:last-child) {
  margin-bottom: 30px;
}

.skill-lt h6, .skill-lt .h6 {
  margin: 0 0 10px;
  font-size: 16px;
}

.skill-lt .skill-bar {
  background: #eee;
  position: relative;
  box-shadow: inset 0 1px 2px #0000001a;
}

.skill-lt .skill-bar .skill-bar-in {
  width: 0;
  height: 5px;
  transition: all .95s cubic-bezier(.4, 0, 1, 1);
  position: relative;
}

.skill-lt .skill-bar .skill-bar-in span {
  color: #fff;
  background: #08052f;
  border-radius: 3px 3px 0;
  padding: 2px 7px;
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  top: -30px;
  right: 0;
}

.skill-lt .skill-bar .skill-bar-in span:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid #08052f;
  border-left: 8px solid #0000;
  position: absolute;
  bottom: -6px;
  right: 6px;
}

.skill-lt.md .skill-bar .skill-bar-in {
  height: 8px;
}

.skill-lt.lg .skill-bar .skill-bar-in {
  height: 10px;
}

.skill-lt.light .skill-bar {
  background: #00000080;
}

.portfolio-filter-01 li {
  color: #08052f;
  cursor: pointer;
  background: #e2e8f0;
  border: 1px solid #cbd5e0;
  border-radius: 30px;
  margin: 4px;
  padding: 3px 18px;
}

.portfolio-filter-01 li.active {
  color: #fff;
  background: #03c;
  border-color: #03c;
}

.portfolio-filter-02 .filter {
  border-left: 3px solid #cbd5e0;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.portfolio-filter-02 .filter li {
  color: #2d3748;
  cursor: pointer;
  padding-left: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  position: relative;
}

.portfolio-filter-02 .filter li:after {
  content: "";
  width: 3px;
  height: 0;
  background: currentColor;
  transition: all .35s;
  position: absolute;
  top: 0;
  left: -3px;
}

.portfolio-filter-02 .filter li.active {
  color: #03c;
}

.portfolio-filter-02 .filter li.active:after {
  height: 100%;
}

.portfolio-filter-02 .filter li + li {
  margin-top: 15px;
}

.portfolio-box-02 {
  position: relative;
  overflow: hidden;
}

.portfolio-box-02 .portfolio-img img {
  transition: all .55s;
  transform: scale(1);
}

.portfolio-box-02 .portfolio-info {
  opacity: 0;
  background: #03cc;
  transition: all .55s;
  position: absolute;
  inset: 0;
}

.portfolio-box-02 .gallery-link {
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 20px;
  line-height: 52px;
  transition: all .55s;
  display: inline-block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.portfolio-box-02 .gallery-link i {
  display: inline-block;
}

.portfolio-box-02 .gallery-link:hover {
  color: #03c;
  background: #fff;
}

.portfolio-box-02 .portfolio-desc {
  opacity: 0;
  transition: all .55s .15s;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.portfolio-box-02 .portfolio-desc h5, .portfolio-box-02 .portfolio-desc .h5 {
  margin: 0 0 15px;
  font-size: 16px;
  font-weight: 500;
}

.portfolio-box-02 .portfolio-desc h5 a, .portfolio-box-02 .portfolio-desc .h5 a {
  color: #fff;
  text-decoration: none;
}

.portfolio-box-02 .portfolio-desc .pb-tag a {
  vertical-align: top;
  color: #03c;
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-bottom: 3px;
  margin-right: 8px;
  padding: 4px 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  transition: all .55s;
  display: inline-block;
}

.portfolio-box-02 .portfolio-desc .pb-tag a:hover {
  color: #fff;
  background: #03c;
}

.portfolio-box-02:hover .portfolio-img img {
  transform: scale(1.2);
}

.portfolio-box-02:hover .portfolio-desc {
  opacity: 1;
}

.portfolio-box-02:hover .portfolio-info {
  opacity: 1;
  position: absolute;
}

.count-down-01 {
  flex-wrap: wrap;
  display: flex;
}

.count-down-01 > div {
  text-align: center;
  background: #fff;
  border-radius: .25rem;
  margin: 3px 7px 3px 0;
  padding: 10px 0;
  line-height: 1;
  box-shadow: 0 12px 15px #8c98a41a;
}

.count-down-01 .count {
  color: #08052f;
  vertical-align: top;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
}

.count-down-01 .title {
  font-size: 14px;
}

.media {
  display: flex;
}

.media .media-body {
  flex: 1;
}

.page-heading {
  padding-top: 12rem;
  padding-bottom: 6rem;
}

.browser-device {
  z-index: 1;
  max-width: 100%;
  display: block;
  position: relative;
}

.browser-device .browser-device-header {
  background: #fff;
  border-bottom: 1px solid #e2e8f0;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  padding: .5rem 6.5rem;
  display: flex;
  position: relative;
}

.browser-device .browser-device-header .browser-device-action {
  grid-gap: .25rem;
  display: flex;
  position: absolute;
  top: calc(50% - .25rem);
  left: 1rem;
}

.browser-device .browser-device-header .browser-device-action span {
  width: .5rem;
  height: .5rem;
  background-color: #e2e8f0;
  border-radius: 50%;
}

.browser-device .browser-device-header .browser-device-bar {
  width: 100%;
  height: 100%;
  color: #2d3748;
  background-color: #f1f6fd;
  border-radius: .125rem;
  justify-content: center;
  align-items: center;
  font-size: .5rem;
  display: flex;
}

.browser-device .browser-device-frame {
  background-color: #fff;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  box-shadow: 0 10px 40px 10px #8c98a42d;
}

.browser-device .browser-device-frame img {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.browser-device .browser-actions {
  text-align: center;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  opacity: 0;
  background: #00000080;
  border-radius: 0 0 10px 10px;
  justify-content: center;
  align-items: center;
  transition: all .35s;
  display: flex;
  position: absolute;
  inset: 30px 0 0;
}

.browser-device:hover .browser-actions {
  opacity: 1;
}

.min-h-650px {
  min-height: 650px;
}

.min-h-750px {
  min-height: 750px;
}

.min-h-850px {
  min-height: 850px;
}

.min-h-950px {
  min-height: 950px;
}

.fixed-300 {
  width: 300px;
}

.pricing-text {
  font-size: 1.75rem;
}

.premium-highlight {
  color: #03c;
}

#pricing-slider {
  margin: 3rem 0;
}

#pricing-slider .noUi-handle {
  background-color: #4285f4;
  box-shadow: 0 .25rem .25rem .125rem #0033cc1a, 0 .375rem .75rem -.125rem #03c6;
}

#pricing-slider .noUi-target {
  background: none;
}

#skillo-pricing-features-list ul.pricing-features-list {
  list-style: none;
}

#skillo-pricing-features-list ul.pricing-features-list li {
  border-bottom: 1px dotted #eaecf3;
  margin-bottom: .5rem;
}

#skillo-pricing-features-list ul.pricing-features-list li.premium-feature {
  font-weight: 700;
}

.pricing-row {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pricing-row:hover {
  background-color: #eaecf3;
}

.form-control-has-validation.error {
  border: 1px solid #f25767;
}

.landing-quick-features-box {
  color: #08052f;
  font-size: 1.15rem;
}

.landing-feature-header {
  font-weight: bold;
}

.landing-feature-subtext {
  margin-left: 25px;
}

#home-banner-text-1 {
  background: -webkit-linear-gradient(top left, #162271, #00a1ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-justified {
  text-align: justify;
}

/*# sourceMappingURL=parcel.css.map */
