// You easily change theme using

//$primary:               #(color code) !default; 
//$secondary:             #(color code) !default;
//$info:                  #(color code) !default;
//$success:               #(color code) !default;
//$warning:               #(color code) !default;
//$danger:                #(color code) !default;
//$light:                 #(color code) !default;
//$dark:                  #(color code) !default;


// Font change

// Import fonts from Google
// go to https://fonts.google.com copy and paste the URL of the selected font from the @import tab
// (e.g: https://fonts.googleapis.com/css?family=Nunito) and peste it in the _variables.scss from the custom folder

// Update font family in $font-family-sans-serif-cutom variables


$font: "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" !default;
$font-family-sans-serif-cutom:      'Inter', 'Roboto', sans-serif !default;
$font-family-sans-serif:      $font-family-sans-serif-cutom !default;
//$headings-font-family:        $font-family-sans-serif-cutom !default;



// Custom Variable