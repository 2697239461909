

.max-w-100px {
    max-width: 100px !important;
}

.max-w-150px {
    max-width: 150px !important;
}

.max-w-200px {
    max-width: 200px !important;
}

.max-w-250px {
    max-width: 250px !important;
}
