// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

$h3-font-size: 1.75rem;
$font-weight-bold: 700 !default;
$red: #f25767;


.min-h-650px {
    min-height: 650px;
}

.min-h-750px {
    min-height: 750px;
}

.min-h-850px {
    min-height: 850px;
}

.min-h-950px {
    min-height: 950px;
}

.fixed-300 {
    width: 300px;
}

.pricing-text {
    font-size: $h3-font-size;
}

.premium-highlight {
    color: $blue;
}

#pricing-slider {
    margin: 3rem 0;

    .noUi-handle {
        background-color: #4285F4;
        box-shadow: 0 0.25rem 0.25rem 0.125rem rgb(0 51 204 / 10%), 0 0.375rem 0.75rem -0.125rem rgb(0 51 204 / 40%);
    }

    .noUi-target {
        background: none;

    }
}

#skillo-pricing-features-list {
    ul.pricing-features-list {
        list-style: none;

        li {
            margin-bottom: 0.5rem;

            &.premium-feature {
                font-weight: $font-weight-bold;
            }

            border-bottom: 1px dotted $gray-200;
        }
    }
}

#skillo-pricing-features-header {}

.pricing-row {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &:hover {
        background-color: $gray-200;
    }
}

.form-control-has-validation.error {
    border: 1px solid $red;
}

.landing-quick-features-box {
    color: $dark;
    font-size: 1.15rem;
    // text-shadow: 1px 1px 2px rgba(155, 155, 169, 0.73);
}

.landing-feature-header {
    font-weight: bold;
}

.landing-feature-subtext {
    margin-left: 25px;
}

#home-banner-text-1 {
    background: -webkit-linear-gradient(top left, #162271, #00a1ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-justified {
    text-align: justify;
}