@each $color, $value in $grays {
  .text-#{$color} {
    color: $value;
  }
}

.text-white-65 {
	color: rgba($white, 0.65);
}

.text-white-85 {
	color: rgba($white, 0.65);
}