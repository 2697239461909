.avatar {
    height: $avatar-size-base;
    width: $avatar-size-base;
    position: relative;
    display: inline-block !important;
    .avatar-status {
    	border-radius: 50%;
	    bottom: 5%;
	    height: 20%;
	    position: absolute;
	    right: 5%;
	    width: 20%;
	    border: 1px solid $white;
    }
    .avatar-text {
    	font-size: $avatar-font-size-base;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    width: 100%;
	    height: 100%;
        font-weight: 600;
		text-transform: uppercase;
    }
}

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-group {
    padding: 0;
    display: flex;
    .avatar-img,
    .avatar-text {
    	border: 2px solid $white;
    }
    .avatar {
    	+ .avatar {
    		margin-left: -1rem;
    	}
    }
    .avatar-xs {
    	+ .avatar-xs {
    		margin-left: -.6125rem;
    	}
    }
    .avatar-sm {
    	+ .avatar-sm {
    		margin-left: -.875rem;
    	}
    }
    .avatar-lg {
    	+ .avatar-lg {
    		margin-left: -1.5rem;
    	}
    }
    .avatar-xl {
    	+ .avatar-xl {
    		margin-left: -2rem;
    	}
    }
    .avatarxxl {
    	+ .avatarxxl {
    		margin-left: -2.5rem;
    	}
    }
    .avatar-xxxl {
    	+ .avatar-xxxl {
    		margin-left: -3rem;
    	}
    }
}


// avatar Sizing

.avatar {
    .avatar-name {
        margin-left: 7px;
    }
}




// avatar Sizing
.avatar-xs {
    height: $avatar-size-xs;
    width: $avatar-size-xs;
    .avatar-text {
    	font-size: $avatar-font-size-xs;
    }
}

.avatar-sm {
    height: $avatar-size-sm;
    width: $avatar-size-sm;
    .avatar-text {
    	font-size: $avatar-font-size-sm;
    }
}

.avatar-lg {
    height: $avatar-size-lg;
    width: $avatar-size-lg;
    .avatar-text {
    	font-size: $avatar-font-size-lg;
    }
}

.avatar-xl {
    height: $avatar-size-xl;
    width: $avatar-size-xl;
    .avatar-text {
    	font-size: $avatar-font-size-xl;
    }
}

.avatar-xxl {
    height: $avatar-size-xxl;
    width: $avatar-size-xxl;
    .avatar-text {
    	font-size: $avatar-font-size-xxl;
    }
}

.avatar-xxxl {
    width: $avatar-size-xxxl;
    height: $avatar-size-xxxl;
    .avatar-text {
    	font-size: $avatar-font-size-xxxl;
    }
}