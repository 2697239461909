.min-h-150px {
    min-height: 150px;
}

.min-h-250px {
    min-height: 250px;
}

.min-h-350px {
    min-height: 350px;
}

